<template>
    <b-container>
        <b-row>
            <b-col class="col-lg-12 col-md-12 col-sm-12 mb-5" v-if="emasculationPollinationData.emasculationRingLabel">
                <h4 class="text-center mb-3" style="color: var(--color-secondary)">Emasculation</h4>
                <b-table-simple responsive bordered>
                    <b-thead>
                        <b-tr style="height: 40px">
                            <b-th class="text-center" style="width: 13%;">Year week</b-th>
                            <b-th class="text-center" style="width: 11%;">Week</b-th>
                            <b-th class="text-center" style="width: 16%">Day</b-th>
                            <b-th class="text-center" style="width: 20%">Date</b-th>
                            <b-th class="text-center" style="width: 20%">Emasculation</b-th>
                        </b-tr>
                    </b-thead>
                    <b-tbody>
                        <tr>
                            <td class="vertical-align">
                                <div class="text-center">
                                    <p>{{ emasculationPollinationData.yearWeek}}</p>
                                </div>
                            </td>
                            <td class="vertical-align">
                                <div class="text-center">
                                    <p>{{ emasculationPollinationData.week }}</p>
                                </div>
                            </td>
                            <td class="vertical-align">
                                <div class="text-center">
                                    <p>{{ getDayName(emasculationPollinationData.date) }}</p>
                                </div>
                            </td>
                            <td class="vertical-align">
                                <div class="text-center font-bold">
                                    <p>{{ emasculationPollinationData.date }}</p>
                                </div>
                            </td>
                            <td class="vertical-align">
                                <div>
                                    <div class="d-flex justify-content-around align-items-center">
                                        <p :class="emasculationPollinationData.emasculationRingLabel.replace(' ','-') +' square'" v-if="emasculationPollinationData.emasculationStatus === 'ACTIVE'">{{ emasculationPollinationData.emasculationRingLabel }}</p>
                                        <p :class="emasculationPollinationData.emasculationRingLabel.replace(' ','-') +'-inactive' +' square'" v-else>{{ emasculationPollinationData.emasculationRingLabel }}</p>
                                    </div>
                                </div>
                            </td>
                        </tr>
                    </b-tbody>
                </b-table-simple>
                <emasculation-pollination-comment-component comment-type="Emasculation" :pollination-id="emasculationPollinationData.id" @load-data="loadData"></emasculation-pollination-comment-component>
            </b-col>
            <b-col class="col-lg-12 col-md-12 col-sm-12" v-if="emasculationPollinationData.pollinationRingLabel">
                <h4 class="text-center mb-3" style="color: var(--color-secondary)">Pollination</h4>
                <b-table-simple responsive bordered>
                    <b-thead>
                        <b-tr style="height: 40px">
                            <b-th class="text-center" style="width: 13%;">Year week</b-th>
                            <b-th class="text-center" style="width: 11%;">Week</b-th>
                            <b-th class="text-center" style="width: 16%">Day</b-th>
                            <b-th class="text-center" style="width: 20%">Date</b-th>
                            <b-th class="text-center" style="width: 20%">Pollination</b-th>
                        </b-tr>
                    </b-thead>
                    <b-tbody>
                        <tr>
                            <td class="vertical-align">
                                <div class="text-center">
                                    <p>{{ emasculationPollinationData.yearWeek}}</p>
                                </div>
                            </td>
                            <td class="vertical-align">
                                <div class="text-center">
                                    <p>{{ emasculationPollinationData.week }}</p>
                                </div>
                            </td>
                            <td class="vertical-align">
                                <div class="text-center">
                                    <p>{{ getDayName(emasculationPollinationData.date) }}</p>
                                </div>
                            </td>
                            <td class="vertical-align">
                                <div class="text-center font-bold">
                                    <p>{{ emasculationPollinationData.date }}</p>
                                </div>
                            </td>
                            <td class="vertical-align">
                                <div>
                                    <div class="d-flex justify-content-around align-items-center">
                                        <p :class="emasculationPollinationData.pollinationRingLabel.replace(' ','-') +' square'" v-if="emasculationPollinationData.pollinationStatus === 'ACTIVE'">{{ emasculationPollinationData.pollinationRingLabel }}</p>
                                        <p :class="emasculationPollinationData.pollinationRingLabel.replace(' ','-') +'-inactive' +' square'" v-else>{{ emasculationPollinationData.pollinationRingLabel }}</p>
                                    </div>
                                </div>
                            </td>
                        </tr>
                    </b-tbody>
                </b-table-simple>
            </b-col>
        </b-row>
        <b-row class="mt-3" v-if="emasculationPollinationData.pollinationRingLabel">
            <pollination-tubes-component :production-line-id="productionLineId" :pollination-id="emasculationPollinationData.id" :pollination-status="emasculationPollinationData.pollinationStatus" :key="emasculationPollinationData.id" @load-data="loadData"></pollination-tubes-component>
            <b-col md="12">
                <emasculation-pollination-comment-component comment-type="Pollination" :pollination-id="emasculationPollinationData.id" @load-data="loadData"></emasculation-pollination-comment-component>
            </b-col>
        </b-row>
    </b-container>

</template>

<script>
import dayjs from 'dayjs';
import PollinationTubesComponent from '@/views/EmasculationPollination/PollinationTubesComponent.vue';
import EmasculationPollinationCommentComponent from '@/views/EmasculationPollination/EmasculationPollinationCommentsComponent.vue';

export default {
    name: 'EmasculationPollinationComponent',
    props: {
        data: {
            type: Object,
            required: true
        },
        productionLineId: {
            type: Number,
            required: true
        },
        weekNumber: {
            type: String,
            required: true
        }
    },
    components: {
        PollinationTubesComponent,
        EmasculationPollinationCommentComponent
    },
    data() {
        return {
            emasculationPollinationData: null,
            toastTitle: 'Production lines'
        };
    },
    created() {
        this.emasculationPollinationData = this.data;
    },
    methods: {
        loadData() {
            this.$emit('load-data');
        },
        getDayName(date) {
            let dateFormat;

            if (date) {
                dateFormat = dayjs(date.toString(), 'DD/MM/YYYY');
            }

            return date ? dateFormat.format('ddd') : '';
        },
        formatDate(date) {
            let dateFormat;

            if (date) {
                dateFormat = dayjs(date.toString(), 'DD/MM/YYYY');
            }

            return date ? dateFormat.format('DD/MM/YYYY') : '';
        },
        processData() {
            this.emasculationPollinationData = this.data;
        }
    },
    watch: {
        data: {
            immediate: true,
            handler() {
                this.processData();
            //     todo verify this
            }
        }
    }
};
</script>

<style scoped>
    .form-group label, .form-group {
        margin-top: 0
    }

    .square {
        width: 100px;;
        height: 40px;
        margin-top: 4px;
        color: #000;
        border-radius: 1em;
        padding: 6px 12px;
        margin-right: 4px;
        margin-bottom: 4px;
        /*flex: 0 1 50%;*/
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 11px;
    }

    .White {
        background-color: #FFFFFF;
        border: 2px solid #DEE4E4;
    }

    .White-inactive {
        background-color: transparent;
        border: 2px solid #ccc;
        text-decoration: line-through;
    }

    .Pink {
        background-color: #FF6699;
        border: 2px solid rgba(169, 29, 76, 0.99);
    }

    .Pink-inactive {
        background-color: transparent;
        border: 2px solid #FF6699;
        text-decoration: line-through;
    }

    .Purple {
        background-color: #b782e6;
        border: 2px solid #8425d9;

    }

    .Purple-inactive {
        background-color: transparent;
        border: 2px solid #b782e6;
        text-decoration: line-through;
    }

    .Blue {
        background-color: #3a95e4;
        border: 2px solid #1660a1;
    }

    .Blue-inactive {
        background-color: transparent;
        border: 2px solid #3a95e4;
        text-decoration: line-through;
    }

    .Yellow {
        background-color: #FFFF01;
        border: 2px solid #dede3b;
    }

    .Yellow-inactive {
        background-color: transparent;
        border: 2px solid #FFFF01;
        text-decoration: line-through;
    }

    .Orange {
        background-color: #FA6503;
        border: 2px solid #ad5922;
    }

    .Orange-inactive {
        background-color: transparent;
        border: 2px solid #FA6503;
        text-decoration: line-through;
    }

    .Bronze {
        background-color: #b98d07;
        border: 2px solid #987f32;
    }

    .Bronze-inactive {
        background-color: transparent;
        border: 2px solid #b98d07;
        text-decoration: line-through;
    }

    .Turquoise {
        background-color: #2CC2FF;
        border: 2px solid #1e8cb9;
    }

    .Turquoise-inactive {
        background-color: transparent;
        border: 2px solid #2CC2FF;
        text-decoration: line-through;
    }

    .Black {
        background-color: #000000;
        border: 2px solid #000000;
        color: #FFFFFF;
    }

    .Black-inactive {
        background-color: transparent;
        border: 2px solid #000000;
        text-decoration: line-through;
    }

    .Brown {
        background-color: #843C0B;
        border: 2px solid #ad5922;
        color: #FFFFFF;
    }

    .Brown-inactive {
        background-color: transparent;
        border: 2px solid #843C0B;
        text-decoration: line-through;
    }

    .Blue {
        background-color: #002060;
        border: 2px solid #031741;
        color: #FFFFFF;
    }

    .Blue-inactive {
        background-color: transparent;
        border: 2px solid #002060;
        text-decoration: line-through;
    }

    .Red {
        background-color: #FF0000;
        border: 2px solid #a20303;
    }

    .Red-inactive {
        background-color: transparent;
        border: 2px solid #FF0000;
        text-decoration: line-through;
    }

    .No-ring {
        background-color: #D9D9D9;
        border: 2px solid #b7b6b6;
    }

    .No-ring-inactive {
        background-color: transparent;
        border: 2px solid #D9D9D9;
        text-decoration: line-through;
    }

    .vertical-align {
        vertical-align: middle;
    }
</style>