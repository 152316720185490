<template>
    <div>
        <b-card style="width: 100%; border: none">
            <ValidationObserver v-slot="{ passes }" ref="setForm">
                <b-form @submit.prevent="passes(saveSetData)" :id="'set-form' + index">
                    <b-row style="min-height: 96px;">
<!--                    <b-row>-->
                        <template v-if="!emasculationPollination.id || showEditDates">
                            <b-col sm="6" md="6" lg="6" class="d-flex">
                                <b-col md="6" sm="12">
<!--                                    <ValidationProvider rules="required" v-slot="{ errors }">
                                        <b-form-group label="Start date" :invalid-feedback="errors[0]">
                                            <b-form-datepicker :date-format-options="{ day: '2-digit', month: '2-digit', year: 'numeric' }"
                                                   :disabled="disableInputs && cropFamily !== 'Cucumber'" boundary="window" placeholder=""
                                                   hide-header ref="setStartDate" v-model="emasculationPollination.startDate"
                                                   :state="errors[0] ? false : null" @input="formatDatepicker($event, 'setStartDate')">
                                            </b-form-datepicker>
                                        </b-form-group>
                                    </ValidationProvider>-->
									<ValidationProvider rules="required" v-slot="{ errors }">
										<b-form-group label="Start date" :invalid-feedback="errors[0]">
											<custom-datepicker :container="'#container-production-line'" v-model="emasculationPollination.startDate" :state="errors[0] ? false : null" :disabled="disableInputs && cropFamily !== 'Cucumber'"/>
										</b-form-group>
									</ValidationProvider>
                                </b-col>
                                <b-col md="6" sm="12" v-if="cropFamily === 'Cucumber'">
<!--                                    <ValidationProvider rules="required" v-slot="{ errors }">
                                        <b-form-group label="End date" :invalid-feedback="errors[0]">
                                            <b-form-datepicker :date-format-options="{ day: '2-digit', month: '2-digit', year: 'numeric' }"
                                                   boundary="window" placeholder="" hide-header
                                                   v-model="emasculationPollination.endDate" :state="errors[0] ? false : null">
                                            </b-form-datepicker>
                                        </b-form-group>
                                    </ValidationProvider>-->
									<ValidationProvider rules="required" v-slot="{ errors }">
										<b-form-group label="End date" :invalid-feedback="errors[0]">
											<custom-datepicker :container="'#container-production-line'" v-model="emasculationPollination.endDate" :state="errors[0] ? false : null"/>
										</b-form-group>
									</ValidationProvider>
                                </b-col>
                                <b-col md="6" sm="12" v-if="cropFamily !== 'Cucumber'">
                                    <ValidationProvider rules="required|integer|min_value:1|max_value:100" v-slot="{ errors }">
                                        <b-form-group label="Weeks" :invalid-feedback="errors[0]">
                                            <b-input v-model="emasculationPollination.currentNumberWeeks" :state="errors[0] ? false : null" autocomplete="off"
                                                     :disabled="disableInputs">
                                            </b-input>
                                        </b-form-group>
                                    </ValidationProvider>
                                </b-col>
                            </b-col>
                        </template>
                        <b-col class="col-6 d-flex justify-content-center align-items-center" sm="6" md="6" lg="6" v-else>
                            <b-table-simple responsive bordered style="margin-bottom: 0">
                                <b-tbody>
                                    <b-tr>
                                        <b-th class="text-center" colspan="3" style="font-size: 14px; background-color: #EEEEEEFF">Compartment: {{ productionLine.compartmentName }}</b-th>
                                    </b-tr>
                                    <b-tr>
                                        <b-th class="text-center" style="width: 33%">Start date</b-th>
                                        <b-td class="text-center">{{ emasculationPollination.startDate }}</b-td>
                                        <b-td class="text-center" rowspan="5" style="width: 33%">
                                            <b-img class="img-fluid mb-1 mt-3" style="min-width: 70px; max-width: 120px; max-height: 120px;" v-if="productionLine.figureGraphic" :src="urlImg + productionLine.fileId"
                                                    :alt="productionLine.varietyName" ref="varietyImage"></b-img>
                                            <p style="color: var(--color-secondary); font-weight: 500">{{ productionLine.figureName }}</p>
                                        </b-td>
                                    </b-tr >
                                    <b-tr>
                                        <b-th class="text-center" style="width: 33%">End date</b-th>
                                        <b-td class="text-center">{{ emasculationPollination.endDate }}</b-td>
                                    </b-tr>
                                    <b-tr v-if="cropFamily !== 'Cucumber'">
                                        <b-th class="text-center" style="width: 33%">Weeks</b-th>
                                        <b-td class="text-center">{{ emasculationPollination.currentNumberWeeks }}</b-td>
                                    </b-tr>
                                    <b-tr v-if="cropFamily !== 'Cucumber'">
                                        <b-th class="text-center" style="width: 33%">Pollination days</b-th>
                                        <b-td class="text-center">{{ emasculationPollination.pollinationDays }}</b-td>
                                    </b-tr>
                                    <b-tr v-if="cropFamily !== 'Cucumber'">
                                        <b-th class="text-center" style="width: 33%">Set status</b-th>
                                        <b-td class="text-center"><b-badge :variant="emasculationPollination.ready ? 'success' : 'info'">{{ emasculationPollination.ready ? 'READY' : 'IN PROCESS' }}</b-badge></b-td>
                                    </b-tr>
                                </b-tbody>
                            </b-table-simple>
                        </b-col>
<!--                        <b-col sm="6" md="6" lg="6" style="background-color: rgba(208,191,225,10)">-->
                        <b-col sm="6" md="6" lg="6">
                            <div>
                                <b-row class="w-100 d-flex justify-content-end" v-if="cropFamily !== 'Cucumber'">
                                    <b-button type="button" @click="showInputAddWeeks = true" v-if="emasculationPollination.id && hasPermission"
                                              variant="outline-primary" v-b-tooltip title="Add weeks">
                                        <font-awesome-icon icon="plus"></font-awesome-icon>
                                    </b-button>
                                    <b-button type="button" @click="resetCalendar" v-if="emasculationPollination.id && !emasculationPollination.ready && hasPermission"
                                              variant="outline-primary" v-b-tooltip title="Reset">
                                        <font-awesome-icon icon="redo-alt"></font-awesome-icon>
                                    </b-button>
                                    <b-button type="button" @click="readyCalendar" v-if="emasculationPollination.id && !emasculationPollination.ready && hasPermission"
                                              variant="outline-primary" v-b-tooltip title="Ready">
                                        <font-awesome-icon icon="check"></font-awesome-icon>
                                    </b-button>
                                    <b-button type="button" @click="showConfirmSaveExcel" v-if="emasculationPollination.id"
                                              variant="outline-primary" v-b-tooltip title="Excel">
                                        <font-awesome-icon icon="file-excel"></font-awesome-icon>
                                    </b-button>
                                    <b-button type="button" @click="deleteCalendar" v-if="emasculationPollination.id && !emasculationPollination.ready && hasPermission"
                                              variant="outline-danger" v-b-tooltip title="Delete">
                                        <font-awesome-icon icon="trash-alt"></font-awesome-icon>
                                    </b-button>
                                </b-row>
                                <b-row v-else>
                                    <b-col md="6" sm="12" style="display: flex;" v-if="cropFamily === 'Cucumber'">
                                        <b-button type="submit" :form="'set-form' + index" @click="setData" v-show="showEditDates || !emasculationPollination.id" style="align-self: flex-start; margin-top: 33px"
                                                  variant="outline-primary">
                                            <font-awesome-icon icon="save"></font-awesome-icon>
                                            {{ emasculationPollination.id ? 'Update' : 'Save' }}
                                        </b-button>
                                        <b-button type="button" @click="showEdit" v-show="showEditDates && emasculationPollination.id" style="align-self: flex-start; margin-top: 33px"
                                                  variant="outline-primary">
                                            <font-awesome-icon icon="ban"></font-awesome-icon> Cancel
                                        </b-button>
                                        <b-button type="button" @click="showEdit" v-show="!showEditDates && emasculationPollination.id" style="align-self: flex-start; margin-top: 46px"
                                                  variant="outline-primary">
                                            <font-awesome-icon icon="edit"></font-awesome-icon> Edit
                                        </b-button>
                                    </b-col>
                                </b-row>
                                <b-row class="w-100">
                                    <b-col md="4" sm="12" v-if="showInputAddWeeks">
                                        <ValidationProvider :rules="showInputAddWeeks ? 'required|integer|min_value:1|max_value:100' : ''" v-slot="{ errors }">
                                            <b-form-group label="Weeks" :invalid-feedback="errors[0]" style="margin-top: 12px">
                                                <b-input v-model="emasculationPollination.weeksToAdd" :state="errors[0] ? false : null"
                                                         autocomplete="off">
                                                </b-input>
                                            </b-form-group>
                                        </ValidationProvider>
                                    </b-col>
                                    <b-col md="4" sm="12" style="display: flex; align-items: center;" v-if="(!disableInputs || showInputAddWeeks) && cropFamily !== 'Cucumber'">
                                        <b-button type="submit" v-if="showInputAddWeeks" :form="'set-form' + index" @click="setData" style="align-self: flex-start; margin-top: 37px"
                                                  variant="outline-primary" v-b-tooltip title="Add">
                                            <font-awesome-icon icon="save"></font-awesome-icon>
                                        </b-button>
                                        <b-button type="submit" v-else :form="'set-form' + index" @click="setData" :style="showInputAddWeeks ? '' : 'align-self: flex-start; margin-top: 34px'"
                                                  variant="outline-primary">
                                            <font-awesome-icon icon="save"></font-awesome-icon> Generate
                                        </b-button>
                                        <b-button type="button" @click="showInputAddWeeks = !showInputAddWeeks" v-if="showInputAddWeeks" style="align-self: flex-start; margin-top: 37px"
                                                  variant="outline-primary" v-b-tooltip title="Cancel">
                                            <font-awesome-icon icon="ban"></font-awesome-icon>
                                        </b-button>
                                    </b-col>
                                </b-row>
                            </div>
                        </b-col>
                    </b-row>
                </b-form>
            </ValidationObserver>
            <b-card class="mt-5" col="8" v-if="cropFamily === 'Cucumber'">
                <b-col md="12" class="mt-5 d-flex justify-content-center" >
                    <div style="width: 700px">
                        <emasculation-pollination-comment-component comment-type="Emasculation pollination" :pollination-id="emasculationPollination.id" @load-data="loadData"></emasculation-pollination-comment-component>
                    </div>
                </b-col>
            </b-card>
        </b-card>
        <b-row fluid class="mt-3 set-container" style="padding: 4px" v-if="Object.keys(this.calendar).length > 0">
            <b-col class="col-6" style="max-height: 100vh; overflow-y: scroll;">
                <b-table-simple responsive bordered style="margin-left: 12px; width: 98%">
                    <b-thead>
                        <b-tr style="height: 40px">
                            <b-th class="text-center" style="width: 13%;">Year week</b-th>
                            <b-th class="text-center" style="width: 11%;">Week</b-th>
                            <b-th class="text-center" style="width: 16%">Day</b-th>
                            <b-th class="text-center" style="width: 20%">Date</b-th>
                            <b-th class="text-center" style="width: 20%" v-if="showEmasculationColumnSet">Emasculation</b-th>
                            <b-th class="text-center" style="width: 20%">Pollination</b-th>
                        </b-tr>
                    </b-thead>
                    <b-tbody>
                        <b-tr v-for="(day, index) in calendar" :key="day.id" style="height: 50px"  class="cursor-pointer">
                            <b-td class="vertical-align" rowspan="7" v-if="getDayName(day.date) === 'Mon'" >
                                <div class="text-center">
                                    <p class="font-bold">{{ day.yearWeek}}</p>
                                </div>
                            </b-td>
                            <b-td class="vertical-align" rowspan="7" v-if="getDayName(day.date) === 'Mon'">
                                <div class="text-center">
                                    <p class="font-bold">{{ day.week }}</p>
                                </div>
                            </b-td>
                            <b-td class="vertical-align" :class="{'day-container': true,'selected-day': selectedDay === day,'non-selected-day': selectedDay !== day}" @click="openDetail(day, index)">
                                <div class="text-center vertical-align">
                                    <p class="font-bold">{{ getDayName(day.date) }}</p>
                                </div>
                            </b-td>
                            <b-td class="vertical-align" :class="{'day-container': true,'selected-day': selectedDay === day,'non-selected-day': selectedDay !== day}" @click="openDetail(day, index)">
                                <div class="text-center">
                                    <p class="font-bold">{{ day.date }}</p>
                                </div>
                            </b-td>
                            <b-td class="vertical-align" :class="{'day-container': true,'selected-day': selectedDay === day,'non-selected-day': selectedDay !== day}" v-if="showEmasculationColumnSet">
                                <div class="d-flex justify-content-center align-items-center">
                                    <b-dropdown id="dropdown-1" v-if="day.emasculationStatus !== 'DELETED' && day.emasculationStatus !== 'NO-EVENT'" @shown="getEmasculationPollinationOptions(day, 'Emasculation')"
                                                :variant="day.emasculationStatus === 'ACTIVE' ? day.emasculationRingLabel.replace(' ','-') : day.emasculationRingLabel.replace(' ','-')+'-inactive'" no-caret>
                                        <template #button-content>
                                            <span>{{ day.emasculationRingLabel }}</span>
                                            <span class="ml-2" v-if="day.emasculationComments > 0">
                                                <b-badge variant="secondary" v-b-tooltip="'Comments'">{{ day.emasculationComments }}</b-badge>
                                            </span>
                                        </template>
                                        <template v-if="day.showChangeColorOption">
                                            <b-dropdown-item v-for="(item, index) in day.listColors" :key="index" class="d-block" @click="changeEmasculationPollinationColor(day, item)">
                                                <p class="d-inline-block" style="width: 60%; vertical-align: middle;">Change</p>
                                                <p :class="item.name + ' color-option d-inline-block'" style="width: 50%; vertical-align: middle"></p>
                                            </b-dropdown-item>
                                        </template>
                                        <b-dropdown-item @click="changeStatus(day, 1)" v-if="hasPermission">{{ day.emasculationStatus === 'ACTIVE' ? 'Cancel' : 'Undo cancel' }}</b-dropdown-item>
                                        <b-dropdown-item @click="deleteRing(day, 1)" v-if="hasPermission">Delete</b-dropdown-item>
                                    </b-dropdown>
                                    <b-dropdown v-else variant="transparent" no-caret @shown="getAddOptions(1, day)">
                                        <b-dropdown-item v-for="(item, index) in day.listColors" :key="index" class="d-block" @click="createRing(day, 1, item)">
                                            <p class="d-inline-block" style="width: 60%; vertical-align: middle;">Add</p>
                                            <p :class="(item.name === 'No ring' ? 'No-ring' : item.name) + ' color-option d-inline-block'" style="width: 50%; vertical-align: middle"></p>
                                        </b-dropdown-item>
                                    </b-dropdown>
                                </div>
                            </b-td>
                            <b-td class="vertical-align" :class="{'day-container': true,'selected-day': selectedDay === day,'non-selected-day': selectedDay !== day}">
                                <div class="d-flex justify-content-center align-items-center">
                                    <b-dropdown id="dropdown-2" v-if="day.pollinationStatus !== 'DELETED' && day.pollinationStatus !== 'NO-EVENT'" @shown="getEmasculationPollinationOptions(day, 'Pollination')"
                                                :variant="day.pollinationStatus === 'ACTIVE' ? day.pollinationRingLabel.replace(' ','-') : day.pollinationRingLabel.replace(' ','-')+'-inactive'" no-caret>
                                        <template #button-content>
                                            <span>{{ day.pollinationRingLabel }}</span>
                                            <span class="ml-2" v-if="day.pollinationComments > 0">
                                                <b-badge variant="secondary" v-b-tooltip="'Comments'">{{ day.pollinationComments }}</b-badge>
                                            </span>
                                            <span :class="day.pollinationComments > 0 ? '' : 'ml-2'" v-if="day.pollinationTubes > 0">
                                                <b-badge variant="primary" v-b-tooltip="'Pollination tubes'">{{ day.pollinationTubes }}</b-badge>
                                            </span>
                                        </template>
                                        <template v-if="day.showChangeColorOption && hasPermission">
                                            <b-dropdown-item v-for="(item, index) in day.listColors" :key="index" class="d-block" @click="changeEmasculationPollinationColor(day, item)">
                                                <p class="d-inline-block" style="width: 60%; vertical-align: middle;">Change</p>
                                                <p :class="item.name + ' color-option d-inline-block'" style="width: 50%; vertical-align: middle"></p>
                                            </b-dropdown-item>
                                        </template>
                                        <b-dropdown-item @click="changeStatus(day, 2)" v-if="hasPermission">{{ day.pollinationStatus === 'ACTIVE' ? 'Cancel' : 'Undo cancel' }}</b-dropdown-item>
                                        <b-dropdown-item @click="deleteRing(day, 2)" v-if="hasPermission">Delete</b-dropdown-item>
                                    </b-dropdown>
                                    <b-dropdown v-else variant="transparent" no-caret @shown="getAddOptions(2, day)">
                                        <template v-if="hasPermission">
                                            <b-dropdown-item v-for="(item, index) in day.listColors" :key="index" class="d-block" @click="createRing(day, 2, item)">
                                                <p class="d-inline-block" style="width: 60%; vertical-align: middle;">Add</p>
                                                <p :class="(item.name === 'No ring' ? 'No-ring' : item.name) + ' color-option d-inline-block'" style="width: 50%; vertical-align: middle"></p>
                                            </b-dropdown-item>
                                        </template>
                                    </b-dropdown>
                                </div>
                            </b-td>
                        </b-tr>
                    </b-tbody>
                </b-table-simple>
            </b-col>
            <b-col class="col-6 w-100" v-if="emasculationPollinationDetail" style="max-height: 100vh; overflow-y: scroll;">
                <emasculation-pollination-component :data="emasculationPollinationItem" :production-line-id="productionLineId" ref="emasculationComponent" @load-data="loadData"
                                                     week-number="1"  class="d-block" :key="counter"></emasculation-pollination-component>
            </b-col>
    </b-row>
    </div>
</template>

<script>
import dayjs from 'dayjs';
import EmasculationPollinationComponent from '@/views/EmasculationPollination/EmasculationPollinationComponent.vue';
import EmasculationPollinationCommentComponent from '@/views/EmasculationPollination/EmasculationPollinationCommentsComponent.vue';
import { extend } from 'vee-validate';
import { required } from 'vee-validate/dist/rules';
import {
    deleteEmasculationUrl,
    deletePollinationUrl,
    deleteSetUrl,
    getBaseImageUrl,
    getImgUrl,
    getShowAddColorOptionEmasculationUrl,
    getShowAddColorOptionPollinationUrl,
    getShowChangeColorOptionUrl,
    readyCalendarUrl,
    resetCalendarUrl,
    saveEmasculationUrl,
    savePollinationUrl,
    updateEmasculationStatusUrl,
    updateEmasculationUrl,
    updatePollinationStatusUrl
} from '@routes';
import $permission from '@/js/permission';
import { formatDate } from '@/js/utilities';
import customDatepicker from '@components/customDatepicker.vue';

const Excel = require('exceljs');

extend('required', {
    ...required,
    message: 'Field required'
});

export default {
    props: {
        data: {
            type: Object,
            required: true
        },
        productionLineId: {
            type: Number,
            required: true
        },
        productionLine: {
            type: Object,
            required: true
        },
        showEmasculationColumnSet: {
            type: Boolean,
            required: true
        },
        cropFamily: {
            type: String,
            required: true
        },
        index: {
            type: Number,
            required: true
        },
        showEditDates: {
            type: Boolean,
            required: true
        }
    },
    data() {
        return {
            imageBaseUrl: {
                base64: null,
                extension: null
            },
            emasculationPollination: {
                action: null,
                id: null,
                setNumber: null,
                startDate: null,
                weeks: null,
                weeksToAdd: null
            },
            weeks: [],
            showDetail: false,
            days: [2, 3, 4, 5, 6, 7],
            emasculationPollinationItem: null,
            setNumber: null,
            setId: null,
            emasculationPollinationDetail: false,
            selectedDay: null,
            counter: 0,
            calendar: [],
            url: null,
            itemData: null,
            disableInputs: false,
            showInputAddWeeks: false,
            toastTitle: 'Production lines',
            urlImg: getImgUrl(),
            hasPermission: $permission.check('ROLE_PRLI_EMPO_C')
        };
    },
    components: {
        customDatepicker,
        EmasculationPollinationCommentComponent,
        EmasculationPollinationComponent
    },
    created() {
        this.emasculationPollination = this.data;

        if (this.emasculationPollination.action !== 'create') {
            this.calendar = this.data.emasculationPollinationData;
            this.setNumber = this.data.setNumber;
            this.setId = this.data.id;
        }
    },
    watch: {
        data: {
            immediate: true,
            handler() {
                this.processData();
            }
        }
    },
    methods: {
        processData() {
            this.emasculationPollination = this.data;

            if (this.emasculationPollination.id) {
                this.disableInputs = true;
            }

            if (this.emasculationPollination.action !== 'create') {
                this.calendar = this.data.emasculationPollinationData;
                this.setNumber = this.data.setNumber;
            }
        },
        saveSetData() {
            this.$emit('save-stage-data');
        },
        setData() {
            this.emasculationPollination.setNumber = this.data.setNumber;
            this.$emit('set-data', 11, this.emasculationPollination);
        },
        formatDate(date) {
            let dateFormat;

            if (date) {
                dateFormat = dayjs(date.toString(), 'DD/MM/YYYY');
            }

            return date ? dateFormat.format('DD/MM/YYYY') : '';
        },
        getDayName(date) {
            let dateFormat;

            if (date) {
                dateFormat = dayjs(date.toString(), 'DD/MM/YYYY');
            }

            return date ? dateFormat.format('ddd') : '';
        },
        openDetail(item) {
            this.counter++;
            this.emasculationPollinationItem = item;
            this.selectedDay = item;

            if (!this.emasculationPollinationDetail) {
                this.emasculationPollinationDetail = true;
            }
        },
        deleteRing(item, type) {
            let url, id;
            id= item.id;

            if (type === 1) {
                url = deleteEmasculationUrl();
            } else if (type === 2) {
                url = deletePollinationUrl();
            }

            this.$swal({
                title: 'Delete ring',
                icon: 'warning',
                iconColor: '#bf0d0d',
                text: 'Are you sure you want to delete this ring?',
                cancelButtonText: 'Cancel',
                confirmButtonColor: '#bf0d0d',
                confirmButtonText: 'Yes'
            }).then(result => {
                if (result.value) {
                    this.axios.post(url, {
                        id: id
                    }).then(response => {
                        if (response.data.code === 'OK') {
                            this.$bvToast.toast(response.data.message, {
                                title: this.toastTitle,
                                variant: 'success'
                            });

                            this.emasculationPollinationDetail = false;
                            this.emasculationPollinationItem = null;

                            this.$nextTick(() => {
                                this.$emit('load-data');
                            });
                        } else {
                            this.$bvToast.toast(response.data.message, {
                                title: this.toastTitle,
                                variant: 'danger'
                            });
                        }
                    }).catch(error => {
                        if (error.response) {
                            this.$bvToast.toast(error.response.data.message, {
                                title: this.toastTitle,
                                variant: 'danger'
                            });
                        } else if (error.request) {
                            this.$bvToast.toast('An error occurred while executing the request', {
                                title: this.toastTitle,
                                variant: 'danger'
                            });
                        } else {
                            this.$bvToast.toast('An error occurred while configuring the request', {
                                title: this.toastTitle,
                                variant: 'danger'
                            });
                        }
                    }).finally(() => {
                        this.isLoading = false;
                    });
                }
            });
        },
        changeStatus(item, type) {
            let action, id, title;
            id = item.id;

            if (type === 1) {
                this.statusUrl = updateEmasculationStatusUrl();
                action = item.emasculationStatus === 'ACTIVE' ? 'Cancel' : 'Undo cancel for';
                title = item.emasculationStatus === 'ACTIVE' ? 'Cancel' : 'Undo cancel';
            } else if (type === 2) {
                this.statusUrl = updatePollinationStatusUrl();
                action = item.pollinationStatus === 'ACTIVE' ? 'Cancel' : 'Undo cancel for';
                title = item.pollinationStatus === 'ACTIVE' ? 'Cancel' : 'Undo cancel';
            }

            this.$swal({
                title: `${title}`,
                icon: 'warning',
                text: `Are you sure you want to ${action} this ring?`,
                cancelButtonText: 'Cancel',
                confirmButtonColor: '#97bf0d',
                confirmButtonText: 'Yes'
            }).then(result => {
                if (result.value) {
                    this.axios.post(this.statusUrl, {
                        id: id
                    }).then(response => {
                        if (response.status === 200) {
                            this.$bvToast.toast(response.data.message, {
                                title: this.toastTitle,
                                variant: 'success'
                            });

                            this.$nextTick(() => {
                                this.$emit('load-data');

                                if (type === 1) {
                                    item.emasculationStatus = response.data.emasculationStatus;
                                } else if (type === 2) {
                                    item.pollinationStatus = response.data.pollinationStatus;
                                }

                            });
                        } else {
                            this.$bvToast.toast(response.data.message, {
                                title: this.toastTitle,
                                variant: 'danger'
                            });
                        }
                    }).catch(error => {
                        if (error.response) {
                            this.$bvToast.toast(error.response.data.message, {
                                title: this.toastTitle,
                                variant: 'danger'
                            });
                        } else if (error.request) {
                            this.$bvToast.toast('An error occurred while executing the request', {
                                title: this.toastTitle,
                                variant: 'danger'
                            });
                        } else {
                            this.$bvToast.toast('An error occurred while configuring the request', {
                                title: this.toastTitle,
                                variant: 'danger'
                            });
                        }

                    }).finally(() => {
                        this.isLoading = false;
                    });
                }
            });
        },
        getEmasculationPollinationOptions(item, type = null) {
            let sequenceColor;
            if (type === 'Emasculation') {
                sequenceColor = item.emasculationColorSequence;
            } else {
                sequenceColor = item.pollinationColorSequence;
            }

            if (this.productionLine.cropFamilyName !== 'Tomato') {
                this.axios.post(getShowChangeColorOptionUrl(), {
                    id: item.id,
                    date: item.date,
                    productionLineId: item.productionLineId,
                    setId: item.setId,
                    sequenceColor: sequenceColor,
                    day: item.day,
                    type: type
                }).then(response => {
                    if (response.status === 200) {
                        if (response.data.showColorOption) {
                            // this.$nextTick(() => {
                            item.showChangeColorOption = true;
                            // });
                        }

                        this.$forceUpdate();

                        if (response.data.listColors) {
                            // this.$nextTick(() => {
                            item.listColors = response.data.listColors;
                            // });
                        }
                    } else {
                        this.$bvToast.toast(response.data.message, {
                            title: this.toastTitle,
                            variant: 'danger'
                        });
                    }
                }).catch(error => {
                    if (error.response) {
                        this.$bvToast.toast(error.response.data.message, {
                            title: this.toastTitle,
                            variant: 'danger'
                        });
                    } else if (error.request) {
                        this.$bvToast.toast('An error occurred while executing the request', {
                            title: this.toastTitle,
                            variant: 'danger'
                        });
                    } else {
                        this.$bvToast.toast('An error occurred while configuring the request', {
                            title: this.toastTitle,
                            variant: 'danger'
                        });
                    }

                }).finally(() => {
                    this.isLoading = false;
                });
            }
        },
        getAddOptions(type, item) {
            let url;
            if (type === 1) {
                url = getShowAddColorOptionEmasculationUrl();
            } else {
                url = getShowAddColorOptionPollinationUrl();
            }
            this.axios.post(url, {
                id: item.id
            }).then(response => {
                if (response.status === 200) {
                    if (response.data.showColorOption) {
                        // this.$nextTick(() => {
                        item.showChangeColorOption = true;
                        // });
                    }

                    this.$forceUpdate();

                    if (response.data.listColors) {
                        // this.$nextTick(() => {
                        item.listColors = response.data.listColors;
                        // });
                    }

                    this.$forceUpdate();
                } else {
                    this.$bvToast.toast(response.data.message, {
                        title: this.toastTitle,
                        variant: 'danger'
                    });
                }
            }).catch(error => {
                if (error.response) {
                    this.$bvToast.toast(error.response.data.message, {
                        title: this.toastTitle,
                        variant: 'danger'
                    });
                } else if (error.request) {
                    this.$bvToast.toast('An error occurred while executing the request', {
                        title: this.toastTitle,
                        variant: 'danger'
                    });
                } else {
                    this.$bvToast.toast('An error occurred while configuring the request', {
                        title: this.toastTitle,
                        variant: 'danger'
                    });
                }

            }).finally(() => {
                this.isLoading = false;
            });
        },
        changeEmasculationPollinationColor(day, itemColor) {
            this.url = updateEmasculationUrl();
            this.itemData = {
                'id': day.id,
                'date': day.date,
                'productionLineId': day.productionLineId,
                'setId': day.setId,
                'week': day.week,
                'color': {
                    'name': itemColor.name,
                    'rgb': itemColor.rgb,
                    'id': itemColor.id
                }
            };

            this.$swal({
                title: 'Change ring color',
                icon: 'warning',
                html: 'Are you sure you want to change the color of the ring? <br> This will recalculate the colors of the rings from this date',
                cancelButtonText: 'Cancel',
                confirmButtonText: 'Yes',
                confirmButtonColor: '#97bf0d'
            }).then(result => {
                if (result.value) {
                    this.saveData();
                }
            });

        },
        createRing(day, type, itemColor) {
            if (type === 1) {
                this.url = saveEmasculationUrl();
            } else if (type === 2) {
                this.url = savePollinationUrl();
            }

            this.itemData = {
                'id': day.id,
                'color': {
                    'name': itemColor.name,
                    'rgb': itemColor.rgb,
                    'id': itemColor.id
                }
            };

            this.saveData();
        },
        saveData() {
            this.isLoading = true;

            this.axios.post(this.url, {
                data: this.itemData
            }).then(response => {
                if (response.status === 200) {
                    // this.$bvToast.toast(response.data.message, {
                    //     title: this.toastTitle,
                    //     variant: 'success'
                    // });

                    this.$emit('load-data');
                } else {
                    this.$bvToast.toast(response.data.message, {
                        title: this.toastTitle,
                        variant: 'danger'
                    });
                }
            }).catch(error => {
                if (error.response) {
                    this.$bvToast.toast(error.response.data.message, {
                        title: this.toastTitle,
                        variant: 'danger'
                    });
                } else if (error.request) {
                    this.$bvToast.toast('An error occurred while executing the request', {
                        title: this.toastTitle,
                        variant: 'danger'
                    });
                } else {
                    this.$bvToast.toast('An error occurred while configuring the request', {
                        title: this.toastTitle,
                        variant: 'danger'
                    });
                }
            }).finally(() => {
                this.isLoading = false;
            });
        },
        resetCalendar() {
            this.$swal({
                title: `Reset calendar`,
                icon: 'warning',
                text: `Are you sure you want to reset the calendar.`,
                cancelButtonText: 'Cancel',
                confirmButtonColor: '#bf0d0d',
                iconColor: '#bf0d0d',
                confirmButtonText: 'Yes'
            }).then(result => {
                if (result.value) {
                    this.axios.post(resetCalendarUrl(), {
                        id: this.emasculationPollination.id
                    }).then(response => {
                        if (response.data.code === 'OK') {
                            this.$bvToast.toast(response.data.message, {
                                title: this.toastTitle,
                                variant: 'success'
                            });

                            this.emasculationPollinationDetail = false;
                            this.emasculationPollinationItem = null;
                        } else {
                            this.$bvToast.toast(response.data.message, {
                                title: this.toastTitle,
                                variant: 'danger'
                            });
                        }

                        this.$nextTick(() => {
                            // todo emit new function to focus current set and then load data
                            this.$emit('load-data');
                        });
                    }).catch(error => {
                        if (error.response) {
                            this.$bvToast.toast(error.response.data.message, {
                                title: this.toastTitle,
                                variant: 'danger'
                            });
                        } else if (error.request) {
                            this.$bvToast.toast('An error occurred while executing the request', {
                                title: this.toastTitle,
                                variant: 'danger'
                            });
                        } else {
                            this.$bvToast.toast('An error occurred while configuring the request', {
                                title: this.toastTitle,
                                variant: 'danger'
                            });
                        }

                    }).finally(() => {
                        this.isLoading = false;
                    });
                }
            });
        },
        readyCalendar() {
            this.$swal({
                title: `Set ready`,
                icon: 'success',
                text: `Are you sure you want to mark this set as ready.`,
                cancelButtonText: 'Cancel',
                confirmButtonColor: '#97bf0d',
                iconColor: '#97bf0d',
                confirmButtonText: 'Yes'
            }).then(result => {
                if (result.value) {
                    this.axios.post(readyCalendarUrl(), {
                        id: this.emasculationPollination.id
                    }).then(response => {
                        if (response.status === 200) {
                            this.$bvToast.toast(response.data.message, {
                                title: this.toastTitle,
                                variant: 'success'
                            });

                            this.emasculationPollinationDetail = false;
                            this.emasculationPollinationItem = null;
                        } else {
                            this.$bvToast.toast(response.data.message, {
                                title: this.toastTitle,
                                variant: 'danger'
                            });
                        }

                        this.$nextTick(() => {
                            this.$emit('load-data');
                        });
                    }).catch(error => {
                        if (error.response) {
                            this.$bvToast.toast(error.response.data.message, {
                                title: this.toastTitle,
                                variant: 'danger'
                            });
                        } else if (error.request) {
                            this.$bvToast.toast('An error occurred while executing the request', {
                                title: this.toastTitle,
                                variant: 'danger'
                            });
                        } else {
                            this.$bvToast.toast('An error occurred while configuring the request', {
                                title: this.toastTitle,
                                variant: 'danger'
                            });
                        }

                    }).finally(() => {
                        this.isLoading = false;
                    });
                }
            });
        },
        deleteCalendar() {
            this.$swal({
                title: `Delete set`,
                icon: 'warning',
                text: `Are you sure you want to delete this set.`,
                cancelButtonText: 'Cancel',
                confirmButtonColor: '#bf0d0d',
                iconColor: '#bf0d0d',
                confirmButtonText: 'Yes'
            }).then(result => {
                if (result.value) {
                    this.isLoading = true;
                    this.axios.post(deleteSetUrl(), {
                        id: this.emasculationPollination.id
                    }).then(response => {
                        if (response.status === 200) {
                            this.$nextTick(() => {
                                this.$emit('load-data', response.data);
                            });
                        } else {
                            this.$bvToast.toast(response.data.message, {
                                title: this.toastTitle,
                                variant: 'danger'
                            });
                        }
                    }).catch(error => {
                        if (error.response) {
                            this.$bvToast.toast(error.response.data.message, {
                                title: this.toastTitle,
                                variant: 'danger'
                            });
                        } else if (error.request) {
                            this.$bvToast.toast('An error occurred while executing the request', {
                                title: this.toastTitle,
                                variant: 'danger'
                            });
                        } else {
                            this.$bvToast.toast('An error occurred while configuring the request', {
                                title: this.toastTitle,
                                variant: 'danger'
                            });
                        }

                    }).finally(() => {
                        this.isLoading = false;
                    });
                }
            });
        },
        showEdit() {
            this.$emit('show-edit-view');
        },
        loadData() {
            this.$emit('load-data');
        },
        async exportCalendarToExcel() {
            const workbook = new Excel.Workbook();

            let sheet = workbook.addWorksheet('POLLINATION PROGRAM');
            let columns = [];
            let headers = [
                {
                    key: 'Year week',
                    width: 11
                },
                {
                    key: 'Week',
                    width: 9
                },
                {
                    key: 'Day',
                    width: 11
                },
                {
                    key: 'Date',
                    width: 16
                },
                {
                    key: 'Emasculation',
                    width: 13
                },
                {
                    key: 'Pollination',
                    width: 12
                },
                {
                    key: '',
                    width: 40
                },
                {
                    key: '',
                    width: 40
                }
            ];

            let headersWithoutEmasculation = [
                {
                    key: 'Year week',
                    width: 11
                },
                {
                    key: 'Week',
                    width: 9
                },
                {
                    key: 'Day',
                    width: 11
                },
                {
                    key: 'Date',
                    width: 16
                },
                {
                    key: 'Pollination',
                    width: 12
                },
                {
                    key: '',
                    width: 40
                },
                {
                    key: '',
                    width: 40
                }
            ];

            if (this.showEmasculationColumnSet) {
                for (let header of headers) {
                    columns.push({ key: header.key, width: header.width, style: header.style });
                }
            } else {
                for (let header of headersWithoutEmasculation) {
                    columns.push({ key: header.key, width: header.width, style: header.style });
                }
            }

            sheet.columns = columns;
            let rowIndex = 4;
            let offset = this.showEmasculationColumnSet ? 1 : 0;
            let subheader = 'POLLINATION PROGRAM VARIETY ' + (this.productionLine.figureName ? this.productionLine.figureName : '');

            sheet.addRow(['Set ' + Number(Number(this.index) + 1)]);
            sheet.addRow(['COMPARTMENT ' + this.productionLine.compartmentName]);
            sheet.addRow([subheader]);

            if (this.showEmasculationColumnSet) {
                sheet.addRow(headers.map(header => header.key));
            } else {
                sheet.addRow(headersWithoutEmasculation.map(header => header.key));
            }

            sheet.getRow(rowIndex).alignment = { wrapText: true };
            sheet.getRow(rowIndex).height = 25;

            const compartmentStyle = {
                font: {
                    name: 'Calibri',
                    bold: true,
                    size: 14
                },
                alignment: {
                    horizontal: 'center'
                }
            };
            const headerStyle = {
                border: {
                    top: { style: 'medium', color: { argb: 'FF000000' }},
                    left: { style: 'medium', color: { argb: 'FF000000' }},
                    bottom: { style: 'medium', color: { argb: 'FF000000' }},
                    right: { style: 'medium', color: { argb: 'FF000000' }}
                },
                font: {
                    name: 'Calibri',
                    bold: true
                },
                alignment: {
                    horizontal: 'center'
                }
            };

            const setStyle = {
                font: {
                    name: 'Calibri',
                    bold: true,
                    size: 14,
                    color: { argb: 'ff0000' }
                },
                alignment: {
                    horizontal: 'center'
                }
            };

            const varietyStyle = {
                font: {
                    name: 'Calibri',
                    bold: true,
                    size: 12
                },
                alignment: {
                    horizontal: 'center'
                }
            };

            sheet.getCell('A1').style = setStyle;
            sheet.getCell('A2').style = compartmentStyle;
            sheet.getCell('A3').style = varietyStyle;
            sheet.getCell('A4').style = headerStyle;
            sheet.getCell('B4').style = headerStyle;
            sheet.getCell('C4').style = headerStyle;
            sheet.getCell('D4').style = headerStyle;
            sheet.getCell('E4').style = headerStyle;

            if (this.showEmasculationColumnSet) {
                sheet.getCell('F4').style = headerStyle;
            }

            sheet.getRow(1).height = 26;
            sheet.getRow(2).height = 26;
            sheet.getRow(3).height = 26;
            sheet.mergeCells(1, 1, 1, 5 + offset);
            sheet.mergeCells(2, 1, 2, 5 + offset);
            sheet.mergeCells(3, 1, 3, 5 + offset);

            let color, label, status;

            if (this.imageBaseUrl.base64) {
                const imageId2 = workbook.addImage({
                    base64: this.imageBaseUrl.base64,
                    extension: this.imageBaseUrl.extension
                });

                let range = this.showEmasculationColumnSet ? 'F1:F3' : 'E1:E3';

                sheet.addImage(imageId2, range);
            }

            this.calendar.forEach(date => {
                rowIndex++;

                if (this.showEmasculationColumnSet) {
                    sheet.addRow([date.yearWeek, date.week, this.getDayName(date.date), this.formatDate(date.date), date.emasculationRingLabel, date.pollinationRingLabel]);
                    color = date.emasculationRingColor;
                    label = date.emasculationRingLabel;
                    status = date.emasculationStatus;
                } else {
                    sheet.addRow([date.yearWeek, date.week, this.getDayName(date.date), this.formatDate(date.date), date.pollinationRingLabel]);
                    color = date.pollinationRingColor;
                    label = date.pollinationRingLabel;
                    status = date.pollinationStatus;
                }
                sheet.getRow(rowIndex).height = 20;
                sheet.getCell('A' +rowIndex).alignment = { horizontal: 'center' };
                sheet.getCell('B' +rowIndex).alignment = { horizontal: 'center' };
                sheet.getCell('C' +rowIndex).alignment = { horizontal: 'center' };
                sheet.getCell('D' +rowIndex).alignment = { horizontal: 'center' };
                sheet.getCell('E' +rowIndex).alignment = { horizontal: 'center' };

                sheet.getCell('E' + rowIndex).fill = {
                    type: 'pattern',
                    pattern: 'solid',
                    fgColor:{ argb: color ? color.replace('#', '') : 'd9d9d9' }
                };

                sheet.getCell('E' + rowIndex).font = {
                    name: 'Calibri',
                    color: { argb: label === 'Blue' || label === 'Black' ? 'ffffff' : '000000' },
                    strike:  status !== 'ACTIVE'
                };

                if (this.showEmasculationColumnSet) {
                    sheet.getCell('F' + rowIndex).fill = {
                        type: 'pattern',
                        pattern: 'solid',
                        fgColor:{ argb: date.pollinationRingColor ? date.pollinationRingColor.replace('#', '') : 'd9d9d9' }
                    };

                    sheet.getCell('F' + rowIndex).font = {
                        name: 'Calibri',
                        color: { argb: date.pollinationRingLabel === 'Blue' || date.pollinationRingLabel === 'Black' ? 'ffffff' : '000000' },
                        strike:  date.pollinationStatus !== 'ACTIVE'
                    };

                    sheet.getCell('F' +rowIndex).alignment = { horizontal: 'center' };

                    sheet.getCell('F' + rowIndex).border = {
                        top: { style: 'thin' },
                        left: { style: 'thin' },
                        bottom: { style: date.day === 0 ? 'medium' : 'thin' },
                        right: { style: 'medium' }
                    };

                    sheet.getCell('G' +rowIndex).value = date.emasculationCommentsValues;
                    sheet.getCell('H' +rowIndex).value = date.pollinationCommentsValues;
                } else {
                    sheet.getCell('F' +rowIndex).value = date.emasculationCommentsValues;
                    sheet.getCell('G' +rowIndex).value = date.pollinationCommentsValues;
                }

                sheet.getCell('A' + rowIndex).border = {
                    top: { style: 'thin' },
                    left: { style: 'medium' },
                    bottom: { style: date.day === 0 ? 'medium' : 'thin' },
                    right: { style: 'thin' }
                };

                sheet.getCell('B' + rowIndex).border = {
                    top: { style: 'thin' },
                    left: { style: 'thin' },
                    bottom: { style: date.day === 0 ? 'medium' : 'thin' },
                    right: { style: 'thin' }
                };
                sheet.getCell('C' + rowIndex).border = {
                    top: { style: 'thin' },
                    left: { style: 'thin' },
                    bottom: { style: date.day === 0 ? 'medium' : 'thin' },
                    right: { style: 'thin' }
                };
                sheet.getCell('D' + rowIndex).border = {
                    top: { style: 'thin' },
                    left: { style: 'thin' },
                    bottom: { style: date.day === 0 ? 'medium' : 'thin' },
                    right: { style: 'thin' }
                };

                sheet.getCell('E' + rowIndex).border = {
                    top: { style: 'thin' },
                    right: { style: this.showEmasculationColumnSet ? 'thin' : 'medium' },
                    bottom: { style: date.day === 0 ? 'medium' : 'thin' },
                    left: { style: 'thin' }
                };
            });

            let startMerge = 5;
            let length = 11;

            for (let i = 0; i < (rowIndex - 7); i+=7) {
                sheet.mergeCells('A' + startMerge + ':A' + (length));
                sheet.mergeCells('B' + startMerge + ':B' + (length));
                sheet.getCell('A' + startMerge).alignment = { vertical: 'middle', horizontal: 'center' };
                sheet.getCell('A' + startMerge).border = { bottom: { style: 'medium' }, left: { style: 'medium' }, right: { style: 'thin' }};
                sheet.getCell('B' + startMerge).border = { bottom: { style: 'medium' }};
                sheet.getCell('B' + startMerge).alignment = { vertical: 'middle', horizontal: 'center' };
                startMerge+= 7;
                length+=7;
            }

            rowIndex += 4;

            let text, date, lastLabel, lastColor, lastPollinationLabel, lastPollinationColor;

            if (this.showEmasculationColumnSet) {
                text = 'Last emasculation:';
                date = this.emasculationPollination.lastEmasculationDate;
                lastLabel = this.emasculationPollination.lastEmasculationLabel;
                lastColor = this.emasculationPollination.lastEmasculationColor;
                lastPollinationLabel = this.emasculationPollination.lastPollinationLabel;
                lastPollinationColor = this.emasculationPollination.lastPollinationColor;
            } else {
                text = 'Last pollination:';
                date = this.emasculationPollination.lastPollinationDate;
                lastLabel = this.emasculationPollination.lastPollinationLabel;
                lastColor = this.emasculationPollination.lastPollinationColor;
                lastPollinationLabel = this.emasculationPollination.lastPollinationLabel;
                lastPollinationColor = this.emasculationPollination.lastPollinationColor;
            }

            sheet.getCell('A' +rowIndex).value = text;
            sheet.mergeCells('A' + rowIndex+ ':B' + rowIndex);
            sheet.getCell('C' +rowIndex).value = this.formatDate(date);
            sheet.getCell('C' +rowIndex).alignment = { horizontal: 'center' };
            sheet.getCell('C' +rowIndex).font = {
                name: 'Calibri',
                bold: true,
                color: { argb:
                        lastLabel === 'Blue' || lastLabel === 'Black'
                            ? 'ffffff'
                            : '000000'
                }};

            sheet.getCell('C' +rowIndex).fill = {
                type: 'pattern',
                pattern: 'solid',
                fgColor:{ argb: lastColor ? lastColor.replace('#', '') : '' }
            };
            sheet.getCell('C' +rowIndex).border = {
                top: { style: 'thin' },
                left: { style: 'thin' },
                bottom: { style: 'thin' },
                right: { style: 'thin' }
            };

            sheet.getCell('D' +rowIndex).value = 'Last ring color:  ';
            sheet.getCell('D' +rowIndex).alignment = { horizontal: 'right' };
            sheet.getCell('E' +rowIndex).value = this.emasculationPollination.lastPollinationLabel;
            sheet.getCell('E' +rowIndex).fill = {
                type: 'pattern',
                pattern: 'solid',
                fgColor:{ argb: this.emasculationPollination.lastPollinationColor ? this.emasculationPollination.lastPollinationColor.replace('#', '') : '' }
            };
            sheet.getCell('E' +rowIndex).alignment = { horizontal: 'center' };
            sheet.getCell('E' +rowIndex).border = {
                top: { style: 'thin' },
                left: { style: 'thin' },
                bottom: { style: 'thin' },
                right: { style: 'thin' }
            };
            sheet.getCell('E' +rowIndex).font = {
                name: 'Calibri',
                bold: true,
                color: { argb:
                    lastPollinationLabel === 'Blue' || lastPollinationLabel === 'Black'
                        ? 'ffffff'
                        : '000000'
                }};
            sheet.getRow(rowIndex).height = 20;
            rowIndex += 2;

            if (this.showEmasculationColumnSet) {
                sheet.getCell('A' +rowIndex).value = 'Last pollination:';
                sheet.mergeCells('A' + rowIndex+ ':B' + rowIndex);
                sheet.getCell('C' +rowIndex).value = this.formatDate(this.emasculationPollination.lastPollinationDate);
                sheet.getCell('C' +rowIndex).alignment = { horizontal: 'center' };
                sheet.getRow(rowIndex).height = 20;
                sheet.getCell('C' +rowIndex).fill = {
                    type: 'pattern',
                    pattern: 'solid',
                    fgColor:{ argb: this.emasculationPollination.lastPollinationColor ? this.emasculationPollination.lastPollinationColor.replace('#', '') : '' }
                };
                sheet.getCell('C' +rowIndex).border = {
                    top: { style: 'thin' },
                    left: { style: 'thin' },
                    bottom: { style: 'thin' },
                    right: { style: 'thin' }
                };
                sheet.getCell('C' +rowIndex).font = {
                    name: 'Calibri',
                    bold: true,
                    color: { argb:
                            lastPollinationLabel === 'Blue' || lastPollinationLabel === 'Black'
                                ? 'ffffff'
                                : '000000'
                    }};

                rowIndex += 2;
                sheet.getCell('F' +rowIndex).border = {
                    bottom: { style: 'thin' }
                };
            }

            sheet.getCell('A' +rowIndex).value = 'Worked by:';
            sheet.getCell('B' +rowIndex).border = {
                bottom: { style: 'thin' }
            };
            sheet.getCell('C' +rowIndex).border = {
                bottom: { style: 'thin' }
            };
            sheet.getCell('D' +rowIndex).border = {
                bottom: { style: 'thin' }
            };
            sheet.getCell('E' +rowIndex).border = {
                bottom: { style: 'thin' }
            };

            sheet.getRow(rowIndex).height = 20;

            const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';

            const buffer = await workbook.xlsx.writeBuffer();

            const blob = new Blob([buffer], { type: fileType });

            return blob;
        },
        showConfirmSaveExcel() {
            this.loadData();
            this.$swal({
                title: `Download excel`,
                icon: 'warning',
                text: ``,
                cancelButtonText: 'Cancel',
                confirmButtonColor: '#97bf0d',
                confirmButtonText: 'Yes'
            }).then(result => {
                if (result.value) {
                    if (this.productionLine.figureGraphic) {
                        this.getBase64Image(this.productionLine.fileId);
                    } else {
                        this.saveExcel();
                    }
                }
            });
        },
        async saveExcel() {
            const blob = await this.exportCalendarToExcel();

            const url = window.URL.createObjectURL(blob);

            const link = document.createElement('a');
            link.href = url;
            const fileName = `${this.productionLine.varietyName} ${this.productionLine.tag ? '-' + this.productionLine.tag : ''}_Compartment ${this.productionLine.compartmentName}_Set ${Number(Number(this.index) + 1)}`;
            link.setAttribute('download', fileName +'.xlsx');

            const clicEvent = new MouseEvent('click', {
                view: window,
                bubbles: true,
                cancelable: true
            });
            link.dispatchEvent(clicEvent);

            window.URL.revokeObjectURL(url);
        },
        getBase64Image(fileId) {
            this.axios.get(getBaseImageUrl(fileId)).then(response => {
                if (response.status === 200) {
                    this.imageBaseUrl.base64 = `data:image/${response.data.data.extension};base64,${response.data.data.base64}`;
                    this.imageBaseUrl.extension = response.data.data.extension;
                    this.$nextTick(() => {
                        this.saveExcel();
                    });
                } else {
                    this.$bvToast.toast(response.data.message, {
                        title: this.toastTitle,
                        variant: 'danger'
                    });
                }
            }).catch(error => {
                this.imageBaseUrl.base64 = null;
                this.imageBaseUrl.extension = null;
                this.saveExcel();
            }).finally(() => {
                this.isLoading = false;
            });
        },
        formatDatepicker(value, ref) {
            if (value) {
                this.$nextTick(() => {
                    this.$refs[ref].formattedValue = formatDate(value);
                });
            }
        }
    }
};
</script>

<style>
    .dropdown-toggle {
        width: 100px;
        height: 40px;
        margin-top: 4px;
        color: #000;
        border-radius: 1em;
        padding: 6px 12px;
        margin-right: 4px;
        margin-bottom: 4px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 11px;
    }

    .btn-White, .White {
        background-color: #FFFFFF;
        border: 2px solid #DEE4E4;
    }

    .White-inactive {
        background-color: transparent;
        border: 2px solid #ccc;
        text-decoration: line-through;
    }

    .btn-White-inactive {
        background-color: transparent;
        border: 2px solid #DEE4E4;
        text-decoration: line-through;
    }

    .btn-Pink, .Pink {
        background-color: #FF6699;
        border: 2px solid rgba(169, 29, 76, 0.99);
    }

    .Pink-inactive {
        background-color: transparent;
        border: 2px solid #FF6699;
        text-decoration: line-through;
    }

    .btn-Pink-inactive {
        background-color: transparent;
        border: 2px solid #FF6699;
        text-decoration: line-through;
    }

    .btn-Purple, .Purple {
        background-color: #b782e6;
        border: 2px solid #8425d9;

    }

    .btn-Purple-inactive {
        background-color: transparent;
        border: 2px solid #b782e6;
        text-decoration: line-through;
    }

    .btn-Yellow, .Yellow {
        background-color: #FFFF01;
        border: 2px solid #dede3b;
    }

    .btn-Yellow-inactive {
        background-color: transparent;
        border: 2px solid #FFFF01;
        text-decoration: line-through;
    }

    .btn-Orange, .Orange {
        background-color: #FA6503;
        border: 2px solid #ad5922;
    }

    .btn-Orange-inactive {
        background-color: transparent;
        border: 2px solid #FA6503;
        text-decoration: line-through;
    }

    .btn-Bronze, .Bronze {
        background-color: #b98d07;
        border: 2px solid #987f32;
    }

    .btn-Bronze-inactive {
        background-color: transparent;
        border: 2px solid #b98d07;
        text-decoration: line-through;
    }

    .btn-Turquoise, .Turquoise {
        background-color: #2CC2FF;
        border: 2px solid #1e8cb9;
    }

    .btn-Turquoise-inactive {
        background-color: transparent;
        border: 2px solid #2CC2FF;
        text-decoration: line-through;
    }

    .btn-Black, .Black {
        background-color: #000000;
        border: 2px solid #000000;
        color: #FFFFFF;
    }

    .btn-Black-inactive {
        background-color: transparent;
        border: 2px solid #000000;
        text-decoration: line-through;
    }

    .btn-Brown, .Brown {
        background-color: #843C0B;
        border: 2px solid #ad5922;
        /*color: #FFFFFF;*/
    }

    .btn-Brown-inactive {
        background-color: transparent;
        border: 2px solid #843C0B;
        text-decoration: line-through;
    }

    .Blue {
        background-color: #002060;
        border: 2px solid #031741;
        color: #FFFFFF;
    }

    .btn-Blue {
        background-color: #002060;
        border: 2px solid #031741;
        color: #FFFFFF;
    }

    .btn-Blue-inactive {
        background-color: transparent;
        border: 2px solid #002060;
        text-decoration: line-through;
    }

    .btn-Red, .Red {
        background-color: #FF0000;
        border: 2px solid #a20303;
    }

    .btn-Red-inactive {
        background-color: transparent;
        border: 2px solid #FF0000;
        text-decoration: line-through;
    }

    .btn-No-ring, .No-ring {
        background-color: #D9D9D9;
        border: 2px solid #b7b6b6;
    }

    .btn-No-ring-inactive {
        background-color: transparent;
        border: 2px solid #D9D9D9;
        text-decoration: line-through;
    }

    .vertical-align {
        vertical-align: middle !important;
    }

    p {
        margin-bottom: 0 !important;
    }

    .cursor-pointer {
        cursor: pointer;
    }

    .color-option {
        height: 20px;
        width: 20px;
        border-radius: 6px;
    }

    /*::-webkit-scrollbar {*/
    /*    display: none;*/
    /*}*/

    .set-container ::-webkit-scrollbar:vertical {
        width: 3px;
    }

    .set-container ::-webkit-scrollbar {
        -webkit-appearance: none;
    }

    .day-container {
        /* Common styles for both selected and non-selected days */
    }

    /* Styles for the selected day */
    .selected-day {
        background-color: var(--color-primary);
        color: #fff;
    }

    /* Styles for non-selected days */
    .non-selected-day {
        /* Define any specific styles for non-selected days here, if needed */
    }
</style>
