<template>
    <b-row v-if="commentType === 'Emasculation' || commentType === 'Emasculation pollination'">
        <b-col md="12">
            <template v-if="hasPermission">
                <ValidationObserver v-slot="{ passes }" :ref="'emasculationCommentForm' + pollinationId">
                    <b-form @submit.prevent="passes(sendComment)" :id="'new-emasculation-comment-form-' + pollinationId">
                        <ValidationProvider :rules="editEmasculationComments ? '' : 'required'" v-slot="{ errors }">
                            <b-form-group :invalid-feedback="errors[0]">
                                <b-form-textarea :placeholder="commentType + ' comments...'" rows="2" no-resize v-model="emasculationComment"
                                     :state="errors[0] ? false : null">
                                </b-form-textarea>
                            </b-form-group>
                        </ValidationProvider>
                    </b-form>
                </ValidationObserver>
                <div class="d-flex justify-content-end mt-3">
                    <b-button type="submit" :form="'new-emasculation-comment-form-' + pollinationId" variant="outline-primary" @click="setData('create')">
                        <font-awesome-icon icon="save"></font-awesome-icon> Save
                    </b-button>
                </div>
            </template>
            <b-card class="mt-3" v-if="emasculationComments.length > 0">
                <ValidationObserver v-slot="{ passes }" ref="emasculationEditCommentForm">
                    <b-form @submit.prevent="passes(sendComment)" id="emasculation-comment-form">
                        <div v-for="comment in emasculationComments" :key="comment.id" class="mb-3">
                            <strong class="font-name">{{ comment.userName}} </strong> • <span class="label-created">{{ comment.createdSince }}</span>
                            <br>
                            <small class="text-muted">{{ comment.created }}</small>
                            <template v-if="!comment.isEditing">
                                <p class="comment">{{ comment.comment}}</p>
                            </template>
                            <template v-else>
                                <ValidationProvider rules="required" v-slot="{ errors }">
                                    <b-form-group :invalid-feedback="errors[0]">
                                        <b-form-textarea :placeholder="commentType + ' comments...'" rows="2" no-resize v-model="comment.comment"
                                             :state="errors[0] ? false : null">
                                        </b-form-textarea>
                                    </b-form-group>
                                </ValidationProvider>
                            </template>
                            <div class="d-flex justify-content-end">
                                <b-button type="button" variant="transparent" class="btn-xs btn-link" @click="editComment('EDIT', comment)" v-if="!comment.isEditing && hasPermission && userId == comment.userId">
                                    <font-awesome-icon icon="edit"></font-awesome-icon> Edit
                                </b-button>
                                <b-button type="button" variant="transparent" class="btn-xs btn-link" @click="deleteComment(comment)" v-if="!comment.isEditing && hasPermission && userId == comment.userId">
                                    <font-awesome-icon icon="trash-alt"></font-awesome-icon> Delete
                                </b-button>
                                <b-button type="button" variant="transparent" class="btn-xs btn-link" @click="editComment('CANCEL', comment)" v-if="comment.isEditing">
                                    <font-awesome-icon icon="ban"></font-awesome-icon> Cancel
                                </b-button>
                                <b-button type="submit" form="emasculation-comment-form" variant="transparent" class="btn-xs btn-link" @click="setData('edit', comment)" v-show="comment.isEditing && hasPermission && userId == comment.userId">
                                    <font-awesome-icon icon="save"></font-awesome-icon> Save
                                </b-button>
                            </div>
                        </div>
                    </b-form>
                </ValidationObserver>
            </b-card>
            <b-card v-else class="mt-3">
                <p class="text-center"><small>No registered comments</small></p>
            </b-card>
        </b-col>
    </b-row>
    <b-row v-else-if="commentType === 'Pollination'">
        <b-col md="12">
            <template v-if="hasPermission">
                <ValidationObserver v-slot="{ passes }" :ref="'pollinationCommentForm' + pollinationId">
                    <b-form @submit.prevent="passes(sendComment)" :id="'new-pollination-comment-form-' + pollinationId">
                        <ValidationProvider :rules="editPollinationComments ? '' : 'required'" v-slot="{ errors }">
                            <b-form-group :invalid-feedback="errors[0]">
                                <b-form-textarea :placeholder="commentType + ' comments...'" rows="2" no-resize v-model="pollinationComment"
                                     :state="errors[0] ? false : null">
                                </b-form-textarea>
                            </b-form-group>
                        </ValidationProvider>
                    </b-form>
                </ValidationObserver>
                <div class="d-flex justify-content-end mt-3">
                    <b-button type="submit" :form="'new-pollination-comment-form-' + pollinationId" variant="outline-primary" @click="setData('create')">
                        <font-awesome-icon icon="save"></font-awesome-icon> Save
                    </b-button>
                </div>
            </template>
            <b-card class="mt-3" v-if="pollinationComments.length > 0">
                <ValidationObserver v-slot="{ passes }" :ref="'pollinationEditCommentForm' + pollinationId">
                    <b-form @submit.prevent="passes(sendComment)" :id="'pollination-comment-form-' + pollinationId">
                        <div v-for="comment in pollinationComments" :key="comment.id" class="mb-3">
                            <strong class="font-name">{{ comment.userName}} </strong> • <span class="label-created">{{ comment.createdSince }}</span>
                            <br>
                            <small class="text-muted">{{ comment.created }}</small>
                            <template v-if="!comment.isEditing">
                                <p class="comment">{{ comment.comment}}</p>
                            </template>
                            <template v-else>
                                <ValidationProvider rules="required" v-slot="{ errors }">
                                    <b-form-group :invalid-feedback="errors[0]">
                                        <b-form-textarea :placeholder="commentType + ' comments...'" rows="2" no-resize v-model="comment.comment"
                                             :state="errors[0] ? false : null">
                                        </b-form-textarea>
                                    </b-form-group>
                                </ValidationProvider>
                            </template>
                            <div class="d-flex justify-content-end">
                                <b-button type="button" variant="transparent" class="btn-xs btn-link" @click="editPollinationComment('EDIT', comment)" v-if="!comment.isEditing && hasPermission && userId == comment.userId">
                                    <font-awesome-icon icon="edit"></font-awesome-icon> Edit
                                </b-button>
                                <b-button type="button" variant="transparent" class="btn-xs btn-link" @click="deleteComment(comment)" v-if="!comment.isEditing && hasPermission && userId == comment.userId">
                                    <font-awesome-icon icon="trash-alt"></font-awesome-icon> Delete
                                </b-button>
                                <b-button type="button" variant="transparent" class="btn-xs btn-link" @click="editPollinationComment('CANCEL', comment)" v-if="comment.isEditing">
                                    <font-awesome-icon icon="ban"></font-awesome-icon> Cancel
                                </b-button>
                                <b-button type="submit" :form="'pollination-comment-form-' + pollinationId" variant="transparent" class="btn-xs btn-link" @click="setData('edit', comment)" v-show="comment.isEditing && hasPermission && userId == comment.userId">
                                    <font-awesome-icon icon="save"></font-awesome-icon> Save
                                </b-button>
                            </div>
                        </div>
                    </b-form>
                </ValidationObserver>
            </b-card>
            <b-card v-else class="mt-3">
                <p class="text-center"><small>No registered comments</small></p>
            </b-card>
        </b-col>
    </b-row>
</template>

<script>
import dayjs from 'dayjs';
import {
    addPollinationCommentUrl,
    deleteEmasculationPollinationCommentsUrl,
    getEmasculationPollinationCommentsUrl
} from '@routes';
import $permission from '@/js/permission';

export default {
    name: 'EmasculationPollinationCommentComponent',
    props: {
        pollinationId: {
            type: Number,
            required: true
        },
        commentType: {
            type: String,
            required: true
        }
    },
    data() {
        return {
            emasculationComment: null,
            pollinationComment: null,
            pollinationComments: [],
            emasculationComments: [],
            editEmasculationComments: false,
            editPollinationComments: false,
            commentData: null,
            commentEmasculationId: null,
            commentPollinationId: null,
            commentId: null,
            emasculationCommentsCache: [],
            pollinationCommentsCache: [],
            toastTitle: 'Production line',
            calendar: this.$parent,
            hasPermission: $permission.check('ROLE_PRLI_EMPO_C'),
            userId: localStorage.getItem('userId')
        };
    },
    created() {
        this.loadComments();
    },
    methods: {
        formatDate(date) {
            let dateFormat;

            if (date) {
                dateFormat = dayjs(date.toString(), 'DD/MM/YYYY');
            }

            return date ? dateFormat.format('DD/MM/YYYY') : '';
        },
        sendComment() {
            let action, comment, index, indexCache;

            this.axios.post(addPollinationCommentUrl(), {
                id: this.commentId,
                pollinationId: this.pollinationId,
                comment: this.commentData,
                commentType: this.commentType
            }).then(response => {
                if (response.status === 200) {
                    this.$bvToast.toast(response.data.message, {
                        title: this.toastTitle,
                        variant: 'success'
                    });

                    action= response.data.action;
                    comment = response.data.comment;

                    if (action === 'created') {
                        this.$nextTick(() => {
                            this.loadComments();
                        });

                        this.emasculationCommentsCache = [];

                        if (this.commentType === 'Emasculation' || this.commentType === 'Emasculation pollination') {
                            this.emasculationComment = null;
                            this.commentEmasculationId = null;
                            this.commentId = null;

                            let value = 'emasculationCommentForm' + this.pollinationId;

                            if (this.$refs[value]) {
                                requestAnimationFrame(() => {
                                    this.$refs[value].reset();
                                });
                            }

                            if (this.commentType === 'Emasculation') {
                                this.calendar.emasculationPollinationData.emasculationComments++;
                            }
                        } else {
                            this.pollinationComment = null;
                            this.commentPollinationId = null;
                            this.commentId = null;

                            let value = 'pollinationCommentForm' + this.pollinationId;

                            if (this.$refs[value]) {
                                requestAnimationFrame(() => {
                                    this.$refs[value].reset();
                                });
                            }

                            this.calendar.emasculationPollinationData.pollinationComments++;
                        }
                    } else {
                        if (comment.commentType === 'Emasculation' || this.commentType === 'Emasculation pollination') {
                            index = this.emasculationComments.findIndex(item => item.id === comment.id);
                            indexCache = this.emasculationCommentsCache.findIndex(item => item.id === comment.id);

                            if (index !== -1) {
                                this.emasculationComments[index].id = comment.id;
                                this.emasculationComments[index].comment = comment.comment;
                                this.emasculationComments[index].isEditing = comment.isEditing;
                                this.emasculationComments[index].created = comment.created;
                                this.emasculationComments[index].createdSince = comment.createdSince;
                            }

                            if (indexCache !== -1) {
                                this.emasculationCommentsCache.splice(indexCache, 1);
                            }
                        } else {
                            index = this.pollinationComments.findIndex(item => item.id === comment.id);
                            indexCache = this.pollinationCommentsCache.findIndex(item => item.id === comment.id);

                            if (index !== -1) {
                                this.pollinationComments[index].id = comment.id;
                                this.pollinationComments[index].comment = comment.comment;
                                this.pollinationComments[index].isEditing = comment.isEditing;
                                this.pollinationComments[index].created = comment.created;
                                this.pollinationComments[index].createdSince = comment.createdSince;
                            }

                            if (indexCache !== -1) {
                                this.pollinationCommentsCache.splice(indexCache, 1);
                            }
                        }
                    }

                    // this.$nextTick(() => {
                    //     this.$emit('load-data');
                    // });
                } else {
                    this.$bvToast.toast(response.data.message, {
                        title: this.toastTitle,
                        variant: 'danger'
                    });
                }
            }).catch(error => {
                if (error.response) {
                    this.$bvToast.toast(error.response.data.message, {
                        title: this.toastTitle,
                        variant: 'danger'
                    });
                } else if (error.request) {
                    this.$bvToast.toast('An error occurred while executing the request', {
                        title: this.toastTitle,
                        variant: 'danger'
                    });
                } else {
                    this.$bvToast.toast('An error occurred while configuring the request', {
                        title: this.toastTitle,
                        variant: 'danger'
                    });
                }
            }).finally(() => {
                this.isLoading = false;
            });
        },
        loadComments() {
            this.axios.post(getEmasculationPollinationCommentsUrl(), {
                pollinationId: this.pollinationId,
                commentType: this.commentType
            }).then(response => {
                if (response.status === 200) {
                    this.emasculationComments = response.data.emasculationComments;
                    this.pollinationComments = response.data.pollinationComments;
                }
            }).catch(error => {
                if (error.response) {
                    this.$bvToast.toast(error.response.data.message, {
                        title: this.toastTitle,
                        variant: 'danger'
                    });
                } else if (error.request) {
                    this.$bvToast.toast('An error occurred while executing the request', {
                        title: this.toastTitle,
                        variant: 'danger'
                    });
                } else {
                    this.$bvToast.toast('An error occurred while configuring the request', {
                        title: this.toastTitle,
                        variant: 'danger'
                    });
                }
            });
        },
        editComment(action, item) {
            if (action === 'EDIT') {
                this.emasculationCommentsCache.push(JSON.parse(JSON.stringify(item)));
                item.isEditing = true;
            } else if (action === 'CANCEL') {
                let idx = this.emasculationCommentsCache.findIndex(comment => comment.id === item.id);
                let commentIdx = this.emasculationComments.findIndex(comment => comment.id === item.id);

                if (idx !== -1 && commentIdx !== -1) {
                    this.emasculationComments[commentIdx].comment = this.emasculationCommentsCache[idx].comment;
                    this.emasculationComments[commentIdx].isEditing = false;
                    this.emasculationCommentsCache.splice(idx, 1);
                }

                item.isEditing = false;
            }
        },
        editPollinationComment(action, item) {
            if (action === 'EDIT') {
                this.pollinationCommentsCache.push(JSON.parse(JSON.stringify(item)));
                item.isEditing = true;
            } else if (action === 'CANCEL') {
                let idx = this.pollinationCommentsCache.findIndex(comment => comment.id === item.id);
                let commentIdx = this.pollinationComments.findIndex(comment => comment.id === item.id);

                if (idx !== -1 && commentIdx !== -1) {
                    this.pollinationComments[commentIdx].comment = this.pollinationCommentsCache[idx].comment;
                    this.pollinationComments[commentIdx].isEditing = false;
                    this.pollinationCommentsCache.splice(idx, 1);
                }

                item.isEditing = false;
            }
        },
        setData(action, item = null) {
            if (action === 'create') {
                if (this.commentType === 'Emasculation') {
                    this.editEmasculationComments = false;
                } else if (this.commentType === 'Pollination') {
                    this.editPollinationComments = false;
                }

                this.commentData = this.commentType === 'Emasculation' || this.commentType === 'Emasculation pollination' ? this.emasculationComment : this.pollinationComment;
                this.commentId = null;
            } else if (action === 'edit') {
                if (this.commentType === 'Emasculation') {
                    this.editEmasculationComments = true;
                } else if (this.commentType === 'Pollination') {
                    this.editPollinationComments = true;
                }

                this.commentData = item.comment;
                this.commentId = item.id;
            }
        },
        deleteComment(comment) {
            this.$swal({
                title: 'Delete comment',
                icon: 'warning',
                iconColor: '#bf0d0d',
                text: 'Are you sure you want to delete this comment?',
                cancelButtonText: 'Cancel',
                confirmButtonColor: '#bf0d0d',
                confirmButtonText: 'Yes'
            }).then(result => {
                if (result.value) {
                    this.axios.post(deleteEmasculationPollinationCommentsUrl(), {
                        id: comment.id
                    }).then(response => {
                        if (response.status === 200) {
                            this.$bvToast.toast(response.data.message, {
                                title: this.toastTitle,
                                variant: 'success'
                            });

                            this.$nextTick(() => {
                                this.loadComments();
                            });

                            if (comment.commentType === 'Emasculation') {
                                this.calendar.emasculationPollinationData.emasculationComments--;
                            } else if (comment.commentType === 'Pollination') {
                                this.calendar.emasculationPollinationData.pollinationComments--;
                            }
                        } else {
                            this.$bvToast.toast(response.data.message, {
                                title: this.toastTitle,
                                variant: 'danger'
                            });
                        }
                    }).catch(error => {
                        if (error.response) {
                            this.$bvToast.toast(error.response.data.message, {
                                title: this.toastTitle,
                                variant: 'danger'
                            });
                        } else if (error.request) {
                            this.$bvToast.toast('An error occurred while executing the request', {
                                title: this.toastTitle,
                                variant: 'danger'
                            });
                        } else {
                            this.$bvToast.toast('An error occurred while configuring the request', {
                                title: this.toastTitle,
                                variant: 'danger'
                            });
                        }
                    }).finally(() => {
                        this.isLoading = false;
                    });
                }
            });
        }
    }
};
</script>

<style scoped>
    .form-group label, .form-group {
        margin-top: 0
    }

    .text-muted {
        color: #999c9e !important;
        font-size: 10px;
    }

    .comment {
        padding: 6px;
        border: 1px solid #ccc;
        font-size: 11px;
    }

    .font-name {
        font-size: 11px;
        color: var(--color-secondary);
    }

    .label-created{
        font-size: 10px;
        color: var(--color-secondary);
    }
</style>