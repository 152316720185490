<template>
    <b-overlay :show="isLoading">
        <b-card style="background-color: #fff;" id="container-production-line" ref="containerProductionLine">
            <template #header>
                <div class="text-right mb-2 mt-2">
                    <b-button variant="outline-secondary" :to="{ name: 'ProductionLineIndex' }" class="mr-2"><font-awesome-icon icon="angle-left"></font-awesome-icon> Back</b-button>
                    <b-button variant="primary" :to="{ name: 'ProductionLineEdit', params: { id: productionLine.id, variety: productionLine.varietyName }}" v-if="productionLine.id && hasCreatePermission">
                        <font-awesome-icon icon="edit"></font-awesome-icon> Edit
                    </b-button>
                </div>
            </template>
            <b-row class="justify-content-center mb-5">
                <div class="timeline">
                    <div v-for="(stage, index) in stages" :key="stage.number" class="stage">
                        <div class="line" v-if="index !== 0"></div>
                        <div class="circle" @click="scrollView(stage.number)" v-if="!stage.ready" :style="showCursorStage(stage.number)">{{ stage.number }}</div>
                        <div class="circle circle--complete" @click="scrollView(stage.number)" v-else><font-awesome-icon icon="check"></font-awesome-icon></div>
                        <div class="name" v-if="!stage.ready">{{ stage.name }}</div>
                        <div class="name name--complete" v-else>{{ stage.name }}</div>
                    </div>
                </div>
            </b-row>
            <b-row class="mb-3">
                <div class="col-4">
                    <b-table-simple responsive bordered striped>
                        <b-tbody>
                            <b-tr>
                                <b-th style="width: 50%">Created</b-th>
                                <b-td>{{ productionLine.createdBy }} {{ productionLine.createDate }}</b-td>
                            </b-tr>
                            <b-tr>
                                <b-th style="width: 50%">Year - Season - Review</b-th>
                                <b-td>{{ productionLine.orderYear }}-{{ productionLine.season}}-{{ productionLine.sequence}}</b-td>
                            </b-tr>
                            <b-tr>
                                <template v-if="productionLine.children?.length > 0">
                                    <b-th style="width: 50%">Order Kg (Original Order Kg) {{ productionLine.orderId ? ' - Order' : ''}}</b-th>
                                    <b-td>{{ productionLine.orderKg }} Kg. ({{ productionLine.originalOrderKg }} Kg.) {{ productionLine.orderId ? ' - ' + productionLine.orderId : '' }}</b-td>
                                </template>
                                <template v-else>
                                    <b-th style="width: 50%">Order Kg - Order</b-th>
                                    <b-td>{{ productionLine.orderKg }} Kg. {{ productionLine.orderId ? ' - ' + productionLine.orderId : '' }}</b-td>
                                </template>
                            </b-tr>
                            <b-tr>
                                <b-th style="width: 50%">Order date</b-th>
                                <b-td>{{ productionLine.orderDate }}</b-td>
                            </b-tr>
                        </b-tbody>
                    </b-table-simple>
                </div>
                <b-col class="col-4">
                    <b-table-simple responsive bordered striped>
                        <b-tbody>
                            <b-tr>
                                <b-th style="width: 40%">Crop</b-th>
                                <b-td>{{ productionLine.crop }}</b-td>
                            </b-tr>

                            <b-tr>
                                <b-th style="width: 30%">Type</b-th>
                                <b-td>{{ productionLine.type }}</b-td>
                            </b-tr>
                            <b-tr>
                                <b-th style="width: 30%">BIO/GSPP</b-th>
                                <b-td>{{ productionLine.bioGspp }}</b-td>
                            </b-tr>
                            <b-tr>
                                <b-th style="width: 30%">MS - MS %</b-th>
                                <b-td>{{ productionLine.msVariety }} {{ productionLine.productionLineMs ? ' - ' + productionLine.productionLineMs : '' }}</b-td>
                            </b-tr>
                        </b-tbody>
                    </b-table-simple>
                </b-col>
                <b-col class="col-4 d-flex flex-column align-items-end">
                    <div style="width: 85px; height: 85px; position: absolute; top: -136px; right: 0;" v-b-tooltip.hover.left :title="productionLine.figureName" v-if="productionLine.figureGraphic">
                        <b-img class="img-fluid mb-1 mt-3" style=" min-width: 70px; max-width: 85px; max-height: 85px; object-fit: cover;" :src="urlImg + productionLine.fileId"
                               :alt="productionLine.varietyName" ref="varietyImage"></b-img>
                    </div>
                    <h1 class="title">Production line</h1>
                    <h2 class="variety-title">{{ productionLine.varietyName }}{{ productionLine.tag ? '-' + productionLine.tag : ''}} ({{ productionLine.orderKg }} Kg.)</h2>
                    <p class="subtitle">{{ productionLine.orderYear }}-{{ productionLine.season }}-{{ productionLine.sequence }}</p>
                    <h2 class="variety-title mt-1 mb-1" v-if="productionLine.compartmentName" v-b-tooltip.hover.left title="Compartment">
                        <router-link v-if="productionLine.compartmentName && hasCompartmentPermission" :to="{ name: 'CompartmentsShow', params: { id: productionLine.compartmentId }}" target="_blank">
                            {{ productionLine.compartmentName }}
                        </router-link>
                        <span v-else-if="productionLine.compartmentName && !hasCompartmentPermission">{{ productionLine.compartmentName }}</span>
                    </h2>
                    <div class="d-flex">
                        <p class="process-stage-label cancelled" v-if="productionLine.processStatusId === 4">CANCELLED</p>
                        <p class="process-stage-label" v-if="productionLine.processStageId">{{ productionLine.processStageTitle?.toUpperCase() }}</p>
                    </div>
                    <div class="mt-2">
                        <b-button variant="outline-danger" v-if="!productionLine.productionLineOccupancy && productionLine.processStatusId === 1 && hasCreatePermission" @click="cancelProductionLine" class="mr-2">Cancel</b-button>
                        <b-button variant="outline-warning" v-if="productionLineReadyToRelease && hasCreatePermission" @click="releaseCompartment">Release compartment</b-button>
                    </div>
                </b-col>
            </b-row>

            <b-row>
                <b-col class="col-12">
                    <b-table-simple responsive bordered striped class="mb-0">
                        <b-thead>
                            <b-tr>
                                <b-th colspan="4">Parents</b-th>
                            </b-tr>
                        </b-thead>
                        <b-tbody>
                            <b-tr>
                                <b-td style="width: 25%"><strong>Female parent</strong></b-td>
                                <b-td>{{ productionLine.parentalFemaleName }}</b-td>
                                <b-td style="width: 25%"><strong>Male parent</strong></b-td>
                                <b-td>{{ productionLine.parentalMaleName }}</b-td>
                            </b-tr>
                            <b-tr style="background-color: #fff;">
                                <b-td style="width: 25%"><strong>PLD (female)</strong></b-td>
                                <b-td>{{ productionLine.femalePld }}</b-td>
                                <b-td style="width: 25%"><strong>PLD (male)</strong></b-td>
                                <b-td>{{ productionLine.malePld }}</b-td>
                            </b-tr>
                            <b-tr>
                                <b-td><strong>Remarks stockseed female</strong></b-td>
                                <b-td>{{ productionLine.remarkStockseedFemale }}</b-td>
                                <b-td><strong>Remarks stockseed male</strong></b-td>
                                <b-td>{{ productionLine.remarkStockseedMale }}</b-td>
                            </b-tr>
                        </b-tbody>
                    </b-table-simple>
                </b-col>
            </b-row>

            <b-row class="mt-3">
                <div class="col-12">
                    <b-table-simple responsive bordered class="mb-0">
                        <b-thead>
                            <b-tr>
                                <b-th colspan="6">Details</b-th>
                            </b-tr>
                        </b-thead>
                        <b-tbody>
                            <b-tr>
                                <b-td style="width: 16.6%;"><strong>Change date</strong></b-td>
                                <b-td style="width: 16.6%">{{ productionLine.changeDate }}</b-td>
                                <b-td style="width: 16.6%"><strong>Location</strong></b-td>
                                <b-td style="width: 16.6%">{{ productionLine.location }}</b-td>
                                <b-td style="width: 16.6%"><strong>Females needed</strong></b-td>
                                <b-td style="width: 16.6%">{{ productionLine.femalePlantsMx }}</b-td>
                            </b-tr>
                            <b-tr>
                                <b-td style="width: 16.6%"><strong>Yield NL</strong></b-td>
                                <b-td style="width: 16.6%">{{ productionLine.yieldNl }}</b-td>
                                <b-td style="width: 16.6%"><strong>Priority</strong></b-td>
                                <b-td style="width: 16.6%">{{ productionLine.priority }}</b-td>
                                <b-td style="width: 16.6%"><strong>Males needed</strong></b-td>
                                <b-td style="width: 16.6%">{{ productionLine.malesNeeded }}</b-td>
                            </b-tr>
                            <b-tr>
                                <b-td style="width: 16.6%"><strong>Yield MX</strong></b-td>
                                <b-td style="width: 16.6%">{{ productionLine.yieldMx }}</b-td>
                                <b-td style="width: 16.6%"><strong>Delivery seeds date</strong></b-td>
                                <b-td style="width: 16.6%">{{ productionLine.deliverDate }}</b-td>
                                <b-td style="width: 16.6%"><strong>Total plants</strong></b-td>
                                <b-td style="width: 16.6%">{{ productionLine.totalPlants }}</b-td>
                            </b-tr>
                            <b-tr>
                                <b-td style="width: 16.6%"><strong>Kg/ha, g/plant</strong></b-td>
                                <b-td style="width: 16.6%">{{ productionLine.yieldUnits }}</b-td>
                                <b-td style="width: 16.6%"><strong>Ultimate seeds date</strong></b-td>
                                <b-td style="width: 16.6%">{{ productionLine.ultimateShipmentDate }}</b-td>
                                <b-td style="width: 16.6%"><strong>Plants per line</strong></b-td>
                                <b-td style="width: 16.6%">{{ productionLine.plantsPerLine }}</b-td>
                            </b-tr>
                            <b-tr>
                                <b-td style="width: 16.6%"><strong>Female nr. of plants net</strong></b-td>
                                <b-td style="width: 16.6%">{{ productionLine.femalePlantsNl }}</b-td>
                                <b-td style="width: 16.6%"><strong>Yield calculated</strong></b-td>
                                <b-td style="width: 16.6%">{{ productionLine.yieldCalculated }}</b-td>
                                <b-td style="width: 16.6%"><strong>Rows required</strong></b-td>
                                <b-td style="width: 16.6%">{{ productionLine.rowsRequired }}</b-td>
                            </b-tr>
                            <b-tr>
                                <b-td style="width: 16.6%"><strong>Prod. area</strong></b-td>
                                <b-td style="width: 16.6%">{{ productionLine.productionArea }}</b-td>
                                <b-td style="width: 16.6%"><strong>Ratio</strong></b-td>
                                <b-td style="width: 16.6%">{{ productionLine.malePerFemaleRatio }}</b-td>
                                <b-td style="width: 16.6%"><strong>Surface required (m²)</strong></b-td>
                                <b-td style="width: 16.6%">{{ productionLine.surfaceRequired }}</b-td>
                            </b-tr>
                            <b-tr>
                                <b-td style="width: 16.6%"><strong>Growth tech</strong></b-td>
                                <b-td style="width: 16.6%">{{ productionLine.growthTech }}</b-td>
                                <b-td style="width: 16.6%"><strong>Density</strong></b-td>
                                <b-td style="width: 16.6%">{{ productionLine.density }}</b-td>
                                <b-td style="width: 16.6%"></b-td>
                                <b-td style="width: 16.6%"></b-td>
                            </b-tr>
                        </b-tbody>
                    </b-table-simple>
                </div>
            </b-row>

            <b-row class="mt-3">
                <b-col class="col-12">
                    <b-table-simple responsive bordered striped class="mb-0">
                        <b-thead>
                            <b-tr>
                                <b-th>Remarks</b-th>
                            </b-tr>
                        </b-thead>
                        <b-tbody>
                            <b-tr>
                                <b-td v-if="productionLine.remarks">{{ productionLine.remarks }}</b-td>
                                <b-td v-else style="color: transparent">.</b-td>
                            </b-tr>
                        </b-tbody>
                    </b-table-simple>
                </b-col>
            </b-row>

            <template #footer>
                <div class="text-right mb-2 mt-2">
                    <b-button variant="outline-secondary" :to="{ name: 'ProductionLineIndex' }" class="mr-2"><font-awesome-icon icon="angle-left"></font-awesome-icon> Back</b-button>
                    <b-button variant="primary" :to="{ name: 'ProductionLineEdit', params: { id: productionLine.id, variety:productionLine.varietyName }}" v-if="productionLine.id && hasCreatePermission">
                        <font-awesome-icon icon="edit"></font-awesome-icon> Edit
                    </b-button>
                </div>
            </template>

            <b-card class="mt-5" ref="planningCard" v-if="hasPlanningListPermission">
                <div class="d-flex justify-content-between mb-2">
                    <h3 class="title">Planning - {{ productionLine.varietyName }}{{ productionLine.tag ? '-' + productionLine.tag : ''}}
                        <h6 class="label label-primary ml-1" v-if="stages[0].ready" style="font-size: 12px">
                            Completed
                        </h6>
                    </h3>
                    <div>
                        <b-button size="sm" v-b-tooltip.hover title="Complete process" variant="outline-primary"
                                  v-if="planningReadyToComplete" @click="completeProcess('Planning', 1)" >
                            <font-awesome-icon icon="check"></font-awesome-icon>
                        </b-button>
                        <b-button size="sm" v-b-tooltip.hover title="Cancel" variant="outline-primary" v-if="editPlanning" @click="editStage(1, 'CANCEL')">
                            <font-awesome-icon icon="ban"></font-awesome-icon>
                        </b-button>
                        <b-button size="sm" @click="editStage(1, 'EDIT')"
                                  v-b-tooltip.hover v-if="!editPlanning && hasPlanningCreatePermission && productionLine.processStatusId !== 4"
                                  title="Edit" variant="outline-primary">
                            <font-awesome-icon icon="edit"></font-awesome-icon>
                        </b-button>
                        <b-button type="submit" form="planning-form" size="sm" v-b-tooltip.hover title="Save" v-if="editPlanning && hasPlanningCreatePermission"
                                  variant="outline-primary">
                            <font-awesome-icon icon="save"></font-awesome-icon>
                        </b-button>
                    </div>
                </div>

                <div>
                    <b-collapse :visible="editPlanning">
                        <b-card style="width: 100%">
                            <ValidationObserver v-slot="{ passes }" ref="planningForm">
                                <b-form @submit.prevent="passes(savePlanning)" id="planning-form">
<!--                                <b-form @submit.prevent="validateBeforeSave(passes)" id="planning-form">-->
<!--                                <b-form @submit.prevent="passes(validateBeforeSave)" id="planning-form">-->
                                    <h5 class="d-flex align-items-center"><font-awesome-icon icon="mars" class="parent-icon"></font-awesome-icon>&nbsp;{{ productionLine.parentalMaleName }}</h5>
                                    <b-row style="margin-bottom: 30px">
										<b-col md="3" sm="12">
											<ValidationProvider rules="required" v-slot="{ errors }">
												<b-form-group label="Batch">
													<Batches v-model="planning.male.batch" style="width: 100%;" :state="errors[0] ? false : null"></Batches>
												</b-form-group>
											</ValidationProvider>
                                        </b-col>
                                        <b-col md="3" sm="12">
											<ValidationProvider rules="" v-slot="{ errors }">
												<b-form-group label="Seed shipped date" :invalid-feedback="errors[0]">
													<custom-datepicker :container="'#container-production-line'" v-model="planning.male.shippedDate" :state="errors[0] ? false : null" :color="'planned-date'" />
												</b-form-group>
											</ValidationProvider>
                                        </b-col>
                                        <b-col md="3" sm="12">
											<ValidationProvider rules="" v-slot="{ errors }">
												<b-form-group label="Received in PROMEX" :invalid-feedback="errors[0]">
													<custom-datepicker :container="'#container-production-line'" v-model="planning.male.receivedDate" :state="errors[0] ? false : null" :color="'planned-date'" />
												</b-form-group>
											</ValidationProvider>
                                        </b-col>
                                        <b-col md="3" sm="12">
											<ValidationProvider rules="" v-slot="{ errors }">
												<b-form-group label="Released date" :invalid-feedback="errors[0]">
													<custom-datepicker :container="'#container-production-line'" v-model="planning.male.releasedDate" :state="errors[0] ? false : null" :color="'planned-date'" />
												</b-form-group>
											</ValidationProvider>
                                        </b-col>
                                        <b-col md="3" sm="12">
											<ValidationProvider rules="required" v-slot="{ errors }">
												<b-form-group label="Start sowing date" :invalid-feedback="errors[0]">
													<custom-datepicker :container="'#container-production-line'" v-model="planning.male.startSowingDate" :state="errors[0] ? false : null" :color="'planned-date'" />
												</b-form-group>
											</ValidationProvider>
                                        </b-col>
                                        <b-col md="3" sm="12">
											<ValidationProvider rules="required" v-slot="{ errors }">
												<b-form-group label="Germination date" :invalid-feedback="errors[0]">
													<custom-datepicker :container="'#container-production-line'" v-model="planning.male.germinationDate" :state="errors[0] ? false : null" :color="'planned-date'" />
												</b-form-group>
											</ValidationProvider>
                                        </b-col>
                                        <b-col md="3" sm="12">
											<ValidationProvider rules="required" v-slot="{ errors }">
												<b-form-group label="Transplant to tidal floor date" :invalid-feedback="errors[0]">
													<custom-datepicker :container="'#container-production-line'" v-model="planning.male.transplantTidalFloorDate" :state="errors[0] ? false : null" :color="'planned-date'" />
												</b-form-group>
											</ValidationProvider>
                                        </b-col>
                                        <b-col md="3" sm="12">
											<ValidationProvider rules="required" v-slot="{ errors }">
												<b-form-group label="Compartment transplant date" :invalid-feedback="errors[0]">
													<custom-datepicker :container="'#container-production-line'" v-model="planning.male.transplantingDate" :state="errors[0] ? false : null" :color="'planned-date'" />
												</b-form-group>
											</ValidationProvider>
                                        </b-col>
                                    </b-row>
                                    <hr>
                                    <h5 class="d-flex align-items-center"><font-awesome-icon icon="venus" class="parent-icon parent-icon--female"></font-awesome-icon>&nbsp;{{ productionLine.parentalFemaleName }}</h5>
                                    <b-row>
										<b-col md="3" sm="12">
											<ValidationProvider rules="required" v-slot="{ errors }">
												<b-form-group label="Batch">
													<Batches v-model="planning.female.batch" style="width: 100%;" :state="errors[0] ? false : null"></Batches>
												</b-form-group>
											</ValidationProvider>
										</b-col>
                                        <b-col md="3" sm="12">
											<ValidationProvider rules="" v-slot="{ errors }">
												<b-form-group label="Seed shipped date" :invalid-feedback="errors[0]">
													<custom-datepicker :container="'#container-production-line'" v-model="planning.female.shippedDate" :state="errors[0] ? false : null" :color="'planned-date'" />
												</b-form-group>
											</ValidationProvider>
                                        </b-col>
                                        <b-col md="3" sm="12">
											<ValidationProvider rules="" v-slot="{ errors }">
												<b-form-group label="Received in PROMEX" :invalid-feedback="errors[0]">
													<custom-datepicker :container="'#container-production-line'" v-model="planning.female.receivedDate" :state="errors[0] ? false : null" :color="'planned-date'" />
												</b-form-group>
											</ValidationProvider>
                                        </b-col>
                                        <b-col md="3" sm="12">
											<ValidationProvider rules="" v-slot="{ errors }">
												<b-form-group label="Released date" :invalid-feedback="errors[0]">
													<custom-datepicker :container="'#container-production-line'" v-model="planning.female.releasedDate" :state="errors[0] ? false : null" :color="'planned-date'" />
												</b-form-group>
											</ValidationProvider>
                                        </b-col>
                                        <b-col md="3" sm="12">
                                            <ValidationProvider rules="required" v-slot="{ errors }">
												<b-form-group label="Start sowing date" :invalid-feedback="errors[0]">
													<custom-datepicker :container="'#container-production-line'" v-model="planning.female.startSowingDate" :state="errors[0] ? false : null" :color="'planned-date'" />
												</b-form-group>
                                            </ValidationProvider>
                                        </b-col>
                                        <b-col md="3" sm="12">
                                            <ValidationProvider rules="required" v-slot="{ errors }">
												<b-form-group label="Germination date" :invalid-feedback="errors[0]">
													<custom-datepicker :container="'#container-production-line'" v-model="planning.female.germinationDate" :state="errors[0] ? false : null" :color="'planned-date'" />
												</b-form-group>
                                            </ValidationProvider>
                                        </b-col>
                                        <b-col md="3" sm="12">
                                            <ValidationProvider rules="required" v-slot="{ errors }">
												<b-form-group label="Transplant to tidal floor date" :invalid-feedback="errors[0]">
													<custom-datepicker :container="'#container-production-line'" v-model="planning.female.transplantTidalFloorDate" :state="errors[0] ? false : null" :color="'planned-date'" />
												</b-form-group>
                                            </ValidationProvider>
                                        </b-col>
                                        <b-col md="3" sm="12">
                                            <ValidationProvider rules="required" v-slot="{ errors }">
												<b-form-group label="Compartment transplant date" :invalid-feedback="errors[0]">
													<custom-datepicker :container="'#container-production-line'" v-model="planning.female.transplantingDate" :state="errors[0] ? false : null" :color="'planned-date'" />
												</b-form-group>
                                            </ValidationProvider>
                                        </b-col>
                                        <b-col md="3" sm="12">
                                            <ValidationProvider rules="required" v-slot="{ errors }">
												<b-form-group label="Start pollination date" :invalid-feedback="errors[0]">
													<custom-datepicker :container="'#container-production-line'" v-model="planning.female.startPollinationDate" :state="errors[0] ? false : null" :color="'planned-date'" />
												</b-form-group>
                                            </ValidationProvider>
                                        </b-col>
                                        <b-col md="3" sm="12">
                                            <ValidationProvider rules="required" v-slot="{ errors }">
												<b-form-group label="End pollination date" :invalid-feedback="errors[0]">
													<custom-datepicker :container="'#container-production-line'" v-model="planning.female.endPollinationDate" :state="errors[0] ? false : null" :color="'planned-date'" />
												</b-form-group>
                                            </ValidationProvider>
                                        </b-col>
                                        <b-col md="3" sm="12">
                                            <ValidationProvider rules="required" v-slot="{ errors }">
												<b-form-group label="Start harvest date" :invalid-feedback="errors[0]">
													<custom-datepicker :container="'#container-production-line'" v-model="planning.female.startHarvestDate" :state="errors[0] ? false : null" :color="'planned-date'" />
												</b-form-group>
                                            </ValidationProvider>
                                        </b-col>
                                        <b-col md="3" sm="12">
                                            <ValidationProvider rules="required" v-slot="{ errors }">
												<b-form-group label="End harvest date" :invalid-feedback="errors[0]">
													<custom-datepicker :container="'#container-production-line'" v-model="planning.female.endHarvestDate" :state="errors[0] ? false : null" :color="'planned-date'" />
												</b-form-group>
                                            </ValidationProvider>
                                        </b-col>
                                    </b-row>
                                </b-form>
                            </ValidationObserver>
                        </b-card>
                    </b-collapse>
                </div>
                <b-row class="mt-3" v-if="!editPlanning">
                    <div class="col-12">
                        <template v-for="(parent, index) in planning">
                            <b-table-simple responsive bordered :key="index" :class="parent.gender === 'male' ? 'mb-0': 'mt-0'">
                                <b-thead>
                                    <b-tr>
                                        <b-th class="text-center" style="width: 6%">Parent</b-th>
                                        <b-th class="text-center" style="width: 16%">Batch</b-th>
<!--                                        <b-th class="text-center" style="width: 16%">Order date</b-th>-->
                                        <b-th class="text-center" style="width: 16%">Seed shipped date</b-th>
                                        <b-th class="text-center" style="width: 20%">Received in PROMEX date</b-th>
                                        <b-th class="text-center" style="width: 16%">Released date</b-th>
                                    </b-tr>
                                </b-thead>
                                <b-tbody>
                                    <b-tr style="height: 28.5px;">
                                        <b-td :rowspan="parent.gender === 'male' ? '3' : '5'">
                                            <div class="d-flex justify-content-center align-items-center">
                                                <font-awesome-icon icon="mars" class="parent-icon" v-if="index === 'male'"></font-awesome-icon>
                                                <font-awesome-icon icon="venus" class="parent-icon parent-icon--female" v-else></font-awesome-icon>
                                                &nbsp;{{ index=== 'male' ? productionLine.parentalMaleName : productionLine.parentalFemaleName}}
                                            </div>
                                        </b-td>
                                        <b-td class="text-center">{{ parent.batches }}</b-td>
<!--                                        <b-td class="text-center">{{ formatDate(productionLine.orderDate) }}</b-td>-->
                                        <b-td class="text-center text-danger">{{ parent.shippedDate }}</b-td>
                                        <b-td class="text-center text-danger">{{ parent.receivedDate }}</b-td>
                                        <b-td class="text-center text-danger">{{ parent.releasedDate }}</b-td>
                                    </b-tr>
                                    <b-tr>
                                        <b-td class="text-center" style="background-color: #eeeeee;"><strong>Start sowing date</strong></b-td>
                                        <b-td class="text-center" style="background-color: #eeeeee;"><strong>Germination date</strong></b-td>
                                        <b-td class="text-center" style="background-color: #eeeeee;"><strong>Transplant to tidal floor date</strong></b-td>
                                        <b-td class="text-center" style="background-color: #eeeeee;"><strong>Compartment transplant date</strong></b-td>
                                    </b-tr>
                                    <b-tr style="height: 28.5px;">
                                        <b-td class="text-center text-danger">{{ parent.startSowingDate }}</b-td>
                                        <b-td class="text-center text-danger">{{ parent.germinationDate }}</b-td>
                                        <b-td class="text-center text-danger">{{ parent.transplantTidalFloorDate }}</b-td>
                                        <b-td class="text-center text-danger">{{ parent.transplantingDate }}</b-td>
                                    </b-tr>
                                    <b-tr v-if="parent.gender === 'female'">
                                        <b-td class="text-center" style="background-color: #eeeeee;"><strong>Start pollination date</strong></b-td>
                                        <b-td class="text-center" style="background-color: #eeeeee;"><strong>End pollination date</strong></b-td>
                                        <b-td class="text-center" style="background-color: #eeeeee;"><strong>Start harvest date</strong></b-td>
                                        <b-td class="text-center" style="background-color: #eeeeee;"><strong>End harvest date</strong></b-td>
                                    </b-tr>
                                    <b-tr style="height: 28.5px;" v-if="parent.gender === 'female'">
                                        <b-td class="text-center text-danger">{{ parent.startPollinationDate }}</b-td>
                                        <b-td class="text-center text-danger">{{ parent.endPollinationDate }}</b-td>
                                        <b-td class="text-center text-danger">{{ parent.startHarvestDate }}</b-td>
                                        <b-td class="text-center text-danger">{{ parent.endHarvestDate }}</b-td>
                                    </b-tr>
                                </b-tbody>
                            </b-table-simple>
                        </template>
                    </div>
                </b-row>
            </b-card>

            <b-card class="mt-5" ref="sowingCard" v-if="hasSowingListPermission">
                <div class="d-flex justify-content-between mb-2">
                    <h3 class="title">Sowing - {{ productionLine.varietyName }}{{ productionLine.tag ? '-' + productionLine.tag : ''}}
                        <h6 class="label label-primary ml-1" v-if="stages[1].ready" style="font-size: 12px">
                            Completed
                        </h6>
                    </h3>
                    <div>
                        <b-button size="sm" v-b-tooltip.hover title="Complete process" variant="outline-primary" v-if="sowingReadyToComplete" @click="completeProcess('Sowing', 2)">
                            <font-awesome-icon icon="check"></font-awesome-icon>
                        </b-button>
                        <b-button size="sm" v-b-tooltip.hover title="Cancel" variant="outline-primary" v-if="editSowing" @click="editStage(2, 'CANCEL')">
                            <font-awesome-icon icon="ban"></font-awesome-icon>
                        </b-button>
                        <b-button size="sm" @click="editStage(2, 'EDIT')"
                                  v-b-tooltip.hover v-if="!editSowing && stages[0].ready && hasSowingCreatePermission && productionLine.processStatusId !== 4"
                                  title="Edit" variant="outline-primary">
                            <font-awesome-icon icon="edit"></font-awesome-icon>
                        </b-button>
                        <b-button type="submit" form="sowing-form" size="sm" v-b-tooltip.hover title="Save" v-if="editSowing && hasSowingCreatePermission"
                                  variant="outline-primary">
                            <font-awesome-icon icon="save"></font-awesome-icon>
                        </b-button>
                    </div>
                </div>
                <b-collapse :visible="editSowing">
                    <b-card style="width: 100%">
                        <ValidationObserver v-slot="{ passes }">
                            <b-form @submit.prevent="passes(saveSowing)" id="sowing-form">
                                <h5 class="d-flex align-items-center"><font-awesome-icon icon="mars" class="parent-icon"></font-awesome-icon>&nbsp;{{ productionLine.parentalMaleName }}</h5>
                                <b-row style="margin-bottom: 30px">
                                    <b-col md="3" sm="12">
                                        <ValidationProvider rules="required|integer|min_value:1" v-slot="{ errors }">
                                            <b-form-group label="Plants needed in GH" :invalid-feedback="errors[0]">
                                                <b-input v-model="sowing.male.plantsNeeded" :state="errors[0] ? false : null" autocomplete="off"></b-input>
                                            </b-form-group>
                                        </ValidationProvider>
                                    </b-col>
                                    <b-col md="3" sm="12">
                                        <ValidationProvider rules="required|integer|min_value:1" v-slot="{ errors }">
                                            <b-form-group label="Seeds received" :invalid-feedback="errors[0]">
                                                <b-input v-model="sowing.male.seedReceived" :state="errors[0] ? false : null" autocomplete="off"></b-input>
                                            </b-form-group>
                                        </ValidationProvider>
                                    </b-col>
                                    <b-col md="3" sm="12">
                                        <ValidationProvider rules="required|number|min_value:0.01" v-slot="{ errors }">
                                            <b-form-group label="Estimated germination NL" :invalid-feedback="errors[0]">
                                                <b-input v-model="sowing.male.germinationNl" :state="errors[0] ? false : null" autocomplete="off"></b-input>
                                            </b-form-group>
                                        </ValidationProvider>
                                    </b-col>
                                    <b-col md="3" sm="12">
                                        <ValidationProvider rules="required|number|min_value:0.01" v-slot="{ errors }">
                                            <b-form-group label="Germination % range" :invalid-feedback="errors[0]">
                                                <b-input v-model="sowing.male.germinationRange" :state="errors[0] ? false : null" autocomplete="off"></b-input>
                                            </b-form-group>
                                        </ValidationProvider>
                                    </b-col>
                                    <b-col md="3" sm="12">
                                        <ValidationProvider rules="required|number|min_value:0.01" v-slot="{ errors }">
                                            <b-form-group label="Germination % used" :invalid-feedback="errors[0]">
                                                <b-input v-model="sowing.male.germinationUsed" :state="errors[0] ? false : null" autocomplete="off"></b-input>
                                            </b-form-group>
                                        </ValidationProvider>
                                    </b-col>
                                    <b-col md="3" sm="12">
                                        <ValidationProvider rules="required|integer|min_value:1" v-slot="{ errors }">
                                            <b-form-group label="Seeds to sow" :invalid-feedback="errors[0]">
                                                <b-input v-model="sowing.male.seedsToSow" :state="errors[0] ? false : null" autocomplete="off"></b-input>
                                            </b-form-group>
                                        </ValidationProvider>
                                    </b-col>
                                    <b-col md="3" sm="12">
                                        <ValidationProvider rules="required|integer|min_value:1" v-slot="{ errors }">
                                            <b-form-group label="Trays to sow" :invalid-feedback="errors[0]">
                                                <b-input v-model="sowing.male.traysToSow" :state="errors[0] ? false : null" autocomplete="off"></b-input>
                                            </b-form-group>
                                        </ValidationProvider>
                                    </b-col>
                                    <b-col md="3" sm="12">
                                        <ValidationProvider rules="required|integer|min_value:1" v-slot="{ errors }">
                                            <b-form-group label="Seeds sown" :invalid-feedback="errors[0]">
                                                <b-input v-model="sowing.male.sownSeed" :state="errors[0] ? false : null" autocomplete="off"></b-input>
                                            </b-form-group>
                                        </ValidationProvider>
                                    </b-col>
                                    <b-col md="3" sm="12">
                                        <ValidationProvider rules="required|integer|min_value:1" v-slot="{ errors }">
                                            <b-form-group label="Trays sown" :invalid-feedback="errors[0]">
                                                <b-input v-model="sowing.male.traysSown" :state="errors[0] ? false : null" autocomplete="off"></b-input>
                                            </b-form-group>
                                        </ValidationProvider>
                                    </b-col>
                                    <b-col md="3" sm="12">
										<ValidationProvider rules="required" v-slot="{ errors }">
											<b-form-group label="Sowing date" :invalid-feedback="errors[0]">
												<custom-datepicker :container="'#container-production-line'" v-model="sowing.male.realSowingDate" :state="errors[0] ? false : null" :color="'real-date'"/>
											</b-form-group>
										</ValidationProvider>
                                    </b-col>
                                    <b-col md="3" sm="12">
                                        <ValidationProvider rules="required|integer|min_value:0" v-slot="{ errors }">
                                            <b-form-group label="Remaining seeds real" :invalid-feedback="errors[0]">
                                                <b-input v-model="sowing.male.remainingSeeds" :state="errors[0] ? false : null" autocomplete="off"></b-input>
                                            </b-form-group>
                                        </ValidationProvider>
                                    </b-col>
                                    <b-col md="3" sm="12">
                                        <ValidationProvider rules="required|integer|min_value:1" v-slot="{ errors }">
                                            <b-form-group label="Table number" :invalid-feedback="errors[0]">
                                                <b-input v-model="sowing.male.tableNumber" :state="errors[0] ? false : null" autocomplete="off"></b-input>
                                            </b-form-group>
                                        </ValidationProvider>
                                    </b-col>
                                </b-row>
                                <hr>
                                <h5 class="d-flex align-items-center"><font-awesome-icon icon="venus" class="parent-icon parent-icon--female"></font-awesome-icon>&nbsp;{{ productionLine.parentalFemaleName }}</h5>
                                <b-row style="margin-bottom: 30px">
                                    <b-col md="3" sm="12">
                                        <ValidationProvider rules="required|integer|min_value:1" v-slot="{ errors }">
                                            <b-form-group label="Plants needed in GH" :invalid-feedback="errors[0]">
                                                <b-input v-model="sowing.female.plantsNeeded" :state="errors[0] ? false : null" autocomplete="off"></b-input>
                                            </b-form-group>
                                        </ValidationProvider>
                                    </b-col>
                                    <b-col md="3" sm="12">
                                        <ValidationProvider rules="required|integer|min_value:1" v-slot="{ errors }">
                                            <b-form-group label="Seeds received" :invalid-feedback="errors[0]">
                                                <b-input v-model="sowing.female.seedReceived" :state="errors[0] ? false : null" autocomplete="off"></b-input>
                                            </b-form-group>
                                        </ValidationProvider>
                                    </b-col>
                                    <b-col md="3" sm="12">
                                        <ValidationProvider rules="required|number|min_value:0.01" v-slot="{ errors }">
                                            <b-form-group label="Estimated germination NL" :invalid-feedback="errors[0]">
                                                <b-input v-model="sowing.female.germinationNl" :state="errors[0] ? false : null" autocomplete="off"></b-input>
                                            </b-form-group>
                                        </ValidationProvider>
                                    </b-col>
                                    <b-col md="3" sm="12">
                                        <ValidationProvider rules="required|number|min_value:0.01" v-slot="{ errors }">
                                            <b-form-group label="Germination % range" :invalid-feedback="errors[0]">
                                                <b-input v-model="sowing.female.germinationRange" :state="errors[0] ? false : null" autocomplete="off"></b-input>
                                            </b-form-group>
                                        </ValidationProvider>
                                    </b-col>
                                    <b-col md="3" sm="12">
                                        <ValidationProvider rules="required|number|min_value:0.01" v-slot="{ errors }">
                                            <b-form-group label="Germination % used" :invalid-feedback="errors[0]">
                                                <b-input v-model="sowing.female.germinationUsed" :state="errors[0] ? false : null" autocomplete="off"></b-input>
                                            </b-form-group>
                                        </ValidationProvider>
                                    </b-col>
                                    <b-col md="3" sm="12">
                                        <ValidationProvider rules="required|integer|min_value:1" v-slot="{ errors }">
                                            <b-form-group label="Seeds to sow" :invalid-feedback="errors[0]">
                                                <b-input v-model="sowing.female.seedsToSow" :state="errors[0] ? false : null" autocomplete="off"></b-input>
                                            </b-form-group>
                                        </ValidationProvider>
                                    </b-col>
                                    <b-col md="3" sm="12">
                                        <ValidationProvider rules="required|integer|min_value:1" v-slot="{ errors }">
                                            <b-form-group label="Trays to sow" :invalid-feedback="errors[0]">
                                                <b-input v-model="sowing.female.traysToSow" :state="errors[0] ? false : null" autocomplete="off"></b-input>
                                            </b-form-group>
                                        </ValidationProvider>
                                    </b-col>
                                    <b-col md="3" sm="12">
                                        <ValidationProvider rules="required|integer|min_value:1" v-slot="{ errors }">
                                            <b-form-group label="Seeds sown" :invalid-feedback="errors[0]">
                                                <b-input v-model="sowing.female.sownSeed" :state="errors[0] ? false : null" autocomplete="off"></b-input>
                                            </b-form-group>
                                        </ValidationProvider>
                                    </b-col>
                                    <b-col md="3" sm="12">
                                        <ValidationProvider rules="required|integer|min_value:1" v-slot="{ errors }">
                                            <b-form-group label="Trays sown" :invalid-feedback="errors[0]">
                                                <b-input v-model="sowing.female.traysSown" :state="errors[0] ? false : null" autocomplete="off"></b-input>
                                            </b-form-group>
                                        </ValidationProvider>
                                    </b-col>
                                    <b-col md="3" sm="12">
										<ValidationProvider rules="required" v-slot="{ errors }">
											<b-form-group label="Sowing date" :invalid-feedback="errors[0]">
												<custom-datepicker :container="'#container-production-line'" v-model="sowing.female.realSowingDate" :state="errors[0] ? false : null" :color="'real-date'"/>
											</b-form-group>
										</ValidationProvider>
                                    </b-col>
                                    <b-col md="3" sm="12">
                                        <ValidationProvider rules="required|integer|min_value:0" v-slot="{ errors }">
                                            <b-form-group label="Remaining seeds real" :invalid-feedback="errors[0]">
                                                <b-input v-model="sowing.female.remainingSeeds" :state="errors[0] ? false : null" autocomplete="off"></b-input>
                                            </b-form-group>
                                        </ValidationProvider>
                                    </b-col>
                                    <b-col md="3" sm="12">
                                        <ValidationProvider rules="required|integer|min_value:1" v-slot="{ errors }">
                                            <b-form-group label="Table number" :invalid-feedback="errors[0]">
                                                <b-input v-model="sowing.female.tableNumber" :state="errors[0] ? false : null" autocomplete="off"></b-input>
                                            </b-form-group>
                                        </ValidationProvider>
                                    </b-col>
                                </b-row>
                            </b-form>
                        </ValidationObserver>
                    </b-card>
                </b-collapse>

                <b-row class="mt-3" v-if="!editSowing">
                    <div class="col-12">
                        <b-table-simple responsive bordered>
                            <b-thead>
                                <b-tr>
                                    <b-th class="text-center">Parent</b-th>
                                    <b-th class="text-center">Plants needed in GH</b-th>
                                    <b-th class="text-center">Seeds received</b-th>
                                    <b-th class="text-center">Estimated Germination NL</b-th>
                                    <b-th class="text-center">Germination % range</b-th>
                                    <b-th class="text-center">Germination % used</b-th>
                                    <b-th class="text-center">Seeds to sow</b-th>
                                    <b-th class="text-center">Trays to sow</b-th>
                                    <b-th class="text-center">Seeds sown</b-th>
                                    <b-th class="text-center">Trays sown</b-th>
                                    <b-th class="text-center" style="width: 100px;">Sowing date</b-th>
                                    <b-th class="text-center">Remaining seeds real</b-th>
                                    <b-th class="text-center">Table number</b-th>
                                </b-tr>
                            </b-thead>
                            <b-tbody>
                                <b-tr v-for="(parent, index) in sowing" :key="index">
                                    <b-td>
                                        <div class="d-flex justify-content-center align-items-center">
                                            <font-awesome-icon icon="mars" class="parent-icon" v-if="index === 'male'"></font-awesome-icon>
                                            <font-awesome-icon icon="venus" class="parent-icon parent-icon--female" v-else></font-awesome-icon>
                                            &nbsp;{{ index=== 'male' ? productionLine.parentalMaleName : productionLine.parentalFemaleName}}
                                        </div>
                                    </b-td>
                                    <b-td class="text-center">{{ parent.plantsNeeded }}</b-td>
                                    <b-td class="text-center">{{ parent.seedReceived }}</b-td>
                                    <b-td class="text-center">{{ formatPercentage(parent.germinationNl) }}</b-td>
                                    <b-td class="text-center">{{ formatPercentage(parent.germinationRange) }}</b-td>
                                    <b-td class="text-center">{{ formatPercentage(parent.germinationUsed) }}</b-td>
                                    <b-td class="text-center">{{ parent.seedsToSow }}</b-td>
                                    <b-td class="text-center">{{ parent.traysToSow }}</b-td>
                                    <b-td class="text-center">{{ parent.sownSeed }}</b-td>
                                    <b-td class="text-center">{{ parent.traysSown }}</b-td>
                                    <b-td :class="parent.realSowingDate ? 'text-center text-secondary' : 'text-center text-danger'">
                                        {{ parent.realSowingDate ? parent.realSowingDate : parent.sowingDate }}
                                    </b-td>
                                    <b-td class="text-center">{{ parent.remainingSeeds }}</b-td>
                                    <b-td class="text-center">{{ parent.tableNumber }}</b-td>
                                </b-tr>
                            </b-tbody>
                        </b-table-simple>
                    </div>
                </b-row>
            </b-card>

            <b-card class="mt-5" ref="germinationCard" v-if="hasGerminationListPermission">
                <div class="d-flex justify-content-between mb-2">
                    <h3 class="title">Germination - {{ productionLine.varietyName }}{{ productionLine.tag ? '-' + productionLine.tag : ''}}
                        <h6 class="label label-primary ml-1" v-if="stages[2].ready" style="font-size: 12px">
                            Completed
                        </h6>
                    </h3>
                    <div>
                        <b-button size="sm" v-b-tooltip.hover title="Complete process" variant="outline-primary" @click="completeProcess('Germination', 3)"
                                  v-if="germinationReadyToComplete">
                            <font-awesome-icon icon="check"></font-awesome-icon>
                        </b-button>
                        <b-button size="sm" v-b-tooltip.hover title="Cancel" variant="outline-primary" v-if="editGermination" @click="editStage(3, 'CANCEL')">
                            <font-awesome-icon icon="ban"></font-awesome-icon>
                        </b-button>
                        <b-button size="sm" @click="editStage(3, 'EDIT')"
                                  v-b-tooltip.hover v-if="!editGermination && stages[1].ready && hasGerminationCreatePermission && productionLine.processStatusId !== 4"
                                  title="Edit" variant="outline-primary">
                            <font-awesome-icon icon="edit"></font-awesome-icon>
                        </b-button>
                        <b-button type="submit" form="germination-form" size="sm" v-b-tooltip.hover title="Save" v-if="editGermination && hasGerminationCreatePermission"
                                  @click="setData(3)" variant="outline-primary">
                            <font-awesome-icon icon="save"></font-awesome-icon>
                        </b-button>
                    </div>
                </div>
                <b-collapse :visible="editGermination">
                    <b-card style="width: 100%">
                        <ValidationObserver v-slot="{ passes }">
                            <b-form @submit.prevent="passes(saveStageData)" id="germination-form">
                                <h5 class="d-flex align-items-center"><font-awesome-icon icon="mars" class="parent-icon"></font-awesome-icon>&nbsp;{{ productionLine.parentalMaleName }}</h5>
                                <b-row style="margin-bottom: 30px">
                                    <b-col md="3" sm="12">
                                        <ValidationProvider rules="required|number|min_value:0.01" v-slot="{ errors }">
                                            <b-form-group label="Planned germination %" :invalid-feedback="errors[0]">
                                                <b-input v-model="germination.male.plannedGermination" :state="errors[0] ? false : null" autocomplete="off"></b-input>
                                            </b-form-group>
                                        </ValidationProvider>
                                    </b-col>
                                    <b-col md="3" sm="12">
                                        <ValidationProvider :rules="'required|integer|min_value:1|maxGerminatedPlants:' +  germination.male.sownSeeds" v-slot="{ errors }">
                                            <b-form-group label="Germinated plants" :invalid-feedback="errors[0]">
                                                <b-input v-model="germination.male.germinatedPlants" :state="errors[0] ? false : null" autocomplete="off"></b-input>
                                            </b-form-group>
                                        </ValidationProvider>
                                    </b-col>
                                    <b-col md="3" sm="12">
										<ValidationProvider rules="required" v-slot="{ errors }">
											<b-form-group label="Germination date" :invalid-feedback="errors[0]">
												<custom-datepicker :container="'#container-production-line'" v-model="germination.male.realGerminationDate" :state="errors[0] ? false : null" :color="'real-date'"/>
											</b-form-group>
										</ValidationProvider>
                                    </b-col>
                                    <b-col md="3" sm="12">
                                        <ValidationProvider rules="required|number|min_value:0.01" v-slot="{ errors }">
                                            <b-form-group label="Historic germination %" :invalid-feedback="errors[0]">
                                                <b-input v-model="germination.male.germinationHistoric" :state="errors[0] ? false : null" autocomplete="off"></b-input>
                                            </b-form-group>
                                        </ValidationProvider>
                                    </b-col>
                                </b-row>
                                <hr>
                                <h5 class="d-flex align-items-center"><font-awesome-icon icon="venus" class="parent-icon parent-icon--female"></font-awesome-icon>&nbsp;{{ productionLine.parentalFemaleName }}</h5>
                                <b-row style="margin-bottom: 30px">
                                    <b-col md="3" sm="12">
                                        <ValidationProvider rules="required|number|min_value:0.01" v-slot="{ errors }">
                                            <b-form-group label="Planned germination %" :invalid-feedback="errors[0]">
                                                <b-input v-model="germination.female.plannedGermination" :state="errors[0] ? false : null" autocomplete="off"></b-input>
                                            </b-form-group>
                                        </ValidationProvider>
                                    </b-col>
                                    <b-col md="3" sm="12">
                                        <ValidationProvider :rules="'required|integer|min_value:1|maxGerminatedPlants:' +  germination.female.sownSeeds" v-slot="{ errors }">
                                        <b-form-group label="Germinated plants" :invalid-feedback="errors[0]">
                                                <b-input v-model="germination.female.germinatedPlants" :state="errors[0] ? false : null" autocomplete="off"></b-input>
                                            </b-form-group>
                                        </ValidationProvider>
                                    </b-col>
                                    <b-col md="3" sm="12">
										<ValidationProvider rules="required" v-slot="{ errors }">
											<b-form-group label="Germination date" :invalid-feedback="errors[0]">
												<custom-datepicker :container="'#container-production-line'" v-model="germination.female.realGerminationDate" :state="errors[0] ? false : null" :color="'real-date'"/>
											</b-form-group>
										</ValidationProvider>
                                    </b-col>
                                    <b-col md="3" sm="12">
                                        <ValidationProvider rules="required|number|min_value:0.01" v-slot="{ errors }">
                                            <b-form-group label="Historic germination %" :invalid-feedback="errors[0]">
                                                <b-input v-model="germination.female.germinationHistoric" :state="errors[0] ? false : null" autocomplete="off"></b-input>
                                            </b-form-group>
                                        </ValidationProvider>
                                    </b-col>
                                </b-row>
                            </b-form>
                        </ValidationObserver>
                    </b-card>
                </b-collapse>

                <b-row class="mt-3" v-if="!editGermination">
                    <div class="col-12">
                        <b-table-simple responsive bordered>
                            <b-thead>
                                <b-tr>
                                    <b-th class="text-center">Parent</b-th>
<!--                                    <b-th class="text-center">Estimated germination NL</b-th>-->
<!--                                    <b-th class="text-center">Germination % used</b-th>-->
                                    <b-th class="text-center">Planned germination %</b-th>
                                    <b-th class="text-center">Seeds sown</b-th>
                                    <b-th class="text-center">Germinated plants</b-th>
                                    <b-th class="text-center">Germination date</b-th>
                                    <b-th class="text-center">Historic germination %</b-th>
<!--                                    <b-th class="text-center">MS</b-th>-->
                                    <b-th class="text-center">Actual germination %</b-th>
                                </b-tr>
                            </b-thead>
                            <b-tbody>
                                <b-tr v-for="(parent, index) in germination" :key="index">
                                    <b-td>
                                        <div class="d-flex justify-content-center align-items-center">
                                            <font-awesome-icon icon="mars" class="parent-icon" v-if="index === 'male'"></font-awesome-icon>
                                            <font-awesome-icon icon="venus" class="parent-icon parent-icon--female" v-else></font-awesome-icon>
                                            &nbsp;{{ index=== 'male' ? productionLine.parentalMaleName : productionLine.parentalFemaleName}}
                                        </div>
                                    </b-td>
<!--                                    <b-td class="text-center">{{ formatPercentage(parent.germinationUsed) }}</b-td>-->
<!--                                    <b-td class="text-center">{{ parent.germinationNl }}%</b-td>-->
                                    <b-td class="text-center">{{ formatPercentage(parent.plannedGermination) }}</b-td>
                                    <b-td class="text-center">{{ parent.sownSeeds }}</b-td>
                                    <b-td class="text-center">{{ parent.germinatedPlants }}</b-td>
                                    <b-td :class="parent.realGerminationDate ? 'text-center text-secondary' : 'text-center text-danger'">
                                        {{ parent.realGerminationDate ? parent.realGerminationDate : parent.germinationDate }}
                                    </b-td>
                                    <b-td class="text-center">{{ formatPercentage(parent.germinationHistoric) }}</b-td>
<!--                                    <b-td class="text-center">{{ parent.traysSown }}</b-td>-->
                                    <b-td class="text-center">{{ index === 'male' ? maleGerminationPercentage : femaleGerminationPercentage }}</b-td>
                                </b-tr>
                            </b-tbody>
                        </b-table-simple>
                    </div>
                </b-row>
            </b-card>

            <b-card class="mt-5" ref="tidalFloorCard" v-if="hasTidalFloorListPermission">
                <div class="d-flex justify-content-between mb-2">
                    <h3 class="title">Tidal floor - {{ productionLine.varietyName }}{{ productionLine.tag ? '-' + productionLine.tag : ''}}
                        <h6 class="label label-primary ml-1" v-if="stages[3].ready" style="font-size: 12px">
                            Completed
                        </h6>
                    </h3>
                    <div>
                        <b-button size="sm" v-b-tooltip.hover title="Complete process" variant="outline-primary" v-if="tidalFloorReadyToComplete" @click="completeProcess('Tidal floor', 4)">
                            <font-awesome-icon icon="check"></font-awesome-icon>
                        </b-button>
                        <b-button size="sm" v-b-tooltip.hover title="Cancel" variant="outline-primary" v-if="editTidalFloor" @click="editStage(4, 'CANCEL')">
                            <font-awesome-icon icon="ban"></font-awesome-icon>
                        </b-button>
                        <b-button size="sm" @click="editStage(4, 'EDIT')"
                                  v-b-tooltip.hover v-if="!editTidalFloor && stages[2].ready && hasTidalFloorCreatePermission && productionLine.processStatusId !== 4"
                                  title="Edit" variant="outline-primary">
                            <font-awesome-icon icon="edit"></font-awesome-icon>
                        </b-button>
                        <b-button type="submit" form="tidal-floor-form" size="sm" v-b-tooltip.hover title="Save" v-if="editTidalFloor && hasTidalFloorCreatePermission" @click="setData(4)"
                                  variant="outline-primary">
                            <font-awesome-icon icon="save"></font-awesome-icon>
                        </b-button>
                    </div>
                </div>
                <b-collapse :visible="editTidalFloor">
                    <b-card style="width: 100%">
                        <ValidationObserver v-slot="{ passes }">
                            <b-form @submit.prevent="passes(saveStageData)" id="tidal-floor-form">
                                <h5 class="d-flex align-items-center"><font-awesome-icon icon="mars" class="parent-icon"></font-awesome-icon>&nbsp;{{ productionLine.parentalMaleName }}</h5>
                                <b-row style="margin-bottom: 30px">
                                    <b-col md="3" sm="12">
                                        <ValidationProvider rules="required|number|min_value:0.01" v-slot="{ errors }">
                                            <b-form-group label="Extra plants in floor %" :invalid-feedback="errors[0]">
                                                <b-input v-model="tidalFloor.male.extraPlantsInFloor" :state="errors[0] ? false : null" autocomplete="off"></b-input>
                                            </b-form-group>
                                        </ValidationProvider>
                                    </b-col>
                                    <b-col md="3" sm="12">
                                        <ValidationProvider rules="required|integer|min_value:1" v-slot="{ errors }">
                                            <b-form-group label="Calculated plants in floor required" :invalid-feedback="errors[0]">
                                                <b-input v-model="tidalFloor.male.plantsInFloorRequired" :state="errors[0] ? false : null" autocomplete="off"></b-input>
                                            </b-form-group>
                                        </ValidationProvider>
                                    </b-col>
                                    <b-col md="3" sm="12">
                                        <ValidationProvider rules="required|number|min_value:0.01" v-slot="{ errors }">
                                            <b-form-group label="Tidal floor capacity" :invalid-feedback="errors[0]">
                                                <b-input v-model="tidalFloor.male.tidalFloorCapacity" :state="errors[0] ? false : null" autocomplete="off"></b-input>
                                            </b-form-group>
                                        </ValidationProvider>
                                    </b-col>
                                    <b-col md="3" sm="12">
                                        <ValidationProvider rules="required" v-slot="{ errors }">
											<b-form-group label="No tidal floor needed">
												<Batches v-model="tidalFloor.male.noTidalFloorsNeeded" style="width: 100%; min-height: 16px;" :state="errors[0] ? false : null"></Batches>
											</b-form-group>
                                        </ValidationProvider>
                                    </b-col>
                                    <b-col md="3" sm="12">
                                        <ValidationProvider rules="required|number|min_value:0.01" v-slot="{ errors }">
                                            <b-form-group label="Tidal floor number" :invalid-feedback="errors[0]">
                                                <b-input v-model="tidalFloor.male.tidalFloorNumber" :state="errors[0] ? false : null" autocomplete="off"></b-input>
                                            </b-form-group>
                                        </ValidationProvider>
                                    </b-col>
                                    <b-col md="3" sm="12">
                                        <ValidationProvider :rules="plantsInFloorValidationMale" v-slot="{ errors }">
                                            <b-form-group label="Plants in floor" :invalid-feedback="errors[0]">
                                                <b-input v-model="tidalFloor.male.plantsInFloor" :state="errors[0] ? false : null" autocomplete="off"></b-input>
                                            </b-form-group>
                                        </ValidationProvider>
                                    </b-col>
                                    <b-col md="3" sm="12">
										<ValidationProvider rules="required" v-slot="{ errors }">
											<b-form-group label="Transplant to tidal floor date" :invalid-feedback="errors[0]">
												<custom-datepicker :container="'#container-production-line'" v-model="tidalFloor.male.realTidalFloorDate" :state="errors[0] ? false : null" :color="'real-date'"/>
											</b-form-group>
										</ValidationProvider>
                                    </b-col>
                                </b-row>
                                <hr>
                                <h5 class="d-flex align-items-center"><font-awesome-icon icon="venus" class="parent-icon parent-icon--female"></font-awesome-icon>&nbsp;{{ productionLine.parentalFemaleName }}</h5>
                                <b-row style="margin-bottom: 30px">
                                    <b-col md="3" sm="12">
                                        <ValidationProvider rules="required|number|min_value:0.01" v-slot="{ errors }">
                                            <b-form-group label="Extra plants in floor %" :invalid-feedback="errors[0]">
                                                <b-input v-model="tidalFloor.female.extraPlantsInFloor" :state="errors[0] ? false : null" autocomplete="off"></b-input>
                                            </b-form-group>
                                        </ValidationProvider>
                                    </b-col>
                                    <b-col md="3" sm="12">
                                        <ValidationProvider rules="required|integer|min_value:1" v-slot="{ errors }">
                                            <b-form-group label="Calculated plants in floor required" :invalid-feedback="errors[0]">
                                                <b-input v-model="tidalFloor.female.plantsInFloorRequired" :state="errors[0] ? false : null" autocomplete="off"></b-input>
                                            </b-form-group>
                                        </ValidationProvider>
                                    </b-col>
                                    <b-col md="3" sm="12">
                                        <ValidationProvider rules="required|number|min_value:0.01" v-slot="{ errors }">
                                            <b-form-group label="Tidal floor capacity" :invalid-feedback="errors[0]">
                                                <b-input v-model="tidalFloor.female.tidalFloorCapacity" :state="errors[0] ? false : null" autocomplete="off"></b-input>
                                            </b-form-group>
                                        </ValidationProvider>
                                    </b-col>
                                    <b-col md="3" sm="12">
                                        <ValidationProvider rules="required" v-slot="{ errors }">
											<b-form-group label="No tidal floor needed">
												<Batches v-model="tidalFloor.female.noTidalFloorsNeeded" style="width: 100%; min-height: 16px;" :state="errors[0] ? false : null"></Batches>
											</b-form-group>
                                        </ValidationProvider>
                                    </b-col>
                                    <b-col md="3" sm="12">
                                        <ValidationProvider rules="required|number|min_value:0.01" v-slot="{ errors }">
                                            <b-form-group label="Tidal floor number" :invalid-feedback="errors[0]">
                                                <b-input v-model="tidalFloor.female.tidalFloorNumber" :state="errors[0] ? false : null" autocomplete="off"></b-input>
                                            </b-form-group>
                                        </ValidationProvider>
                                    </b-col>
                                    <b-col md="3" sm="12">
                                        <ValidationProvider :rules="plantsInFloorValidationFemale" v-slot="{ errors }">
                                            <b-form-group label="Plants in floor" :invalid-feedback="errors[0]">
                                                <b-input v-model="tidalFloor.female.plantsInFloor" :state="errors[0] ? false : null" autocomplete="off"></b-input>
                                            </b-form-group>
                                        </ValidationProvider>
                                    </b-col>
                                    <b-col md="3" sm="12">
										<ValidationProvider rules="required" v-slot="{ errors }">
											<b-form-group label="Transplant to tidal floor date" :invalid-feedback="errors[0]">
												<custom-datepicker :container="'#container-production-line'" v-model="tidalFloor.female.realTidalFloorDate" :state="errors[0] ? false : null" :color="'real-date'"/>
											</b-form-group>
										</ValidationProvider>
                                    </b-col>
                                </b-row>
                            </b-form>
                        </ValidationObserver>
                    </b-card>
                </b-collapse>

                <b-row class="mt-3" v-if="!editTidalFloor">
                    <div class="col-12">
                        <b-table-simple responsive bordered>
                            <b-thead>
                                <b-tr>
                                    <b-th class="text-center">Parent</b-th>
                                    <b-th class="text-center">Extra plants in floor %</b-th>
                                    <b-th class="text-center">Calculated plants in floor required</b-th>
                                    <b-th class="text-center">Tidal floor capacity</b-th>
                                    <b-th class="text-center">No tidal floor needed</b-th>
                                    <b-th class="text-center">Tidal floor number</b-th>
                                    <b-th class="text-center">Plants in floor</b-th>
                                    <b-th class="text-center">Transplant to tidal floor date</b-th>
                                </b-tr>
                            </b-thead>
                            <b-tbody>
                                <b-tr v-for="(parent, index) in tidalFloor" :key="index">
                                    <b-td>
                                        <div class="d-flex justify-content-center align-items-center">
                                            <font-awesome-icon icon="mars" class="parent-icon" v-if="index === 'male'"></font-awesome-icon>
                                            <font-awesome-icon icon="venus" class="parent-icon parent-icon--female" v-else></font-awesome-icon>
                                            &nbsp;{{ index=== 'male' ? productionLine.parentalMaleName : productionLine.parentalFemaleName}}
                                        </div>
                                    </b-td>
                                    <b-td class="text-center">{{ formatPercentage(parent.extraPlantsInFloor) }}</b-td>
                                    <b-td class="text-center">{{ parent.plantsInFloorRequired }}</b-td>
                                    <b-td class="text-center">{{ parent.tidalFloorCapacity }}</b-td>
                                    <b-td class="text-center">{{ parent.noTidalFloorsNeededSet }}</b-td>
                                    <b-td class="text-center">{{ parent.tidalFloorNumber }}</b-td>
                                    <b-td class="text-center">{{ parent.plantsInFloor }}</b-td>
                                    <b-td :class="parent.realTidalFloorDate ? 'text-center text-secondary' : 'text-center text-danger'">
                                        {{ parent.realTidalFloorDate ? parent.realTidalFloorDate : parent.transplantToTidalFloorDate }}
                                    </b-td>
                                </b-tr>
                            </b-tbody>
                        </b-table-simple>
                    </div>
                </b-row>
            </b-card>

            <b-card class="mt-5" ref="compartmentTransplantCard" v-if="productionLine.productionLineOccupancy && hasCompartmentListPermission">
                <div class="d-flex justify-content-between mb-2">
                    <h3 class="title">Compartment transplant - {{ productionLine.varietyName }}{{ productionLine.tag ? '-' + productionLine.tag : ''}}
                        <h6 class="label label-primary ml-1" v-if="stages[4].ready" style="font-size: 12px">
                            Completed
                        </h6>
                    </h3>
                    <div>
                        <b-button size="sm" v-b-tooltip.hover title="Complete process" variant="outline-primary" v-if="compartmentTransplantReadyToComplete" @click="completeProcess('Compartment transplant', 5)">
                            <font-awesome-icon icon="check"></font-awesome-icon>
                        </b-button>
                        <b-button size="sm" v-b-tooltip.hover title="Cancel" variant="outline-primary" v-if="editCompartmentTransplant" @click="editStage(5, 'CANCEL')">
                            <font-awesome-icon icon="ban"></font-awesome-icon>
                        </b-button>
                        <b-button size="sm" @click="editStage(5, 'EDIT')"
                                  v-b-tooltip.hover v-if="!editCompartmentTransplant && stages[3].ready && hasCompartmentCreatePermission"
                                  title="Edit" variant="outline-primary">
                            <font-awesome-icon icon="edit"></font-awesome-icon>
                        </b-button>
                        <b-button type="submit" form="compartment-transplant-form" size="sm" v-b-tooltip.hover title="Save" v-if="editCompartmentTransplant && hasCompartmentCreatePermission" @click="setData(5)"
                                  variant="outline-primary">
                            <font-awesome-icon icon="save"></font-awesome-icon>
                        </b-button>
                    </div>
                </div>
                <b-collapse :visible="editCompartmentTransplant">
                    <b-card style="width: 100%">
                        <ValidationObserver v-slot="{ passes }">
                            <b-form @submit.prevent="passes(saveStageData)" id="compartment-transplant-form">
                                <h5 class="d-flex align-items-center"><font-awesome-icon icon="mars" class="parent-icon"></font-awesome-icon>&nbsp;{{ productionLine.parentalMaleName }}</h5>
                                <b-row style="margin-bottom: 30px">
                                    <b-col md="3" sm="12">
                                        <ValidationProvider :rules="'required|integer|min_value:1|maxPlantsCompartment:' + tidalFloor.male.plantsInFloor" v-slot="{ errors }">
                                            <b-form-group label="Plants in compartment" :invalid-feedback="errors[0]">
                                                <b-input v-model="compartmentTransplant.male.plantsInCompartment" :state="errors[0] ? false : null" autocomplete="off"></b-input>
                                            </b-form-group>
                                        </ValidationProvider>
                                    </b-col>
                                    <b-col md="3" sm="12">
										<ValidationProvider rules="required" v-slot="{ errors }">
											<b-form-group label="Compartment transplant date" :invalid-feedback="errors[0]">
												<custom-datepicker :container="'#container-production-line'" v-model="compartmentTransplant.male.realCompartmentTransplantDate" :state="errors[0] ? false : null" :color="'real-date'" />
											</b-form-group>
										</ValidationProvider>
                                    </b-col>
                                </b-row>
                                <hr>
                                <h5 class="d-flex align-items-center"><font-awesome-icon icon="venus" class="parent-icon parent-icon--female"></font-awesome-icon>&nbsp;{{ productionLine.parentalFemaleName }}</h5>
                                <b-row style="margin-bottom: 30px">
                                    <b-col md="3" sm="12">
                                        <ValidationProvider :rules="'required|integer|min_value:1|maxPlantsCompartment:' + tidalFloor.female.plantsInFloor" v-slot="{ errors }">
                                        <b-form-group label="Plants in compartment" :invalid-feedback="errors[0]">
                                                <b-input v-model="compartmentTransplant.female.plantsInCompartment" :state="errors[0] ? false : null" autocomplete="off"></b-input>
                                            </b-form-group>
                                        </ValidationProvider>
                                    </b-col>
                                    <b-col md="3" sm="12">
										<ValidationProvider rules="required" v-slot="{ errors }">
											<b-form-group label="Compartment transplant date" :invalid-feedback="errors[0]">
												<custom-datepicker :container="'#container-production-line'" v-model="compartmentTransplant.female.realCompartmentTransplantDate" :state="errors[0] ? false : null" :color="'real-date'" />
											</b-form-group>
										</ValidationProvider>
                                    </b-col>
                                </b-row>
                            </b-form>
                        </ValidationObserver>
                    </b-card>
                </b-collapse>

                <b-row class="mt-3" v-if="!editCompartmentTransplant">
                    <div class="col-12">
                        <b-table-simple responsive bordered>
                            <b-thead>
                                <b-tr>
                                    <b-th class="text-center">Parent</b-th>
                                    <b-th class="text-center">Plants in compartment</b-th>
                                    <b-th class="text-center">Remaining plants</b-th>
                                    <b-th class="text-center">Compartment transplant date</b-th>
                                </b-tr>
                            </b-thead>
                            <b-tbody>
                                <b-tr v-for="(parent, index) in compartmentTransplant" :key="index">
                                    <b-td style="width: 25%">
                                        <div class="d-flex justify-content-center align-items-center">
                                            <font-awesome-icon icon="mars" class="parent-icon" v-if="index === 'male'"></font-awesome-icon>
                                            <font-awesome-icon icon="venus" class="parent-icon parent-icon--female" v-else></font-awesome-icon>
                                            &nbsp;{{ index === 'male' ? productionLine.parentalMaleName : productionLine.parentalFemaleName}}
                                        </div>
                                    </b-td>
                                    <b-td class="text-center" style="width: 25%">{{ parent.plantsInCompartment }}</b-td>
                                    <b-td class="text-center" style="width: 25%">{{ index === 'male' ? malePlantsRemaining : femalePlantsRemaining }}</b-td>
                                    <b-td :class="parent.realCompartmentTransplantDate ? 'text-center text-secondary' : 'text-center text-danger'" style="width: 25%">
                                        {{ parent.realCompartmentTransplantDate ? parent.realCompartmentTransplantDate : parent.compartmentTransplantDate }}
                                    </b-td>
                                </b-tr>
                            </b-tbody>
                        </b-table-simple>
                    </div>
                </b-row>
            </b-card>

            <b-card class="mt-5" ref="pollenHarvestCard" v-if="productionLine.productionLineOccupancy && hasPollenHarvestListPermission" style="min-height: 80px;">
                <template v-if="productionLine.productionLineOccupancy">
                    <div class="d-flex justify-content-between mb-2">
                        <h3 class="title">Pollen harvest - {{ productionLine.varietyName }}{{ productionLine.tag ? '-' + productionLine.tag : ''}}
                            <h6 class="label label-primary ml-1" v-if="stages[5].ready" style="font-size: 12px">
                                Completed
                            </h6>
                        </h3>
                        <div>
                            <b-button size="sm" v-b-tooltip.hover title="Complete process" variant="outline-primary" v-if="pollenHarvestReadyToComplete"
                                      @click="completeProcess('Pollen harvest', 6)">
                                <font-awesome-icon icon="check"></font-awesome-icon>
                            </b-button>
                        </div>
                    </div>
                    <b-row v-if="stages[4].ready">
                        <div class="col-12">
                            <b-col class="col-12">
                                <ValidationObserver v-slot="{ passes }" ref="formRecollection">
                                    <b-form @submit.prevent="passes(saveStageData)" id="pollen-harvest-recollection-form2">
                                        <b-table-simple class="mt-3" responsive bordered>
                                            <b-thead>
                                                <b-tr>
                                                    <b-th class="text-center">Harvest date</b-th>
                                                    <b-th class="text-center" style="width: 18%">Harvest calendar week</b-th>
                                                    <b-th class="text-center" style="width: 18%">Wet (ml)</b-th>
                                                    <b-th class="text-center">Dry (ml)</b-th>
                                                    <b-th class="text-center" style="width: 16%">Tubes</b-th>
                                                    <b-th class="text-center">Actions</b-th>
                                                </b-tr>
                                            </b-thead>
                                            <b-tbody>
                                                <b-tr v-if="hasPollenHarvestCreatePermission">
                                                    <b-td style="width: 20%">
														<ValidationProvider :rules="editRecollection ? '' : 'required'" v-slot="{ errors }">
															<b-form-group label="" :invalid-feedback="errors[0]">
																<custom-datepicker :container="'#container-production-line'" v-model="newPollenHarvestRecollection.harvestDate" :state="errors[0] ? false : null" />
															</b-form-group>
														</ValidationProvider>
                                                    </b-td>
                                                    <b-td class="text-center">
                                                        {{ getWeek(newPollenHarvestRecollection.harvestDate, 'create')}}
                                                    </b-td>
                                                    <b-td>
                                                        <ValidationProvider :rules="editRecollection ? '' : 'required|number|min_value:0.01'" v-slot="{ errors }">
                                                            <b-form-group :invalid-feedback="errors[0]">
                                                                <b-input v-model="newPollenHarvestRecollection.totalCollected"
                                                                         :state="errors[0] ? false : null" autocomplete="off">
                                                                </b-input>
                                                            </b-form-group>
                                                        </ValidationProvider>
                                                    </b-td>
                                                    <b-td></b-td>
                                                    <b-td></b-td>
                                                    <b-td>
                                                        <div class="d-flex justify-content-center align-items-center">
                                                            <b-button type="submit" form="pollen-harvest-recollection-form2" @click="setData(6)"
                                                                      variant="outline-primary" size="sm" v-b-tooltip.hover.bottom title="Save">
                                                                <font-awesome-icon icon="save"></font-awesome-icon>
                                                            </b-button>
                                                        </div>
                                                    </b-td>
                                                </b-tr>
                                                <template v-if="recollection.length > 0">
                                                    <b-tr v-for="(item, index) in recollection" :key="index">
                                                        <template v-if="!item.isEditing">
                                                            <b-td class="text-center">{{ item.harvestDate }}</b-td>
                                                            <b-td class="text-center">{{ item.harvestWeek }}</b-td>
                                                            <b-td class="text-center">{{ item.totalCollected }}</b-td>
                                                            <b-td class="text-center">{{ item.totalDriedMl }}</b-td>
                                                            <b-td class="text-center mr-5">{{ item.tubes.length }}</b-td>
                                                            <b-td class="text-center">
                                                                <b-button size="sm" @click="showTubesTable(item)"
                                                                          v-b-tooltip.hover.left title="Tubes" variant="outline-primary">
                                                                    <font-awesome-icon icon="vial"></font-awesome-icon>
                                                                </b-button>
                                                                <b-button size="sm" @click="editStage(6, 'EDIT', item)" v-if="hasPollenHarvestCreatePermission"
                                                                          v-b-tooltip.hover.left
                                                                          title="Edit" variant="outline-primary">
                                                                    <font-awesome-icon icon="edit"></font-awesome-icon>
                                                                </b-button>
                                                                <b-button size="sm" v-b-tooltip.hover.right title="Delete" variant="outline-primary"
                                                                          @click="deletePollenHarvest(item.id, 1)" v-if="hasPollenHarvestCreatePermission">
                                                                    <font-awesome-icon icon="trash-alt"></font-awesome-icon>
                                                                </b-button>
                                                            </b-td>
                                                        </template>
                                                        <template v-else>
                                                            <b-td>
																<ValidationProvider rules="required" v-slot="{ errors }">
																	<b-form-group label="" :invalid-feedback="errors[0]">
																		<custom-datepicker :container="'#container-production-line'" v-model="item.harvestDate" :state="errors[0] ? false : null" />
																	</b-form-group>
																</ValidationProvider>
                                                            </b-td>
                                                            <b-td class="text-center">
                                                                {{ getWeek(item.harvestDate, 'edit', item)}}
                                                            </b-td>
                                                            <b-td>
                                                                <ValidationProvider rules="required|number|min_value:0.01" v-slot="{ errors }">
                                                                    <b-form-group :invalid-feedback="errors[0]">
                                                                        <b-input v-model="item.totalCollected"
                                                                                 :state="errors[0] ? false : null" autocomplete="off">
                                                                        </b-input>
                                                                    </b-form-group>
                                                                </ValidationProvider>
                                                            </b-td>
                                                            <b-td class="text-center">{{ item.totalDriedMl }}</b-td>
                                                            <b-td class="text-center">{{ item.tubes.length }}</b-td>
                                                            <b-td>
                                                                <div class="d-flex justify-content-center align-items-center">
                                                                    <b-button type="submit" form="pollen-harvest-recollection-form2" @click="setData(9, item)"
                                                                              variant="outline-primary" size="sm" v-b-tooltip.hover.bottom title="Save">
                                                                        <font-awesome-icon icon="save"></font-awesome-icon>
                                                                    </b-button>
                                                                    <b-button @click="editStage(6, 'CANCEL_EDIT_ITEM', item)"
                                                                              variant="outline-primary" size="sm" v-b-tooltip.hover.bottom title="Cancel">
                                                                        <font-awesome-icon icon="ban"></font-awesome-icon>
                                                                    </b-button>
                                                                </div>
                                                            </b-td>
                                                        </template>
                                                    </b-tr>
                                                </template>
                                                <b-tr v-else-if="recollection.length === 0 && !hasPollenHarvestCreatePermission">
                                                    <b-td colspan="6" class="text-center">No records</b-td>
                                                </b-tr>
                                            </b-tbody>
                                        </b-table-simple>
                                    </b-form>
                                </ValidationObserver>
                            </b-col>
                        </div>
                    </b-row>
                    <b-row v-else>
                        <b-col sm="12" md="12">
                            <b-alert variant="warning" show><p class="text-center">No pollen harvest data</p></b-alert>
                        </b-col>
                    </b-row>
                </template>

                <b-modal id="tubes-modal" :title="'Harvest ' + newPollenHarvestTube.harvestDate + ' - ' + productionLine.varietyName + (productionLine.tag ? '-' + productionLine.tag : '')" size="xl" centered no-close-on-backdrop no-close-on-esc>
                    <ValidationObserver v-slot="{ passes }" ref="formTubes">
                        <b-form @submit.prevent="passes(saveStageData)" id="pollen-harvest-tubes-form2">
                            <b-table-simple class="mt-3" responsive bordered>
                                <b-thead>
                                    <b-tr>
                                        <b-th colspan="5" class="text-center font-bold"> Harvest date: {{ newPollenHarvestTube.harvestDate }} &nbsp;&nbsp;&nbsp;&nbsp; Wet (ml): {{ totalCollectedSelected }} </b-th>
                                    </b-tr>
                                </b-thead>
                                <b-thead>
                                    <b-tr>
                                        <b-th class="text-center" style="width: 20%">Tube ID</b-th>
                                        <b-th class="text-center" style="width: 20%">Dry (ml)</b-th>
                                        <b-th class="text-center" style="width: 10%">Inventory</b-th>
                                        <b-th class="text-center" style="width: 25%">Remarks</b-th>
                                        <b-th class="text-center" style="width: 15%" v-if="hasPollenHarvestCreatePermission">Actions</b-th>
                                    </b-tr>
                                </b-thead>
                                <b-tbody>
<!--                                    <b-tr v-if="(totalDriedMl - newPollenHarvestTube.driedMl) < recollectionSelected.totalCollected">-->
                                    <b-tr v-if="hasPollenHarvestCreatePermission">
                                        <b-td class="text-right">{{ newPollenHarvestTube.tubeId }}
                                        </b-td>
                                        <b-td>
                                            <ValidationProvider :rules="editTubes ? '' : 'required|number|min_value:0.01|max_value:1.5'" v-slot="{ errors }">
                                                <b-form-group :invalid-feedback="errors[0]">
                                                    <b-input v-model="newPollenHarvestTube.driedMl" :state="errors[0] ? false : null" autocomplete="off"></b-input>
                                                </b-form-group>
                                            </ValidationProvider>
                                        </b-td>
                                        <b-td>
                                            <div class="d-flex justify-content-center align-items-center">
                                                <b-form-group>
                                                    <b-checkbox v-model="newPollenHarvestTube.fromInventory"></b-checkbox>
                                                </b-form-group>
                                            </div>
                                        </b-td>
                                        <b-td>
                                            <b-form-group>
                                                <b-input v-model="newPollenHarvestTube.comments"></b-input>
                                            </b-form-group>
                                        </b-td>
                                        <b-td>
                                            <div class="d-flex justify-content-center align-items-center">
                                                <b-button type="submit" form="pollen-harvest-tubes-form2" @click="setData(7)"
                                                          variant="outline-primary" size="sm" v-b-tooltip.hover title="Save">
                                                    <font-awesome-icon icon="save"></font-awesome-icon>
                                                </b-button>
                                            </div>
                                        </b-td>
                                    </b-tr>
                                    <template v-if="tubes.length > 0">
                                        <b-tr v-for="(tube, index) in tubes" :key="index">
                                            <template v-if="!tube.isEditing">
                                                <b-td class="text-right">{{ tube.tubeId }}</b-td>
                                                <b-td class="text-right">{{ tube.driedMl }}</b-td>
                                                <b-td class="text-right">
                                                    <div class="d-flex justify-content-center align-items-center">
                                                        <font-awesome-icon icon="check" v-if="tube.fromInventory" class="icon-check"></font-awesome-icon>
                                                    </div>
                                                </b-td>
                                                <b-td>{{ tube.comments }}</b-td>
                                                <b-td class="text-center" v-if="hasPollenHarvestCreatePermission">
                                                    <b-button size="sm" @click="editStage(7, 'EDIT', tube)" v-if="tube.showEdit"
                                                              v-b-tooltip.hover.left title="Edit" variant="outline-primary">
                                                        <font-awesome-icon icon="edit"></font-awesome-icon>
                                                    </b-button>
                                                    <b-button size="sm" v-b-tooltip.hover.right title="Delete" variant="outline-primary" v-if="tube.showEdit"
                                                              @click="deletePollenHarvest(tube.id, 2)">
                                                        <font-awesome-icon icon="trash-alt"></font-awesome-icon>
                                                    </b-button>
                                                </b-td>
                                            </template>
                                            <template v-else>
                                                <b-td class="text-right">{{ tube.tubeId }}</b-td>
                                                <b-td>
                                                    <ValidationProvider rules="required|number|min_value:0.01|max_value:1.5" v-slot="{ errors }">
                                                        <b-form-group :invalid-feedback="errors[0]">
                                                            <b-input v-model="tube.driedMl" :state="errors[0] ? false : null" autocomplete="off"></b-input>
                                                        </b-form-group>
                                                    </ValidationProvider>
                                                </b-td>
                                                <b-td>
                                                    <div class="d-flex justify-content-center align-items-center">
                                                        <b-form-group>
                                                            <b-checkbox v-model="tube.fromInventory"></b-checkbox>
                                                        </b-form-group>
                                                    </div>
                                                </b-td>
                                                <b-td>
                                                    <b-form-group>
                                                        <b-input v-model="tube.comments"></b-input>
                                                    </b-form-group>
                                                </b-td>
                                                <b-td v-if="hasPollenHarvestCreatePermission">
                                                    <div class="d-flex justify-content-center align-items-center">
                                                        <b-button type="submit" form="pollen-harvest-tubes-form2" @click="setData(8, tube)"
                                                                  variant="outline-primary" size="sm" v-b-tooltip.hover title="Save">
                                                            <font-awesome-icon icon="save"></font-awesome-icon>
                                                        </b-button>
                                                        <b-button @click="editStage(7, 'CANCEL_EDIT_ITEM', tube)"
                                                                  variant="outline-primary" size="sm" v-b-tooltip.hover title="Cancel">
                                                            <font-awesome-icon icon="ban"></font-awesome-icon>
                                                        </b-button>
                                                    </div>
                                                </b-td>
                                            </template>
                                        </b-tr>
                                        <b-tr style="border: none !important;">
                                            <b-td style="border: none !important;"></b-td>
                                            <b-td style="height: 36px; vertical-align: middle; font-weight: bold;">
                                                <p class="d-flex justify-content-end">
                                                    Total: &nbsp;{{ totalDriedMl }}
                                                </p>
                                            </b-td>
                                            <b-td style="border: none !important;"></b-td>
                                            <b-td style="border: none !important;"></b-td>
                                        </b-tr>
                                    </template>
                                    <b-tr v-else-if="tubes.length === 0 && !hasPollenHarvestCreatePermission">
                                        <b-td colspan="5" class="text-center">No records</b-td>
                                    </b-tr>
                                </b-tbody>
                            </b-table-simple>
                        </b-form>
                    </ValidationObserver>

                    <template #modal-footer>
                        <div class="text-right">
                            <b-button @click="closeTubesModal" class="mr-2" variant="outline-secondary"> Close</b-button>
                        </div>
                    </template>
                </b-modal>
            </b-card>

            <b-card class="mt-5" ref="emasculationPollinationCard" v-if="productionLine.productionLineOccupancy && hasEmasculationPollListPermission" style="min-height: 80px;">
                <div class="d-flex justify-content-between mb-2">
                    <h3 class="title">Emasculation pollination - {{ productionLine.varietyName }}{{ productionLine.tag ? '-' + productionLine.tag : ''}}
                        <h6 class="label label-primary ml-1" v-if="stages[6].ready" style="font-size: 12px">
                            Completed
                        </h6>
                    </h3>
                    <div>
                        <b-button variant="outline-primary" @click="showFormSetData" v-if="stages[5].ready && showAddSet && hasEmasculationPollCreatePermission"><font-awesome-icon icon="plus"></font-awesome-icon> Add set</b-button>
                        <b-button size="sm" v-b-tooltip.hover title="Complete process" variant="outline-primary" v-if="emasculationPollinationReadyToComplete" @click="completeProcess('Emasculation pollination', 7)">
                            <font-awesome-icon icon="check"></font-awesome-icon>
                        </b-button>
                    </div>
                </div>
                <b-row v-if="sets.length > 0">
                    <b-col sm="12" md="12">
                        <b-tabs content-class="mt-3" ref="tabs" v-model="activeTab">
                            <b-tab v-for="(tab, index) in sets" :key="index" :title="tab.id ? 'Set ' + (index + 1) : 'Set'">
                                <set-component :data="tab === true ? tabData[index] : tab" @set-data="setData" ref="setComponent" :key="tab.id" @show-edit-view="showEditView"
                                               @save-stage-data="saveStageData" @load-data="loadData" @format-date="formatDate" :index="index" :show-edit-dates="showEditDates"
                                               :production-line-id="productionLine.id" :production-line="productionLine" :show-emasculation-column-set="productionLine.showEmasculationColumnSet"
                                               :crop-family="productionLine.cropFamilyName"></set-component>
                            </b-tab>
                        </b-tabs>
                    </b-col>
                </b-row>
                <b-row v-else>
                    <b-col sm="12" md="12">
                        <b-alert variant="warning" show><p class="text-center">No emasculation pollination data</p></b-alert>
                    </b-col>
                </b-row>
            </b-card>
            <b-card class="mt-5" ref="harvestCard" v-if="productionLine.productionLineOccupancy && hasHarvestListPermission" style="min-height: 80px;">
                <div class="d-flex justify-content-between mb-2">
                    <h3 class="title">Harvest - {{ productionLine.varietyName }}{{ productionLine.tag ? '-' + productionLine.tag : ''}}
                        <h6 class="label label-primary ml-1" v-if="stages[7].ready" style="font-size: 12px">
                            Completed
                        </h6>
                    </h3>
                    <div>
                        <b-button size="sm" v-b-tooltip.hover title="Complete process" variant="outline-primary" v-if="harvestReadyToComplete"  @click="completeProcess('Harvest', 8)">
                            <font-awesome-icon icon="check"></font-awesome-icon>
                        </b-button>
                    </div>
                </div>
                <b-row v-if="harvestData.length > 0">
                    <b-col sm="12" md="12" lg="12" v-if="harvestData.length > 0 && productionLine.cropFamilyName === 'Pepper'" class="w-100">
                        <harvest-component :data="harvestData" @save-stage-data="saveStageData" @load-data="loadData" @set-data="setData"></harvest-component>
                    </b-col>
                    <b-col sm="12" md="12" lg="12" v-if="productionLine.cropFamilyName === 'Tomato'">
                        <harvest-tomato-component :data="harvestData" @load-data="loadData" :start-pollination="startPollination" :end-pollination="endPollination" :planned-start-harvest="planning.female.startHarvestDate"
                                                  :planned-end-harvest="planning.female.endHarvestDate" :production-line-id="productionLine.id"></harvest-tomato-component>
                    </b-col>
                </b-row>
                <b-row v-else>
                    <b-col sm="12" md="12">
                        <b-alert variant="warning" show><p class="text-center">No harvest data</p></b-alert>
                    </b-col>
                </b-row>
            </b-card>

            <b-card class="mt-5" ref="extractionCard" v-if="productionLine.productionLineOccupancy && hasExtractionListPermission" style="min-height: 80px;">
                <div class="d-flex justify-content-between mb-2">
                    <h3 class="title">Extraction - {{ productionLine.varietyName }}{{ productionLine.tag ? '-' + productionLine.tag : ''}}
                        <h6 class="label label-primary ml-1" v-if="stages[8].ready" style="font-size: 12px">
                            Completed
                        </h6>
                    </h3>
                    <div>
                        <b-button size="sm" @click="editStage(8, 'EDIT')" type="button"
                                  v-b-tooltip.hover v-if="!editExtraction && stages[7].ready && hasExtractionCreatePermission"
                                  title="Edit" variant="outline-primary">
                            <font-awesome-icon icon="edit"></font-awesome-icon>
                        </b-button>
                        <b-button size="sm" v-b-tooltip.hover title="Complete process" variant="outline-primary" v-if="extractionReadyToComplete"  @click="completeProcess('Extraction', 9)">
                            <font-awesome-icon icon="check"></font-awesome-icon>
                        </b-button>
                        <b-button size="sm" v-b-tooltip.hover title="Cancel" variant="outline-primary" v-if="editExtraction" @click="editStage(8, 'CANCEL')">
                            <font-awesome-icon icon="ban"></font-awesome-icon>
                        </b-button>
                        <b-button type="submit" form="extraction-form" size="sm" v-b-tooltip.hover title="Save" v-show="editExtraction && hasExtractionCreatePermission" @click="setData(12)"
                                  variant="outline-primary">
                            <font-awesome-icon icon="save"></font-awesome-icon>
                        </b-button>
                    </div>
                </div>

                <b-collapse :visible="editExtraction">
                    <b-card style="width: 100%">
                        <ValidationObserver v-slot="{ passes }">
                            <b-form @submit.prevent="passes(saveStageData)" id="extraction-form">
                                <h5 class="d-flex align-items-center">&nbsp;{{ productionLine.varietyName }}</h5>
                                <b-row style="margin-bottom: 30px">
                                    <b-col md="3" sm="12">
										<ValidationProvider rules="required" v-slot="{ errors }">
											<b-form-group label="Extraction date" :invalid-feedback="errors[0]">
												<custom-datepicker :container="'#container-production-line'" v-model="extraction.date" :state="errors[0] ? false : null" :color="'real-color'"/>
											</b-form-group>
										</ValidationProvider>
                                    </b-col>
                                </b-row>
                            </b-form>
                        </ValidationObserver>
                    </b-card>
                </b-collapse>

                <b-row class="mt-3" v-if="!editExtraction">
                    <b-col sm="12" md="6" lg="6">
                        <b-table-simple responsive bordered>
                            <b-thead>
                                <b-tr>
                                    <b-th class="text-center">Production line</b-th>
                                    <b-th class="text-center">Extraction date</b-th>
                                </b-tr>
                            </b-thead>
                            <b-tbody>
                                <b-tr>
                                    <b-td style="width: 25%">
                                            &nbsp;{{ productionLine.varietyName }}{{ productionLine.tag ? '-' + productionLine.tag : ''}}
                                    </b-td>
                                    <b-td class="text-center" style="width: 25%">{{ extraction.date }}</b-td>
                                </b-tr>
                            </b-tbody>
                        </b-table-simple>
                    </b-col>
                </b-row>
            </b-card>
        </b-card>
    </b-overlay>
</template>

<script>
import {
    createSetUrl,
    deletePollenHarvestRecollectionUrl,
    deletePollenHarvestTubeUrl,
    getImgUrl,
    getProductionLineByIdUrl,
    getTubesByRecollectionIdUrl,
    productionLineCancelUrl,
    productionLineReleaseCompartmentUrl,
    saveExtractionUrl,
    savePollenHarvestRecollectionUrl,
    savePollenHarvestTubesUrl,
    updateGerminationUrl,
    updatePlanningUrl,
    updateSowingUrl,
    updateTidalFloorUrl,
    updateStageUrl,
    updateCompartmentTransplantUrl
} from '@routes';
import { integer, required, min_value, max_value, image } from 'vee-validate/dist/rules';
import { ProductionLineEntity } from '@/models/productionLineEntity';
import { extend } from 'vee-validate';
import Utilities, { formatDate } from '@/js/utilities';
import SetComponent from '@/views/EmasculationPollination/SetComponent.vue';
import HarvestComponent from '@/views/Harvest/HarvestComponent.vue';
import HarvestTomatoComponent from '@/views/Harvest/HarvestTomatoComponent.vue';
import { datePickerLabels } from '@/i18n/datePickerI18n';
import $permission from '@/js/permission';
import Batches from '@components/batches.vue';
import customDatepicker from '@components/customDatepicker.vue';
import 'bootstrap-datepicker';
import 'bootstrap-datepicker/dist/css/bootstrap-datepicker.css';
// import dayjs from 'dayjs';

const dayjs = require('dayjs');
const isoWeek = require('dayjs/plugin/isoWeek');

dayjs.extend(isoWeek);

extend('required', {
    ...required,
    message: 'Field required'
});

extend('number', value => {
    if (Utilities.isNumeric(value)) {
        return true;
    }
    return 'Must be number';
});

extend('integer', {
    ...integer,
    message: 'Must be integer'
});

extend('min_value', {
    ...min_value,
    message: 'Must be greater than 0'
});

extend('max_value', {
    ...max_value,
    message: 'Higher value than allowed'
});

extend('image', {
    ...image,
    message: 'Required an image file'
});

extend('maxGerminatedPlants', {
    validate(value, { sownSeeds }) {

        if (Number(value) <= Number(sownSeeds)) {
            return true;
        }

        return 'Invalid value';
    },
    params: ['sownSeeds']
});

extend('maxPlantsCompartment', {
    validate(value, { plantsInFloor }) {

        if (Number(value) <= Number(plantsInFloor)) {
            return true;
        }

        return 'Invalid value';
    },
    params: ['plantsInFloor']
});

extend('minPlantsInFloor', {
    validate(value, { plantsInCompartment }) {

        if (Number(value) >= Number(plantsInCompartment)) {
            return true;
        }

        return 'Invalid value';
    },
    params: ['plantsInCompartment']
});

export default {
    data() {
        return {
            datePickerLabels: datePickerLabels.es,
            productionLine: ProductionLineEntity.create(),
            breadcrumb: {
                title: 'Production lines',
                path: [
                    {
                        name: 'ProductionLineIndex',
                        text: 'Production lines'
                    }
                ]
            },
            isLoading: false,
            toastTitle: 'Production lines',
            stages: [
                { number: 1, name: 'Planning', ready: false },
                { number: 2, name: 'Sowing', ready: false },
                { number: 3, name: 'Germination', ready: false },
                { number: 4, name: 'Tidal floor', ready: false },
                { number: 5, name: 'Compartment transplant', ready: false },
                { number: 6, name: 'Pollen harvest', ready: false },
                { number: 7, name: 'Emasculation pollination', ready: false },
                { number: 8, name: 'Harvest', ready: false },
                { number: 9, name: 'Extraction', ready: false }
            ],
            planning: {
                male: {
                    parent: null,
                    batch: [],
                    orderDate: null,
                    shippedDate: null,
                    receivedDate: null,
                    releasedDate: null,
                    startSowingDate: null,
                    germinationDate: null,
                    transplantTidalFloorDate: null,
                    transplantingDate: null
                },
                female: {
                    parent: null,
                    batch: [],
                    orderDate: null,
                    shippedDate: null,
                    receivedDate: null,
                    releasedDate: null,
                    startSowingDate: null,
                    germinationDate: null,
                    transplantTidalFloorDate: null,
                    transplantingDate: null,
                    startPollinationDate: null,
                    endPollinationDate: null,
                    startHarvestDate: null,
                    endHarvestDate: null
                }
            },
            sowing: {
                male: {
                    plantsNeeded: null,
                    seedReceived: null,
                    germinationNl: null,
                    germinationRange: null,
                    germinationUsed: null,
                    seedsToSow: null,
                    traysToSow: null,
                    sownSeed: null,
                    traysSown: null,
                    sowingDate: null,
                    realSowingDate: null,
                    remainingSeeds: null,
                    tableNumber: null
                },
                female: {
                    plantsNeeded: null,
                    seedReceived: null,
                    germinationNl: null,
                    germinationRange: null,
                    germinationUsed: null,
                    seedsToSow: null,
                    traysToSow: null,
                    sownSeed: null,
                    traysSown: null,
                    sowingDate: null,
                    realSowingDate: null,
                    remainingSeeds: null,
                    tableNumber: null
                }
            },
            germination: {
                male: {
                    plannedGermination: null,
                    germinatedPlants: null,
                    germinationDate: null,
                    realGerminationDate: null,
                    germinationPercent: null,
                    germinationHistoric: null,
                    sownSeeds:null
                },
                female: {
                    plannedGermination: null,
                    germinatedPlants: null,
                    germinationDate: null,
                    realGerminationDate: null,
                    germinationPercent: null,
                    germinationHistoric: null,
                    sownSeeds: null
                }
            },
            tidalFloor: {
                male: {
                    extraPlantsInFloor: null,
                    plantsInFloorRequired: null,
                    tidalFloorCapacity: null,
                    noTidalFloorsNeeded: [],
                    tidalFloorNumber: null,
                    plantsInFloor: null,
                    transplantToTidalFloorDate: null,
                    realTidalFloorDate: null
                },
                female: {
                    extraPlantsInFloor: null,
                    plantsInFloorRequired: null,
                    tidalFloorCapacity: null,
                    noTidalFloorsNeeded: [],
                    tidalFloorNumber: null,
                    plantsInFloor: null,
                    transplantToTidalFloorDate: null,
                    realTidalFloorDate: null
                }
            },
            compartmentTransplant: {
                male: {
                    plantsInCompartment: null,
                    compartmentTransplantDate: null,
                    realCompartmentTransplantDate: null
                },
                female: {
                    plantsInCompartment: null,
                    compartmentTransplantDate: null,
                    realCompartmentTransplantDate: null
                }
            },
            newPollenHarvestRecollection: {
                id: null,
                harvestDate: null,
                harvestWeek: null,
                totalCollected: null,
                isEditing: false
            },
            newPollenHarvestTube: {
                id: null,
                recollectionId: null,
                harvestDate: null,
                tubeId: null,
                driedMl: 0,
                fromInventory: false,
                comments: null,
                isEditing: false
            },
            cacheProductionLine: {
                planningCache: [],
                sowingCache: [],
                germinationCache: [],
                tidalFloorCache: [],
                compartmentTransplantCache: [],
                pollenHarvestRecollectionCache: [],
                pollenHarvestTubesCache: [],
                extractionCache: []
            },
            editPlanning: false,
            editSowing: false,
            editGermination: false,
            editTidalFloor: false,
            editCompartmentTransplant: false,
            editPollenHarvest: false,
            editPollenHarvestRecollection: false,
            editExtraction: false,
            showFormPollenHarvestTube: false,
            stageData: null,
            url: null,
            recollection: [],
            tubes: [],
            editTubes: false,
            editRecollection: false,
            totalDriedMl: 0,
            sets: [],
            harvestData: [],
            activeTab: 0,
            emasculationPollination: {
                weeks: null,
                startDate: null,
                setNumber: 1,
                sets: [],
                pollinationType: null
            },
            tabData: [],
            showFormSet: false,
            groupedEmasculationPollination: [],
            emasculationPollinationData: [],
            showEditDates: false,
            showCompleteHarvest: true,
            startPollination: null,
            endPollination:null,
            totalCollectedSelected: null,
            recollectionSelected: null,
            extraction: {
                date: null
            },
            showAddSet: true,
            urlImg: getImgUrl(),
            hasCreatePermission: $permission.check('ROLE_PRLI_C'),
            hasCompartmentPermission: $permission.check('ROLE_COMP_L'),
            hasPlanningCreatePermission: $permission.check('ROLE_PRLI_PLAN_C'),
            hasPlanningListPermission: $permission.check('ROLE_PRLI_PLAN_L'),
            hasSowingCreatePermission: $permission.check('ROLE_PRLI_SOWI_C'),
            hasSowingListPermission: $permission.check('ROLE_PRLI_SOWI_L'),
            hasGerminationCreatePermission: $permission.check('ROLE_PRLI_GERM_C'),
            hasGerminationListPermission: $permission.check('ROLE_PRLI_GERM_L'),
            hasTidalFloorCreatePermission: $permission.check('ROLE_PRLI_TIFL_C'),
            hasTidalFloorListPermission: $permission.check('ROLE_PRLI_TIFL_L'),
            hasCompartmentCreatePermission: $permission.check('ROLE_PRLI_COTR_C'),
            hasCompartmentListPermission: $permission.check('ROLE_PRLI_COTR_L'),
            hasPollenHarvestCreatePermission: $permission.check('ROLE_PRLI_POHA_C'),
            hasPollenHarvestListPermission: $permission.check('ROLE_PRLI_POHA_L'),
            hasEmasculationPollCreatePermission: $permission.check('ROLE_PRLI_EMPO_C'),
            hasEmasculationPollListPermission: $permission.check('ROLE_PRLI_EMPO_L'),
            hasHarvestCreatePermission: $permission.check('ROLE_PRLI_HARV_C'),
            hasHarvestListPermission: $permission.check('ROLE_PRLI_HARV_L'),
            hasExtractionCreatePermission: $permission.check('ROLE_PRLI_EXTR_C'),
            hasExtractionListPermission: $permission.check('ROLE_PRLI_EXTR_L'),
            setToActivate: 0
        };
    },
    components: {
        Batches,
        SetComponent,
        HarvestComponent,
        HarvestTomatoComponent,
        customDatepicker
    },
    created() {
        if (this.$route.params.successMessage) {
            this.$bvToast.toast(this.$route.params.successMessage, {
                title: this.toastTitle,
                variant: 'success'
            });
        }

        this.loadData();
    },
    computed: {
        Utilities() {
            return Utilities;
        },
        maleGerminationPercentage() {
            if (!this.germination.male.germinatedPlants || !this.germination.male.sownSeeds) {
                return '';
            }

            return  Number(this.germination.male.germinatedPlants * 100 / this.germination.male.sownSeeds).toFixed(0) + '%';
        },
        femaleGerminationPercentage() {
            if (!this.germination.female.germinatedPlants || !this.germination.female.sownSeeds) {
                return '';
            }

            return  Number(this.germination.female.germinatedPlants * 100 / this.germination.female.sownSeeds).toFixed(0) + '%';
        },
        malePlantsRemaining() {
            if (!this.tidalFloor.male.plantsInFloor || !this.compartmentTransplant.female.plantsInCompartment) {
                return '';
            }
            return Number (this.tidalFloor.male.plantsInFloor - this.compartmentTransplant.male.plantsInCompartment);
        },
        femalePlantsRemaining() {
            if (!this.tidalFloor.female.plantsInFloor || !this.compartmentTransplant.female.plantsInCompartment) {
                return '';
            }
            return Number (this.tidalFloor.female.plantsInFloor - this.compartmentTransplant.female.plantsInCompartment);
        },
        plantsInFloorValidationMale() {
            return this.compartmentTransplant.male.plantsInCompartment
                ? 'required|integer|min_value:1|minPlantsInFloor:'+ this.compartmentTransplant.male.plantsInCompartment
                : 'required|integer|min_value:1';
        },
        plantsInFloorValidationFemale() {
            return this.compartmentTransplant.female.plantsInCompartment
                ? 'required|integer|min_value:1|minPlantsInFloor:'+ this.compartmentTransplant.female.plantsInCompartment
                : 'required|integer|min_value:1';
        },
        planningReadyToComplete() {
            return !this.editPlanning && !this.stages[0].ready && this.planning.female.startSowingDate
                && this.planning.male.startSowingDate && this.hasPlanningCreatePermission && this.productionLine.processStatusId !== 4;
        },
        sowingReadyToComplete() {
            return !this.editSowing && !this.stages[1].ready && this.sowing.female.realSowingDate
                && this.sowing.male.realSowingDate && this.hasSowingCreatePermission && this.productionLine.processStatusId !== 4;
        },
        germinationReadyToComplete() {
            return !this.editGermination && !this.stages[2].ready && this.germination.female.realGerminationDate
                && this.germination.male.realGerminationDate && this.hasGerminationCreatePermission && this.productionLine.processStatusId !== 4;
        },
        tidalFloorReadyToComplete() {
            return !this.editTidalFloor && !this.stages[3].ready && this.tidalFloor.female.realTidalFloorDate
                && this.tidalFloor.male.realTidalFloorDate && this.hasTidalFloorCreatePermission && this.productionLine.processStatusId !== 4;
        },
        compartmentTransplantReadyToComplete() {
            return !this.editCompartmentTransplant && !this.stages[4].ready
                && this.compartmentTransplant.female.realCompartmentTransplantDate
                && this.compartmentTransplant.male.realCompartmentTransplantDate && this.hasCompartmentCreatePermission && this.productionLine.processStatusId !== 4;
        },
        pollenHarvestReadyToComplete() {
            return !this.stages[5].ready && this.recollection.length > 0 && this.stages[4].ready && this.hasPollenHarvestCreatePermission && this.productionLine.processStatusId !== 4;
        },
        emasculationPollinationReadyToComplete() {
            return !this.stages[6].ready && this.productionLine.totalSets > 0 && this.stages[5].ready && this.hasEmasculationPollCreatePermission && this.productionLine.processStatusId !== 4;
        },
        harvestReadyToComplete() {
            return this.showCompleteHarvest && !this.stages[7].ready && this.stages[5].ready && this.hasHarvestCreatePermission && this.productionLine.processStatusId !== 4;
        },
        extractionReadyToComplete() {
            return !this.editExtraction && !this.stages[8].ready && this.stages[7].ready && this.extraction.date && this.hasExtractionCreatePermission && this.productionLine.processStatusId !== 4;
        },
        productionLineReadyToRelease() {
            return this.productionLine.productionLineOccupancy && this.productionLine.processStageTitle === 'Completed' && this.productionLine.processStatusId === 2 && this.productionLine.allStagesCompleted;
        }
    },
    methods: {
        showInfoBreadCrumb() {
            let text;
            text = this.productionLine.varietyName + (this.productionLine.tag ? '-' + this.productionLine.tag : '');

            if (this.breadcrumb.path.length === 1) {
                this.breadcrumb.path.push({
                    name: 'ProductionLineShow',
                    text: `${ text } / Detail`
                });
            }

            this.$emit('update-breadcrumb', this.breadcrumb);
        },
        formatDatepicker(value, ref, flag = null) {
            /*if (value) {
                this.$nextTick(() => {
                    if (flag) {
                        if (this.$refs[ref]) {
                            this.$refs[ref][0].formattedValue = formatDate(value);
                        }
                    } else {
                        this.$refs[ref].formattedValue = formatDate(value);
                    }
                });
            }*/
        },
        loadData(response = null) {
            this.isLoading = true;
            let setIndex = 0;

            this.axios.get(getProductionLineByIdUrl(this.$route.params.id)).then(response => {
                let productionLine = JSON.parse(JSON.stringify(response.data.productionLine));
                this.productionLine = productionLine;

                if (productionLine.processStages) {
                    productionLine.processStages.forEach(item => {
                        switch (item.processStageSequenceId) {
                            case 1:
                                this.stages[0].ready = item.ready;
                                break;
                            case 2:
                                this.stages[1].ready = item.ready;
                                break;
                            case 3:
                                this.stages[2].ready = item.ready;
                                break;
                            case 4:
                                this.stages[3].ready = item.ready;
                                break;
                            case 5:
                                this.stages[4].ready = item.ready;
                                break;
                            case 6:
                                this.stages[5].ready = item.ready;
                                break;
                            case 7:
                                this.stages[6].ready = item.ready;
                                break;
                            case 8:
                                this.stages[7].ready = item.ready;
                                break;
                            case 9:
                                this.stages[8].ready = item.ready;
                                break;
                        }
                    });
                }

                if (productionLine.planningData.female) {
                    this.planning.female = productionLine.planningData.female;
                }

                if (productionLine.planningData.male) {
                    this.planning.male = productionLine.planningData.male;
                }

                if (productionLine.sowingData.female) {
                    this.sowing.female = productionLine.sowingData.female;
                }

                if (productionLine.sowingData.male) {
                    this.sowing.male = productionLine.sowingData.male;
                }

                if (productionLine.germinationData.female) {
                    this.germination.female = productionLine.germinationData.female;
                }

                if (productionLine.germinationData.male) {
                    this.germination.male = productionLine.germinationData.male;
                }

                if (productionLine.tidalFloorData.female) {
                    this.tidalFloor.female = productionLine.tidalFloorData.female;
                }

                if (productionLine.tidalFloorData.male) {
                    this.tidalFloor.male = productionLine.tidalFloorData.male;
                }

                if (productionLine.compartmentTransplantData.female) {
                    this.compartmentTransplant.female = productionLine.compartmentTransplantData.female;
                }

                if (productionLine.compartmentTransplantData.male) {
                    this.compartmentTransplant.male = productionLine.compartmentTransplantData.male;
                }

                if (productionLine.recollectionData) {
                    this.recollection = productionLine.recollectionData;
                }

                if (productionLine.emascullationPollinationData) {
                    this.sets = productionLine.emascullationPollinationData;
                }

                if (productionLine.harvestData) {
                    this.harvestData = productionLine.harvestData;
                }

                if (productionLine.extractionData) {
                    this.extraction = productionLine.extractionData;
                }

                this.showCompleteHarvest = productionLine.harvestShowComplete;
                this.showAddSet = productionLine.showAddSet;

                if (productionLine.startPollination) {
                    this.startPollination = productionLine.startPollination;
                }

                if (productionLine.endPollination) {
                    this.endPollination = productionLine.endPollination;
                }

            }).finally(() => {
                this.isLoading = false;

                this.$nextTick(() => {
                    this.showInfoBreadCrumb();

                    if (response) {
                        this.$bvToast.toast(response.message, {
                            title: this.toastTitle,
                            variant: response.code === 'OK' ? 'success' : 'danger'
                        });
                    }

                    if (this.setToActivate) {
                        setIndex = this.sets.findIndex(set => set.id === this.setToActivate);

                        if (setIndex !== -1) {
                            this.activeTab = setIndex;
                        }
                    }
                });
            });
        },
        scrollView(stage) {
            let reference;

            if (this.productionLine.productionLineOccupancy) {
                switch (stage) {
                    case 1:
                        reference = this.$refs.planningCard;
                        break;
                    case 2:
                        reference = this.$refs.sowingCard;
                        break;
                    case 3:
                        reference = this.$refs.germinationCard;
                        break;
                    case 4:
                        reference = this.$refs.tidalFloorCard;
                        break;
                    case 5:
                        reference = this.$refs.compartmentTransplantCard;
                        break;
                    case 6:
                        reference = this.$refs.pollenHarvestCard;
                        break;
                    case 7:
                        reference = this.$refs.emasculationPollinationCard;
                        break;
                    case 8:
                        reference = this.$refs.harvestCard;
                        break;
                    case 9:
                        reference = this.$refs.extractionCard;
                        break;
                }
            } else {
                switch (stage) {
                    case 1:
                        reference = this.$refs.planningCard;
                        break;
                    case 2:
                        reference = this.$refs.sowingCard;
                        break;
                    case 3:
                        reference = this.$refs.germinationCard;
                        break;
                    case 4:
                        reference = this.$refs.tidalFloorCard;
                        break;
                }
            }

            if (reference) {
                const options = {
                    behavior: 'smooth'
                };

                reference.scrollIntoView(options);
            }
        },
        editStage(stage, action, item = null) {
            switch (stage) {
                case 1:
                    this.editPlanning = !this.editPlanning;

                    if (action === 'EDIT') {
                        this.cacheProductionLine.planningCache = JSON.parse(JSON.stringify(this.planning));

                        this.$nextTick(() => {
                            this.formatDatepicker(this.planning.male.shippedDate, 'maleShippedDate');
                            this.formatDatepicker(this.planning.male.receivedDate, 'maleReceivedDate');
                            this.formatDatepicker(this.planning.male.releasedDate, 'maleReleasedDate');
                            this.formatDatepicker(this.planning.male.startSowingDate, 'maleStartSowingDate');
                            this.formatDatepicker(this.planning.male.germinationDate, 'maleGerminationDate');
                            this.formatDatepicker(this.planning.male.transplantTidalFloorDate, 'maleTransplantTidalFloorDate');
                            this.formatDatepicker(this.planning.male.transplantingDate, 'maleCompartmentTransplantDate');
                            this.formatDatepicker(this.planning.female.shippedDate, 'femaleShippedDate');
                            this.formatDatepicker(this.planning.female.receivedDate, 'femaleReceivedDate');
                            this.formatDatepicker(this.planning.female.releasedDate, 'femaleReleasedDate');
                            this.formatDatepicker(this.planning.female.startSowingDate, 'femaleStartSowingDate');
                            this.formatDatepicker(this.planning.female.germinationDate, 'femaleGerminationDate');
                            this.formatDatepicker(this.planning.female.transplantTidalFloorDate, 'femaleTransplantTidalFloorDate');
                            this.formatDatepicker(this.planning.female.transplantingDate, 'femaleCompartmentTransplantDate');
                            this.formatDatepicker(this.planning.female.startPollinationDate, 'femaleStartPollinationDate');
                            this.formatDatepicker(this.planning.female.endPollinationDate, 'femaleEndPollinationDate');
                            this.formatDatepicker(this.planning.female.startHarvestDate, 'femaleStartHarvestDate');
                            this.formatDatepicker(this.planning.female.endHarvestDate, 'femaleEndHarvestDate');
                        });
                    } else if (action === 'CANCEL') {
                        this.planning = JSON.parse(JSON.stringify(this.cacheProductionLine.planningCache));
                        this.cacheProductionLine.planningCache = [];

                        if (this.$refs.planningForm) {
                            requestAnimationFrame(() => {
                                this.$refs.planningForm.reset();
                            });
                        }
                    }

                    break;
                case 2:
                    this.editSowing = !this.editSowing;

                    if (action === 'EDIT') {
                        this.cacheProductionLine.sowingCache = JSON.parse(JSON.stringify(this.sowing));
                        this.sowing.female.realSowingDate = this.sowing.female.realSowingDate ? this.sowing.female.realSowingDate : this.sowing.female.sowingDate;
                        this.sowing.male.realSowingDate = this.sowing.male.realSowingDate ? this.sowing.male.realSowingDate : this.sowing.male.sowingDate;

                        this.$nextTick(() => {
                            this.formatDatepicker(this.sowing.female.realSowingDate, 'femaleRealSowingDate');
                            this.formatDatepicker(this.sowing.male.realSowingDate, 'maleRealSowingDate');
                        });
                    } else if (action === 'CANCEL') {
                        this.sowing = JSON.parse(JSON.stringify(this.cacheProductionLine.sowingCache));
                        this.cacheProductionLine.sowingCache = [];
                    }

                    break;
                case 3:
                    this.editGermination = !this.editGermination;

                    if (action === 'EDIT') {
                        this.cacheProductionLine.germinationCache = JSON.parse(JSON.stringify(this.germination));
                        this.germination.female.realGerminationDate = this.germination.female.realGerminationDate ? this.germination.female.realGerminationDate : this.germination.female.germinationDate;
                        this.germination.male.realGerminationDate = this.germination.male.realGerminationDate ? this.germination.male.realGerminationDate : this.germination.male.germinationDate;

                        this.$nextTick(() => {
                            this.formatDatepicker(this.germination.female.realGerminationDate, 'femaleRealGerminationDate');
                            this.formatDatepicker(this.germination.male.realGerminationDate, 'maleRealGerminationDate');
                        });
                    } else if (action === 'CANCEL') {
                        this.germination = JSON.parse(JSON.stringify(this.cacheProductionLine.germinationCache));
                        this.cacheProductionLine.germinationCache = [];
                    }

                    break;
                case 4:
                    this.editTidalFloor = !this.editTidalFloor;

                    if (action === 'EDIT') {
                        this.cacheProductionLine.tidalFloorCache = JSON.parse(JSON.stringify(this.tidalFloor));
                        this.tidalFloor.female.realTidalFloorDate = this.tidalFloor.female.realTidalFloorDate ? this.tidalFloor.female.realTidalFloorDate : this.tidalFloor.female.transplantToTidalFloorDate;
                        this.tidalFloor.male.realTidalFloorDate = this.tidalFloor.male.realTidalFloorDate ? this.tidalFloor.male.realTidalFloorDate : this.tidalFloor.male.transplantToTidalFloorDate;

                        this.$nextTick(() => {
                            this.formatDatepicker(this.tidalFloor.female.realTidalFloorDate, 'femaleRealTidalFloorDate');
                            this.formatDatepicker(this.tidalFloor.male.realTidalFloorDate, 'maleRealTidalFloorDate');
                        });
                    } else if (action === 'CANCEL') {
                        this.tidalFloor = JSON.parse(JSON.stringify(this.cacheProductionLine.tidalFloorCache));
                        this.cacheProductionLine.tidalFloorCache = [];
                    }

                    break;
                case 5:
                    this.editCompartmentTransplant = !this.editCompartmentTransplant;

                    if (action === 'EDIT') {
                        this.cacheProductionLine.compartmentTransplantCache = JSON.parse(JSON.stringify(this.compartmentTransplant));
                        this.compartmentTransplant.female.realCompartmentTransplantDate = this.compartmentTransplant.female.realCompartmentTransplantDate ? this.compartmentTransplant.female.realCompartmentTransplantDate : this.compartmentTransplant.female.compartmentTransplantDate;
                        this.compartmentTransplant.male.realCompartmentTransplantDate = this.compartmentTransplant.male.realCompartmentTransplantDate ? this.compartmentTransplant.male.realCompartmentTransplantDate : this.compartmentTransplant.male.compartmentTransplantDate;

                        this.$nextTick(() => {
                            this.formatDatepicker(this.compartmentTransplant.male.realCompartmentTransplantDate, 'maleRealCompartmentTransplantDate');
                            this.formatDatepicker(this.compartmentTransplant.female.realCompartmentTransplantDate, 'femaleRealCompartmentTransplantDate');
                        });
                    } else if (action === 'CANCEL') {
                        this.compartmentTransplant = JSON.parse(JSON.stringify(this.cacheProductionLine.compartmentTransplantCache));
                        this.cacheProductionLine.compartmentTransplantCache = [];
                    }

                    break;
                case 6:
                    if (action === 'EDIT') {
                        this.cacheProductionLine.pollenHarvestRecollectionCache.push(JSON.parse(JSON.stringify(item)));
                        item.isEditing = true;
                        this.$nextTick(() => {
                            this.formatDatepicker(item.harvestDate, 'recollectionHarvestDate' + item.id, true);
                        });
                    } else if (action === 'CANCEL_EDIT_ITEM') {
                        let idx = this.cacheProductionLine.pollenHarvestRecollectionCache.findIndex(recollection => recollection.id === item.id);
                        let recollectionIdx = this.recollection.findIndex(recollection => recollection.id === item.id);

                        if (idx !== -1 && recollectionIdx !== -1) {
                            this.recollection[recollectionIdx].harvestDate = this.cacheProductionLine.pollenHarvestRecollectionCache[idx].harvestDate;
                            this.recollection[recollectionIdx].totalCollected = this.cacheProductionLine.pollenHarvestRecollectionCache[idx].totalCollected;
                            this.recollection[recollectionIdx].harvestWeek = this.cacheProductionLine.pollenHarvestRecollectionCache[idx].harvestWeek;
                            this.recollection[recollectionIdx].isEditing = false;
                            this.cacheProductionLine.pollenHarvestRecollectionCache.splice(idx, 1);
                        }

                        this.editRecollection = false;
                    }
                    break;
                case 7:
                    if (action === 'EDIT') {
                        this.cacheProductionLine.pollenHarvestTubesCache.push(JSON.parse(JSON.stringify(item)));
                        item.isEditing = true;
                    } else if (action === 'CANCEL_EDIT_ITEM') {
                        let idx = this.cacheProductionLine.pollenHarvestTubesCache.findIndex(tube => tube.id === item.id);
                        let tubeIdx = this.tubes.findIndex(tube => tube.id === item.id);

                        if (idx !== -1 && tubeIdx !== -1) {
                            this.tubes[tubeIdx].harvestDate = this.cacheProductionLine.pollenHarvestTubesCache[idx].harvestDate;
                            this.tubes[tubeIdx].tubeId = this.cacheProductionLine.pollenHarvestTubesCache[idx].tubeId;
                            this.tubes[tubeIdx].driedMl = this.cacheProductionLine.pollenHarvestTubesCache[idx].driedMl;
                            this.tubes[tubeIdx].comments = this.cacheProductionLine.pollenHarvestTubesCache[idx].comments;
                            this.tubes[tubeIdx].fromInventory = this.cacheProductionLine.pollenHarvestTubesCache[idx].fromInventory;
                            this.tubes[tubeIdx].isEditing = false;
                            this.cacheProductionLine.pollenHarvestTubesCache.splice(idx, 1);
                        }
                        item.isEditing = false;
                        this.editTubes = false;

                        if (this.$refs.formTubes) {
                            requestAnimationFrame(() => {
                                this.$refs.formTubes.reset();
                            });
                        }
                    }
                    break;
                case 8:
                    this.editExtraction = !this.editExtraction;

                    if (action === 'EDIT') {
                        this.cacheProductionLine.extractionCache = JSON.parse(JSON.stringify(this.extraction));

                        this.$nextTick(() => {
                            this.formatDatepicker(this.extraction.date, 'extractionDate');
                        });
                    } else if (action === 'CANCEL') {
                        this.extraction = JSON.parse(JSON.stringify(this.cacheProductionLine.extractionCache));
                        this.cacheProductionLine.extractionCache = [];
                    }

                    break;
            }
        },
        savePlanning() {
            this.isLoading = true;

            this.axios.post(updatePlanningUrl(), {
                planningData: this.planning,
                productionLineId: this.productionLine.id
            }).then(response => {
                if (response.status === 200) {
                    this.$bvToast.toast(response.data.message, {
                        title: this.toastTitle,
                        variant: 'success'
                    });

                    this.editPlanning = !this.editPlanning;
                    this.$nextTick(() => {
                        this.loadData();
                    });
                } else {
                    this.$bvToast.toast(response.data.message, {
                        title: this.toastTitle,
                        variant: 'danger'
                    });
                }
            }).catch(error => {
                if (error.response) {
                    this.$bvToast.toast(error.response.data.message, {
                        title: this.toastTitle,
                        variant: 'danger'
                    });
                } else if (error.request) {
                    this.$bvToast.toast('An error occurred while executing the request', {
                        title: this.toastTitle,
                        variant: 'danger'
                    });
                } else {
                    this.$bvToast.toast('An error occurred while configuring the request', {
                        title: this.toastTitle,
                        variant: 'danger'
                    });
                }
            }).finally(() => {
                this.isLoading = false;
            });
        },
        saveSowing() {
            this.isLoading = true;

            this.axios.post(updateSowingUrl(), {
                stageData: this.sowing,
                productionLineId: this.productionLine.id
            }).then(response => {
                if (response.status === 200) {
                    this.$bvToast.toast(response.data.message, {
                        title: this.toastTitle,
                        variant: 'success'
                    });

                    this.editSowing = !this.editSowing;
                    this.$nextTick(() => {
                        this.loadData();
                    });
                } else {
                    this.$bvToast.toast(response.data.message, {
                        title: this.toastTitle,
                        variant: 'danger'
                    });
                }
            }).catch(error => {
                if (error.response) {
                    this.$bvToast.toast(error.response.data.message, {
                        title: this.toastTitle,
                        variant: 'danger'
                    });
                } else if (error.request) {
                    this.$bvToast.toast('An error occurred while executing the request', {
                        title: this.toastTitle,
                        variant: 'danger'
                    });
                } else {
                    this.$bvToast.toast('An error occurred while configuring the request', {
                        title: this.toastTitle,
                        variant: 'danger'
                    });
                }
            }).finally(() => {
                this.isLoading = false;
            });
        },
        saveStageData() {
            this.isLoading = true;
            let index, indexCache;

            this.axios.post(this.url, {
                stageData: this.stageData,
                productionLineId: this.productionLine.id
            }).then(response => {
                if (response.status === 200) {
                    this.$bvToast.toast(response.data.message, {
                        title: this.toastTitle,
                        variant: response.data.code === 'OK' ? 'success' : 'danger'
                    });

                    let stage = response.data.stage;
                    let recollection = response.data.recollection;
                    let action = response.data.action;
                    let tube;

                    switch (stage) {
                        case 1:
                            this.editPlanning = !this.editPlanning;

                            break;
                        case 2:
                            this.editSowing = !this.editSowing;

                            break;
                        case 3:
                            this.editGermination = !this.editGermination;
                            this.$nextTick(() => {
                                this.loadData();
                            });

                            break;
                        case 4:
                            this.editTidalFloor = !this.editTidalFloor;
                            this.$nextTick(() => {
                                this.loadData();
                            });

                            break;
                        case 5:
                            this.editCompartmentTransplant = !this.editCompartmentTransplant;
                            this.$nextTick(() => {
                                this.loadData();
                            });

                            break;
                        case 6:
                            if (action === 'created') {
                                this.$nextTick(() => {
                                    this.loadData();
                                });

                                this.cacheProductionLine.pollenHarvestRecollectionCache = [];
                            }

                            index = this.recollection.findIndex(item => item.id === recollection.id);
                            indexCache = this.cacheProductionLine.pollenHarvestRecollectionCache.findIndex(item => item.id === recollection.id);

                            if (index !== -1) {
                                this.recollection[index].isEditing = false;
                                this.recollection[index].id = recollection.id;
                                this.recollection[index].recollectionId = recollection.recollectionId;
                                this.recollection[index].tubeId = recollection.tubeId;
                                this.recollection[index].driedMl = recollection.driedMl;
                                this.recollection[index].harvestDate = recollection.harvestDate;
                                this.recollection[index].totalCollected = recollection.totalCollected;
                                this.recollection[index].tubes = recollection.tubes;
                            }

                            this.newPollenHarvestRecollection.id = null;
                            this.newPollenHarvestRecollection.harvestDate = null;
                            this.newPollenHarvestRecollection.harvestWeek = null;
                            this.newPollenHarvestRecollection.totalCollected = null;

                            if (this.$refs.formRecollection) {
                                requestAnimationFrame(() => {
                                    this.$refs.formRecollection.reset();
                                });
                            }

                            if (indexCache !== -1) {
                                this.cacheProductionLine.pollenHarvestRecollectionCache.splice(indexCache, 1);
                            }

                            break;
                        case 7:
                            tube = response.data.tube;

                            if (action === 'created') {
                                this.$nextTick(() => {
                                    this.loadData();
                                });
                                this.cacheProductionLine.pollenHarvestTubesCache = [];
                                this.tubes = response.data.tubes;
                                this.newPollenHarvestTube.id = null;
                                this.newPollenHarvestTube.tubeId = 'TUBE';
                                this.newPollenHarvestTube.driedMl = null;
                                this.newPollenHarvestTube.fromInventory = false;
                                this.newPollenHarvestTube.comments = null;

                                if (this.$refs.formTubes) {
                                    requestAnimationFrame(() => {
                                        this.$refs.formTubes.reset();
                                    });
                                }

                                this.editTubes = false;
                            } else {
                                index = this.tubes.findIndex(item => item.id === tube.id);
                                indexCache = this.cacheProductionLine.pollenHarvestTubesCache.findIndex(item => item.id === tube.id);

                                if (index !== -1) {
                                    this.tubes[index].id = tube.id;
                                    this.tubes[index].recollectionId = tube.recollectionId;
                                    this.tubes[index].tubeId = tube.tubeId;
                                    this.tubes[index].harvestDate = tube.harvestDate;
                                    this.tubes[index].driedMl = tube.driedMl;
                                    this.tubes[index].fromInventory = tube.fromInventory;
                                    this.tubes[index].isEditing = false;
                                }

                                if (indexCache !== -1) {
                                    this.cacheProductionLine.pollenHarvestTubesCache.splice(indexCache, 1);
                                }
                            }

                            break;
                        case 11:
                            this.$nextTick(() => {
                                this.loadData();
                            });

                            this.setToActivate = response.data.set.id;

                            this.showEditDates = false;

                            if (this.productionLine.cropFamilyName !== 'Cucumber' && response.data.action === 'updated') {
                                let setIndex = this.$refs.setComponent.findIndex(set => set.setId === response.data.set.id);
                                this.$refs.setComponent[setIndex].showInputAddWeeks = false;
                            }

                            break;
                        case 12:
                            this.editExtraction = !this.editExtraction;
                            this.$nextTick(() => {
                                this.loadData();
                            });

                            break;
                    }
                } else {
                    this.$bvToast.toast(response.data.message, {
                        title: this.toastTitle,
                        variant: 'danger'
                    });
                }
            }).catch(error => {
                if (error.response) {
                    this.$bvToast.toast(error.response.data.message, {
                        title: this.toastTitle,
                        variant: 'danger'
                    });
                } else if (error.request) {
                    this.$bvToast.toast('An error occurred while executing the request', {
                        title: this.toastTitle,
                        variant: 'danger'
                    });
                } else {
                    this.$bvToast.toast('An error occurred while configuring the request', {
                        title: this.toastTitle,
                        variant: 'danger'
                    });
                }
            }).finally(() => {
                this.isLoading = false;
            });
        },
        setData(stage, item = null) {
            switch (stage) {
                case 1:
                    this.editPlanning = !this.editPlanning;
                    break;
                case 2:
                    this.editSowing = !this.editSowing;
                    break;
                case 3:
                    this.url = updateGerminationUrl();
                    this.germination.male.sownSeeds = this.sowing.male.sownSeed ?? 0;
                    this.germination.female.sownSeeds = this.sowing.female.sownSeed ?? 0;
                    this.stageData = this.germination;
                    break;
                case 4:
                    this.url = updateTidalFloorUrl();
                    this.stageData = this.tidalFloor;
                    break;
                case 5:
                    this.url = updateCompartmentTransplantUrl();
                    this.stageData = this.compartmentTransplant;
                    break;
                case 6:
                    this.url = savePollenHarvestRecollectionUrl();
                    this.editRecollection = false;
                    this.stageData = this.newPollenHarvestRecollection;
                    break;
                case 7:
                    this.url = savePollenHarvestTubesUrl();
                    this.editTubes = false;
                    this.stageData = this.newPollenHarvestTube;
                    break;
                case 8:
                    this.editTubes = true;
                    this.url = savePollenHarvestTubesUrl();
                    this.stageData = item;
                    break;
                case 9:
                    this.editRecollection = true;
                    this.url = savePollenHarvestRecollectionUrl();
                    this.stageData = item;
                    break;
                case 10:
                    this.url = createSetUrl();
                    this.stageData = this.emasculationPollination;
                    break;
                case 11:
                    this.url = createSetUrl();
                    this.stageData = item;
                    break;
                case 12:
                    this.url = saveExtractionUrl();
                    this.stageData = this.extraction;
                    break;
            }
        },
        completeProcess(stage, number) {
            this.$swal({
                title: 'Complete stage',
                icon: 'success',
                iconColor: '#97bf0d',
                text: 'Are you sure you want to mark as complete '+ stage +' stage?',
                cancelButtonText: 'Cancel',
                confirmButtonColor: '#97bf0d',
                confirmButtonText: 'Yes'
            }).then(result => {
                if (result.value) {
                    this.axios.post(updateStageUrl(), {
                        productionLineId: this.productionLine.id,
                        stageProcessId: number,
                        ready: 1
                    }).then(response => {
                        let processStageSequenceId = response.data.processStageSequenceId;
                        this.productionLine.processStageTitle = response.data.processStageTitle;

                        this.stages.forEach(stage => {
                            if (stage.number === processStageSequenceId) {
                                stage.ready = true;
                            }
                        });

                        if (response.status === 200) {
                            this.$bvToast.toast(response.data.message, {
                                title: this.toastTitle,
                                variant: 'success'
                            });
                        } else {
                            this.$bvToast.toast(response.data.message, {
                                title: this.toastTitle,
                                variant: 'danger'
                            });
                        }
                    }).catch(error => {
                        if (error.response) {
                            this.$bvToast.toast(error.response.data.message, {
                                title: this.toastTitle,
                                variant: 'danger'
                            });
                        } else if (error.request) {
                            this.$bvToast.toast('An error occurred while executing the request', {
                                title: this.toastTitle,
                                variant: 'danger'
                            });
                        } else {
                            this.$bvToast.toast('An error occurred while configuring the request', {
                                title: this.toastTitle,
                                variant: 'danger'
                            });
                        }
                    }).finally(() => {
                        this.isLoading = false;
                    });
                }
            });
        },
        formatDate(date) {
            let dateFormat;

            if (date) {
                dateFormat = dayjs(date.toString(), 'DD/MM/YYYY');
            }

            return date ? dateFormat.format('DD/MM/YYYY') : '';
        },
        formatPercentage(value) {
            return value ? value + '%' : '';
        },
        deletePollenHarvest(id, type) {
            let url, element;

            if (type === 1) {
                url = deletePollenHarvestRecollectionUrl();
                element = 'recollection';
            } else if (type === 2) {
                url = deletePollenHarvestTubeUrl();
                element = 'tube';
            }

            this.$swal({
                title: `Delete ${element}`,
                icon: 'warning',
                iconColor: '#bf0d0d',
                text: `Are you sure you want to delete this ${element}?`,
                cancelButtonText: 'Cancel',
                confirmButtonColor: '#bf0d0d',
                confirmButtonText: 'Yes'
            }).then(result => {
                if (result.value) {
                    this.axios.post(url, {
                        id: id
                    }).then(response => {
                        if (response.status === 200) {
                            this.$bvToast.toast(response.data.message, {
                                title: this.toastTitle,
                                variant: 'success'
                            });

                            let responseData = response.data;

                            // todo revisar si esto funciona, con la recarga de loadData parece no ser necesario (al menos para recollections)
                            if (responseData) {
                                if (responseData.type === 'recollection') {
                                    this.recollection = JSON.parse(JSON.stringify(responseData.recollection));
                                } else if (responseData.type === 'tube') {
                                    this.tubes = JSON.parse(JSON.stringify(responseData.tubes));
                                }
                            }

                            this.$nextTick(() => {
                                this.loadData();
                            });
                        } else {
                            this.$bvToast.toast(response.data.message, {
                                title: this.toastTitle,
                                variant: 'danger'
                            });
                        }
                    }).catch(error => {
                        if (error.response) {
                            this.$bvToast.toast(error.response.data.message, {
                                title: this.toastTitle,
                                variant: 'danger'
                            });
                        } else if (error.request) {
                            this.$bvToast.toast('An error occurred while executing the request', {
                                title: this.toastTitle,
                                variant: 'danger'
                            });
                        } else {
                            this.$bvToast.toast('An error occurred while configuring the request', {
                                title: this.toastTitle,
                                variant: 'danger'
                            });
                        }
                    }).finally(() => {
                        this.isLoading = false;
                    });
                }
            });
        },
        showTubesTable(item) {
            this.loadData();
            this.newPollenHarvestTube = {
                id: null,
                recollectionId: item.id,
                harvestDate: item.harvestDate,
                tubeId: null,
                driedMl: null,
                fromInventory: false,
                comments: null
            };

            this.recollectionSelected = item;

            this.totalCollectedSelected = item.totalCollected;

            this.axios.get(getTubesByRecollectionIdUrl(item.id)).then(response => {
                if (response.status === 200) {
                    this.tubes = response.data.tubes;
                    this.newPollenHarvestTube.tubeId = 'TUBE';
                } else {
                    this.$bvToast.toast(response.data.message, {
                        title: this.toastTitle,
                        variant: 'danger'
                    });
                }
            }).catch(error => {
                if (error.response) {
                    this.$bvToast.toast(error.response.data.message, {
                        title: this.toastTitle,
                        variant: 'danger'
                    });
                } else if (error.request) {
                    this.$bvToast.toast('An error occurred while executing the request', {
                        title: this.toastTitle,
                        variant: 'danger'
                    });
                } else {
                    this.$bvToast.toast('An error occurred while configuring the request', {
                        title: this.toastTitle,
                        variant: 'danger'
                    });
                }
            }).finally(() => {
                this.isLoading = false;
            });

            this.$bvModal.show('tubes-modal');
        },
        cancelProductionLine() {
            this.$swal({
                title: 'Cancel production line',
                icon: 'warning',
                text: 'Are you sure you want to cancel this production line?',
                cancelButtonText: 'No',
                confirmButtonText: 'Yes',
                iconColor: '#bf0d0d',
                confirmButtonColor: '#bf0d0d'
            }).then(result => {
                if (result.value) {
                    this.axios.post(productionLineCancelUrl(this.productionLine.id)).then(response => {
                        if (response.status === 200) {
                            this.$bvToast.toast(response.data.message, {
                                title: this.toastTitle,
                                variant: 'success'
                            });

                            this.$nextTick(() => {
                                this.loadData();
                            });
                        } else {
                            this.$bvToast.toast(response.data.message, {
                                title: this.toastTitle,
                                variant: 'danger'
                            });
                        }
                    }).catch(error => {
                        if (error.response) {
                            this.$bvToast.toast(error.response.data.message, {
                                title: this.toastTitle,
                                variant: 'danger'
                            });
                        } else if (error.request) {
                            this.$bvToast.toast('An error occurred while executing the request', {
                                title: this.toastTitle,
                                variant: 'danger'
                            });
                        } else {
                            this.$bvToast.toast('An error occurred while configuring the request', {
                                title: this.toastTitle,
                                variant: 'danger'
                            });
                        }
                    }).finally(() => {
                        this.isLoading = false;
                    });
                }
            });
        },
        releaseCompartment() {
            this.$swal({
                title: 'Release compartment space?',
                icon: 'warning',
                text: '',
                cancelButtonText: 'No',
                confirmButtonText: 'Yes',
                confirmButtonColor: '#97bf0d'
            }).then(result => {
                if (result.value) {
                    this.axios.get(productionLineReleaseCompartmentUrl(this.productionLine.id)).then(response => {
                        if (response.status === 200) {
                            this.$bvToast.toast(response.data.message, {
                                title: this.toastTitle,
                                variant: 'success'
                            });

                            this.$nextTick(() => {
                                this.loadData();
                            });
                        } else {
                            this.$bvToast.toast(response.data.message, {
                                title: this.toastTitle,
                                variant: 'danger'
                            });
                        }
                    }).catch(error => {
                        if (error.response) {
                            this.$bvToast.toast(error.response.data.message, {
                                title: this.toastTitle,
                                variant: 'danger'
                            });
                        } else if (error.request) {
                            this.$bvToast.toast('An error occurred while executing the request', {
                                title: this.toastTitle,
                                variant: 'danger'
                            });
                        } else {
                            this.$bvToast.toast('An error occurred while configuring the request', {
                                title: this.toastTitle,
                                variant: 'danger'
                            });
                        }
                    }).finally(() => {
                        this.isLoading = false;
                    });
                }
            });
        },
        closeTubesModal() {
            this.loadData();
            this.$bvModal.hide('tubes-modal');
        },
        showFormSetData() {
            const newTab = {
                action: 'create',
                id: null,
                setNumber: null,
                startDate: null,
                weeks: null
            };
            this.tabData.push(newTab);
            this.sets.push(newTab);
            this.$forceUpdate();

            this.$nextTick(() => {
                setTimeout(() => { this.activeTab = this.sets.length - 1;}, 100);
            });
        },
        getWeek(harvestDate, action, item = null) {
            if (!harvestDate) {
                return '';
            }
            if (action === 'create') {
                this.newPollenHarvestRecollection.harvestWeek = dayjs(harvestDate).isoWeek();
            } else {
                item.harvestWeek = dayjs(harvestDate).isoWeek();
            }
            return dayjs(harvestDate).isoWeek();
        },
        showEditView() {
            this.showEditDates = !this.showEditDates;
        },
        updateTotalMl() {
            let total = this.tubes.reduce((total, obj) => Number(total) + Number(obj.driedMl), 0);
            total += Number(this.newPollenHarvestTube.driedMl);

            if (isNaN(total)) {
                this.totalDriedMl = null;
            } else {
                this.totalDriedMl = total.toFixed(2);

                if (this.totalDriedMl.endsWith('00')) {
                    this.totalDriedMl = this.totalDriedMl.slice(0, -1);
                } else if (this.totalDriedMl.endsWith('0')) {
                    this.totalDriedMl = this.totalDriedMl.slice(0, -1);
                }
            }
        },
        showCursorStage(number) {
            let style = '';

            if (!this.productionLine.productionLineOccupancy) {
                if (number < 5) {
                    style = 'cursor: pointer';
                } else {
                    style = 'cursor: not-allowed';
                }
            } else {
                style = 'cursor: pointer';
            }

            return style;
        }
    },
    watch: {
        tubes: {
            deep: true,
            handler() {
                this.updateTotalMl();
            }
        },
        newPollenHarvestTube: {
            deep: true,
            handler() {
                this.updateTotalMl();
            }
        },
        'planning.male.startSowingDate': function(newDate) {
            if (newDate && this.editPlanning) {
                if (this.productionLine.cropFamilyName === 'Tomato') {
                    this.planning.female.startSowingDate = dayjs(newDate).add(14, 'day').format('YYYY-MM-DD');
                    this.planning.male.transplantingDate = dayjs(newDate).add(30, 'day').format('YYYY-MM-DD');
                } else if (this.productionLine.cropFamilyName === 'Pepper') {
                    this.planning.female.startSowingDate = dayjs(newDate).add(14, 'day').format('YYYY-MM-DD');
                    this.planning.male.transplantingDate = dayjs(newDate).add(38, 'day').format('YYYY-MM-DD');
                } else if (this.productionLine.cropFamilyName === 'Cucumber') {
                    this.planning.female.startSowingDate = dayjs(newDate).add(21, 'day').format('YYYY-MM-DD');
                    this.planning.male.transplantingDate = dayjs(newDate).add(30, 'day').format('YYYY-MM-DD');
                } else if (this.productionLine.cropFamilyName === 'Aubergine') {
                    this.planning.female.startSowingDate = dayjs(newDate).add(14, 'day').format('YYYY-MM-DD');
                    this.planning.male.transplantingDate = dayjs(newDate).add(37, 'day').format('YYYY-MM-DD');
                }

                this.formatDatepicker(this.planning.female.startSowingDate, 'femaleStartSowingDate');
                this.formatDatepicker(this.planning.male.transplantingDate, 'maleCompartmentTransplantDate');
            }
        },
        'planning.female.startSowingDate': function(newDate) {
            if (newDate && this.editPlanning) {
                if (this.productionLine.cropFamilyName === 'Tomato') {
                    this.planning.female.transplantingDate = dayjs(newDate).add(30, 'day').format('YYYY-MM-DD');
                } else if (this.productionLine.cropFamilyName === 'Pepper') {
                    this.planning.female.transplantingDate = dayjs(newDate).add(38, 'day').format('YYYY-MM-DD');
                } else if (this.productionLine.cropFamilyName === 'Cucumber') {
                    this.planning.female.transplantingDate = dayjs(newDate).add(30, 'day').format('YYYY-MM-DD');
                } else if (this.productionLine.cropFamilyName === 'Aubergine') {
                    this.planning.female.transplantingDate = dayjs(newDate).add(39, 'day').format('YYYY-MM-DD');
                }

                this.formatDatepicker(this.planning.female.transplantingDate, 'femaleCompartmentTransplantDate');
            }
        },
        'planning.female.transplantingDate': function(newDate) {
            if (newDate && this.editPlanning) {
                if (this.productionLine.cropFamilyName === 'Tomato') {
                    this.planning.female.startPollinationDate = dayjs(newDate).add(28, 'day').format('YYYY-MM-DD');
                    this.planning.female.startHarvestDate = dayjs(newDate).add(56, 'day').format('YYYY-MM-DD');
                } else if (this.productionLine.cropFamilyName === 'Pepper') {
                    this.planning.female.startPollinationDate = dayjs(newDate).add(21, 'day').format('YYYY-MM-DD');

                } else if (this.productionLine.cropFamilyName === 'Cucumber') {
                    this.planning.female.startPollinationDate = dayjs(newDate).add(21, 'day').format('YYYY-MM-DD');

                } else if (this.productionLine.cropFamilyName === 'Aubergine') {
                    this.planning.female.startPollinationDate = dayjs(newDate).add(20, 'day').format('YYYY-MM-DD');
                }

                this.formatDatepicker(this.planning.female.startPollinationDate, 'femaleStartPollinationDate');
                this.formatDatepicker(this.planning.female.startHarvestDate, 'femaleStartHarvestDate');
            }
        },
        'planning.female.startPollinationDate': function(newDate) {
            if (newDate && this.editPlanning) {
                if (this.productionLine.cropFamilyName === 'Pepper') {
                    this.planning.female.startHarvestDate = dayjs(newDate).add(65, 'day').format('YYYY-MM-DD');
                } else if (this.productionLine.cropFamilyName === 'Cucumber') {
                    this.planning.female.startHarvestDate = dayjs(newDate).add(40, 'day').format('YYYY-MM-DD');
                } else if (this.productionLine.cropFamilyName === 'Aubergine') {
                    this.planning.female.startHarvestDate = dayjs(newDate).add(86, 'day').format('YYYY-MM-DD');
                }
                
                this.formatDatepicker(this.planning.female.startHarvestDate, 'femaleStartHarvestDate');
            }
        }
    }
};
</script>

<style>
.timeline {
    display: flex;
    justify-content: center;
}

.stage {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0 20px;
    position: relative;
    width: 6%;
}

.circle {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background-color: #adadad;
    color: #ffffff;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 16px;
    z-index: 1;
    cursor: pointer;
}

.circle--complete {
    background-color: var(--color-primary);
}

.name {
    margin-top: 10px;
    text-align: center;
}

.name--complete {
    color: var(--color-secondary);
}

.parent-icon {
    width: 25px;
    height: 25px;
    color: #4966a2;
}

.parent-icon--female {
    color: #942576;
}

.title {
    color: var(--color-secondary);
    margin-bottom: 0;
}

.subtitle {
    font-size: 18px;
    margin-bottom: 0;
    color: var(--color-secondary);
    font-weight: 500;
}

.variety-title {
    margin-bottom: 0;
    font-weight: bold;
}

.subtitle-kg {
    font-size: 16px;
    font-weight: bold;
    margin-bottom: 3px;
}

.process-stage-label {
    background-color: var(--color-secondary);
    color: #fff;
    border-radius: 1em;
    padding: 6px 12px;
    margin-right: 4px;
    margin-bottom: 4px;
}

.cancelled {
    background-color: #bf0d0d;
}

.square {
    margin-top: 4px;
    background-color: var(--color-secondary);
    color: #000;
    border-radius: 1em;
    padding: 6px 12px;
    margin-right: 4px;
    margin-bottom: 4px;
    flex: 0 1 50%;
    outline: 1px solid #ccc;
}

.title-harvest-date {
    font-size: 16px;
    font-weight: 500;
}

.icon-check {
    color: var(--color-primary)
}
/*.line {*/
/*    position: absolute;*/
/*    top: 23%;*/
/*    left: -40px;*/
/*    height: 2px;*/
/*    background-color: var(--color-primary);*/
/*    width: calc(100% + 40px);*/
/*    !*z-index: -1;*!*/
/*}*/

.planned-date, .planned-date .b-form-btn-label-control.form-control > .form-control {
    color: rgb(196, 49, 49);
}

.real-date .b-form-btn-label-control.form-control > .form-control {
    color: rgb(0, 109, 123);
}

.alert, .alert-warning {
    border-left: 1px solid #cbd1d5 !important;
}
</style>