<template>
	<div style="width: 100%;">
		<select ref="selectElement" class="custom-select" style="width: 100%" ></select>
		<div v-if="state === false" class="invalid-value">{{ errorMessage }}</div>
	</div>
</template>

<script>
import $ from 'jquery';
import 'select2/dist/css/select2.css';
import 'select2';

export default {
    name: 'Batches',
    props: {
        value: {
            type: Array,
            required: true
        },
        state: Boolean
    },
    data() {
        return {
            selectInstance: null
        };
    },
    mounted() {
        this.initializeSelect2();
    },
    beforeDestroy() {
        if (this.selectInstance) {
            this.selectInstance.select2('destroy');
        }
    },
    methods: {
        initializeSelect2() {
            const $select = $(this.$refs.selectElement);

            this.selectInstance = $select.select2({
                placeholder: '',
                allowClear: true,
                multiple: true,
                tags: true,
                minimumResultsForSearch: -1,
                language: {
                    noResults: () => 'No se encontraron resultados'
                }
            }).on('select2:select select2:unselect', () => {
                this.$emit('input', $select.val());
            });

            this.fillSelectWithValues();
        },
        fillSelectWithValues() {
            const $select = $(this.$refs.selectElement);

            $select.empty();
            for (let batch of this.value) {
                $select.append(new Option(batch, batch, true, true));
            }

            $select.trigger('change');
        }
    },
    watch: {
        value(newValue) {
            this.fillSelectWithValues();
        }
    },
    computed: {
        errorMessage() {
            return 'Field required';
        }
    }
};
</script>
<style scoped>
	.is-invalid {
		border-color: red !important;
	}

	.invalid-value {
		color: rgb(196, 49, 49);
		font-size: 11.375px;
	}
</style>
