<template>
    <b-container class="py-4">
        <h5 class="text-center">Pollination tubes</h5>
        <ValidationObserver v-slot="{ passes }" :ref="'formPollinationTubes' + pollinationId">
            <b-form @submit.prevent="passes(beforeSaveTube)" :id="'pollination-tubes-form-' + pollinationId">
                <b-table-simple class="mt-3" bordered style="overflow: visible">
                    <b-thead>
                        <b-tr>
                            <b-th class="text-center" style="width: 22%">Tube id</b-th>
                            <b-th class="text-center" style="width: 20%">Available ml</b-th>
                            <b-th class="text-center" style="width: 18%">Used ml</b-th>
                            <b-th class="text-center" style="width: 20%">Disposed ml</b-th>
                            <b-th class="text-center" style="width: 20%">Remaining ml</b-th>
                            <b-th class="text-center" style="width: 20%" v-if="hasPermission">Actions</b-th>
                        </b-tr>
                    </b-thead>
                    <b-tbody>
                        <b-tr v-if="hasPermission">
                            <b-td class="text-right">
                                <ValidationProvider :rules="editTubeRecord ? '' : 'required'" v-slot="{ errors }">
                                    <b-form-group label="" :invalid-feedback="errors[0]" :state="errors[0] ? false : null">
                                        <v-select label="tubeId" :options="tubesIds" @search="getTubes" @search:focus="getTubes"
                                                  :filterable="false" :state="errors[0] ? false : null" v-model="newPollinationTube.tube">
                                            <template #option="option">
                                                <div style="font-size: 8pt;">
                                                <p class="font-bold" style="color: var(--color-secondary)">{{ option.tubeId }}</p>
                                                    <p><span class="font-bold">Harvest date: </span><span>{{ option.harvestDate }}</span></p>
                                                    <p><span class="font-bold">Avail: </span><span>{{ option.deliveredMl }} ml</span></p>
                                                    <p><span class="font-bold">PL: </span><span>{{ option.variety }}</span><span v-if="option.tag">-{{ option.tag }}</span></p>
                                                    <p><span class="font-bold">Compart: </span><span>{{ option.compartmentName }}</span></p>
                                                    <p>
                                                        <span class="font-bold">Inventory: </span>
                                                        <span v-if="option.fromInventory == 1">YES</span>
                                                        <span v-else>NO</span>
                                                    </p>
                                                    <p style="width: 100%;"><span class="font-bold">Remarks: </span><span>{{ option.remarks }}</span></p>
                                                </div>
                                            </template>
                                            <template #no-options>No results</template>
                                        </v-select>
                                    </b-form-group>
                                </ValidationProvider>
                            </b-td>
                            <b-td class="text-right" style="vertical-align: middle">
                                {{ newPollinationTube.deliveredMl }}
                            </b-td>
                            <b-td>
                                <ValidationProvider :rules="editTubeRecord ? '': 'required|number|min_value:0.01|max_value:' + newPollinationTube.deliveredMl" v-slot="{ errors }">
                                    <b-form-group :invalid-feedback="errors[0]">
                                        <b-input v-model="newPollinationTube.realUsedMl" :state="errors[0] ? false : null" autocomplete="off"></b-input>
                                    </b-form-group>
                                </ValidationProvider>
                            </b-td>
                            <b-td>
                                <ValidationProvider :rules="editTubeRecord ? '':'required|number|min_value:0|max_value:' + (Number(newPollinationTube.deliveredMl) - Number(newPollinationTube.realUsedMl)).toFixed(2)" v-slot="{ errors }">
                                    <b-form-group :invalid-feedback="errors[0]">
                                        <b-input v-model="newPollinationTube.disposedMl" :state="errors[0] ? false : null" autocomplete="off"></b-input>
                                    </b-form-group>
                                </ValidationProvider>
                            </b-td>
                            <b-td class="text-right" style="vertical-align: middle">{{ getRemainingMl }}</b-td>
                            <b-td style="width: 100px">
                                <div class="d-flex justify-content-center align-items-center">
                                    <b-button type="submit" :form="'pollination-tubes-form-' + pollinationId" @click="setData('create')"
                                              variant="outline-primary" size="sm" v-b-tooltip.hover.bottom title="Save">
                                        <font-awesome-icon icon="save"></font-awesome-icon>
                                    </b-button>
                                </div>
                            </b-td>
                        </b-tr>
                        <template v-if="tubes.length > 0">
                            <b-tr v-for="(tube, index) in tubes" :key="index">
                                <template v-if="!tube.isEditing">
                                    <b-td :class="tube.status !== 'ACTIVE' ? 'line-through text-center' : 'text-center'" style="vertical-align: middle;">{{ tube.tubeIdName }}</b-td>
                                    <b-td :class="tube.status !== 'ACTIVE' ? 'line-through text-right' : 'text-right'" style="vertical-align: middle">{{ tube.deliveredMl }}</b-td>
                                    <b-td :class="tube.status !== 'ACTIVE' ? 'line-through text-right' : 'text-right'" style="vertical-align: middle">{{tube.realUsedMl}}</b-td>
                                    <b-td :class="tube.status !== 'ACTIVE' ? 'line-through text-right' : 'text-right'" style="vertical-align: middle">{{ tube.disposedMl }}</b-td>
                                    <b-td :class="tube.status !== 'ACTIVE' ? 'line-through text-right' : 'text-right'" style="vertical-align: middle">{{ tube.remainingMl }}</b-td>
                                    <b-td class="text-center" v-if="hasPermission">
    <!--                                    <b-button size="sm" @click="editStage('EDIT', tube)"-->
    <!--                                              v-b-tooltip.hover.left title="Edit" variant="outline-primary">-->
    <!--                                        <font-awesome-icon icon="edit"></font-awesome-icon>-->
    <!--                                    </b-button>-->
                                        <b-button size="sm" v-b-tooltip.hover.right title="Delete" variant="outline-primary" v-if="tube.status === 'ACTIVE'"
                                                  @click="deletePollinationTube(tube.id)">
                                            <font-awesome-icon icon="trash-alt"></font-awesome-icon>
                                        </b-button>
                                    </b-td>
                                </template>
                                <template v-else>
                                    <b-td class="text-center" style="vertical-align: middle">{{ tube.tubeIdName }}</b-td>
                                    <b-td class="text-right" style="vertical-align: middle">{{ tube.deliveredMl }}</b-td>
                                    <b-td>
                                        <ValidationProvider :rules="'required|number|min_value:0.01|max_value:' + tube.deliveredMl" v-slot="{ errors }">
                                            <b-form-group :invalid-feedback="errors[0]">
                                                <b-input v-model="tube.realUsedMl" :state="errors[0] ? false : null" autocomplete="off"></b-input>
                                            </b-form-group>
                                        </ValidationProvider>
                                    </b-td>
                                    <b-td>
                                        <ValidationProvider :rules="'required|number|min_value:0|max_value:' + (Number(tube.deliveredMl) - Number(tube.realUsedMl)).toFixed(2)" v-slot="{ errors }">
                                            <b-form-group :invalid-feedback="errors[0]">
                                                <b-input v-model="tube.disposedMl" :state="errors[0] ? false : null" autocomplete="off"></b-input>
                                            </b-form-group>
                                        </ValidationProvider>
                                    </b-td>
                                    <b-td class="text-right" style="vertical-align: middle">
                                        {{ getRemaining(tube) }}
                                    </b-td>
                                    <b-td>
                                        <div class="d-flex justify-content-center align-items-center">
                                            <b-button type="submit" :form="'pollination-tubes-form' + pollinationId" @click="setData('edit', tube)"
                                                      variant="outline-primary" size="sm" v-b-tooltip.hover title="Save">
                                                <font-awesome-icon icon="save"></font-awesome-icon>
                                            </b-button>
                                            <b-button @click="editStage('CANCEL_EDIT_ITEM', tube)"
                                                      variant="outline-primary" size="sm" v-b-tooltip.hover title="Cancel">
                                                <font-awesome-icon icon="ban"></font-awesome-icon>
                                            </b-button>
                                        </div>
                                    </b-td>
                                </template>
                            </b-tr>
                        </template>
                        <template v-else-if="tubes.length === 0 && !hasPermission">
                            <b-tr>
                                <b-td colspan="6" class="text-center">No records</b-td>
                            </b-tr>
                        </template>
                    </b-tbody>
                </b-table-simple>
            </b-form>
        </ValidationObserver>
    </b-container>

</template>

<script>
import {
    deletePollinationRecordUrl,
    getPollenHarvestTubesUrl,
    getPollinationTubesUrl,
    saveEmasculationPollinationTubeUrl
} from '@routes';
import $permission from '@/js/permission';

export default {
    name: 'PollinationTubesComponent',
    props: {
        productionLineId: {
            type: Number,
            required: true
        },
        pollinationId: {
            type: Number,
            required: true
        },
        pollinationStatus: {
            type: String,
            required: true
        }
    },
    data() {
        return {
            newPollinationTube: {
                id: null,
                tube: null,
                tubeId: null,
                deliveredMl: null,
                remainingMl: null,
                disposedMl: null,
                realUsedMl: null,
                pollinationId: this.pollinationId
            },
            tubes: [],
            tubesIds: [],
            tubeData: null,
            pollinationTubesCache: [],
            editTubeRecord: false,
            toastTitle: 'Production lines',
            calendar: this.$parent,
            hasPermission: $permission.check('ROLE_PRLI_EMPO_C')
        };
    },
    created() {
        this.loadTubesData();
    },
    methods: {
        loadTubesData() {
            this.axios.post(getPollinationTubesUrl(), {
                pollinationId: this.pollinationId
            }).then(response => {
                if (response.status === 200) {
                    this.tubes = response.data.tubes;

                    // this.$nextTick(() => {
                    //     this.$emit('load-data');
                    // });
                }
            }).catch(error => {
                if (error.response) {
                    this.$bvToast.toast(error.response.data.message, {
                        title: this.toastTitle,
                        variant: 'danger'
                    });
                } else if (error.request) {
                    this.$bvToast.toast('An error occurred while executing the request', {
                        title: this.toastTitle,
                        variant: 'danger'
                    });
                } else {
                    this.$bvToast.toast('An error occurred while configuring the request', {
                        title: this.toastTitle,
                        variant: 'danger'
                    });
                }
            });
        },
        beforeSaveTube() {
            if (this.pollinationStatus === 'CANCELLED') {
                this.$swal({
                    title: 'Add pollination tube',
                    text: 'Are you sure to add a tube in a cancelled pollination?',
                    icon: 'warning',
                    cancelButtonText: 'Cancel',
                    confirmButtonColor: '#97bf0d',
                    confirmButtonText: 'Yes'
                }).then(result => {
                    if (result.value) {
                        this.saveTube();
                    }
                });
            } else {
                this.saveTube();
            }
        },
        saveTube() {
            let tube, action, index, indexCache;

            this.axios.post(saveEmasculationPollinationTubeUrl(), {
                tubeData: this.tubeData
            }).then(response => {
                if (response.status === 200) {
                    this.$bvToast.toast(response.data.message, {
                        title: this.toastTitle,
                        variant: 'success'
                    });

                    tube = response.data.tubeRecord;
                    action = response.data.action;

                    if (action === 'created') {
                        this.$nextTick(() => {
                            this.loadTubesData();
                        });

                        this.newPollinationTube.id = null;
                        this.newPollinationTube.tube = null;
                        this.newPollinationTube.deliveredMl = null;
                        this.newPollinationTube.remainingMl = null;
                        this.newPollinationTube.disposedMl = null;
                        this.newPollinationTube.realUsedMl = null;
                        this.pollinationTubesCache = [];

                        let value = 'formPollinationTubes'+ this.pollinationId;

                        if (this.$refs[value]) {
                            requestAnimationFrame(() => {
                                this.$refs[value].reset();
                            });
                        }

                        this.calendar.emasculationPollinationData.pollinationTubes++;
                    } else {
                        index = this.tubes.findIndex(item => item.id === tube.id);
                        indexCache = this.pollinationTubesCache.findIndex(item => item.id === tube.id);

                        if (index !== -1) {
                            this.tubes[index].id = tube.id;
                            this.tubes[index].deliveredMl = tube.deliveredMl;
                            this.tubes[index].tubeId = tube.tubeId;
                            this.tubes[index].disposedMl = tube.disposedMl;
                            this.tubes[index].realUsedMl = tube.realUsedMl;
                            this.tubes[index].remainingMl = tube.remainingMl;
                            this.tubes[index].isEditing = false;
                        }

                        if (indexCache !== -1) {
                            this.pollinationTubesCache.splice(indexCache, 1);
                        }
                    }
                }
            }).catch(error => {
                if (error.response) {
                    this.$bvToast.toast(error.response.data.message, {
                        title: this.toastTitle,
                        variant: 'danger'
                    });
                } else if (error.request) {
                    this.$bvToast.toast('An error occurred while executing the request', {
                        title: this.toastTitle,
                        variant: 'danger'
                    });
                } else {
                    this.$bvToast.toast('An error occurred while configuring the request', {
                        title: this.toastTitle,
                        variant: 'danger'
                    });
                }
            });
        },
        setData(action, tube = null) {
            if (action === 'create') {
                this.editTubeRecord = false;
                this.tubeData = this.newPollinationTube;
            } else if (action === 'edit') {
                this.editTubeRecord = true;
                this.tubeData = tube;
            }
        },
        getTubes(search, loading) {
            if (loading) {
                loading(true);
            }

            this.tubesIds = [];
            this.axios.post(getPollenHarvestTubesUrl(), {
                search: search,
                productionLineId: this.productionLineId,
                pollinationId: this.pollinationId
            }).then(response => {
                this.tubesIds = response.data.tubes;
            }).catch(error => {
                if (error.response) {
                    this.$bvToast.toast(error.response.data.message, {
                        title: this.toastTitle,
                        variant: 'danger'
                    });
                } else if (error.request) {
                    this.$bvToast.toast('An error occurred while executing the request', {
                        title: this.toastTitle,
                        variant: 'danger'
                    });
                } else {
                    this.$bvToast.toast('An error occurred while configuring the request', {
                        title: this.toastTitle,
                        variant: 'danger'
                    });
                }
            }).finally(() => {
                if (loading) {
                    loading(false);
                }
            });
        },
        editStage(action, item) {
            if (action === 'EDIT') {
                this.pollinationTubesCache.push(JSON.parse(JSON.stringify(item)));
                item.isEditing = true;
            } else if (action === 'CANCEL_EDIT_ITEM') {
                let idx = this.pollinationTubesCache.findIndex(tube => tube.id === item.id);
                let tubeIdx = this.tubes.findIndex(tube => tube.id === item.id);

                if (idx !== -1 && tubeIdx !== -1) {
                    this.tubes[tubeIdx].deliveredMl = this.pollinationTubesCache[idx].deliveredMl;
                    this.tubes[tubeIdx].tubeId = this.pollinationTubesCache[idx].tubeId;
                    this.tubes[tubeIdx].disposedMl = this.pollinationTubesCache[idx].disposedMl;
                    this.tubes[tubeIdx].realUsedMl = this.pollinationTubesCache[idx].realUsedMl;
                    this.tubes[tubeIdx].remainingMl = this.pollinationTubesCache[idx].remainingMl;
                    this.tubes[tubeIdx].isEditing = false;
                    this.pollinationTubesCache.splice(idx, 1);
                }
                item.isEditing = false;
            }
        },
        deletePollinationTube(id) {
            this.$swal({
                title: `Delete pollination tube`,
                icon: 'warning',
                text: `Are you sure you want to delete this pollination tube?`,
                cancelButtonText: 'Cancel',
                iconColor: '#bf0d0d',
                confirmButtonColor: '#bf0d0d',
                confirmButtonText: 'Yes'
            }).then(result => {
                if (result.value) {
                    this.axios.post(deletePollinationRecordUrl(), {
                        id: id
                    }).then(response => {
                        if (response.status === 200) {
                            this.$bvToast.toast(response.data.message, {
                                title: this.toastTitle,
                                variant: 'success'
                            });

                            this.$nextTick(() => {
                                this.loadTubesData();
                            });

                            this.calendar.emasculationPollinationData.pollinationTubes--;
                        } else {
                            this.$bvToast.toast(response.data.message, {
                                title: this.toastTitle,
                                variant: 'danger'
                            });
                        }
                    }).catch(error => {
                        if (error.response) {
                            this.$bvToast.toast(error.response.data.message, {
                                title: this.toastTitle,
                                variant: 'danger'
                            });
                        } else if (error.request) {
                            this.$bvToast.toast('An error occurred while executing the request', {
                                title: this.toastTitle,
                                variant: 'danger'
                            });
                        } else {
                            this.$bvToast.toast('An error occurred while configuring the request', {
                                title: this.toastTitle,
                                variant: 'danger'
                            });
                        }
                    }).finally(() => {
                        this.isLoading = false;
                    });
                }
            });
        },
        getRemaining(tube) {
            let remaining = (Number(tube.deliveredMl) - Number(tube.realUsedMl) - Number(tube.disposedMl)).toFixed(2);
            tube.remainingMl = remaining === '-0.00' ? '0.00' : remaining;

            return tube.remainingMl;
        }
    },
    watch: {
        'newPollinationTube.tube': {
            handler() {
                this.newPollinationTube.deliveredMl = this.newPollinationTube.tube ? this.newPollinationTube.tube.deliveredMl : null;
                this.newPollinationTube.realUsedMl = null;
                this.newPollinationTube.disposedMl = null;

                if (this.$refs.formPollinationTubes) {

                    requestAnimationFrame(() => {
                        this.$refs.formPollinationTubes.reset();
                    });
                }
            },
            deep: true
        },
        getRemainingMl(newRemainingMl) {
            this.newPollinationTube.remainingMl = newRemainingMl;
        }
    },
    computed: {
        getRemainingMl() {
            let remaining = (Number(this.newPollinationTube.deliveredMl) - Number(this.newPollinationTube.realUsedMl) - Number(this.newPollinationTube.disposedMl)).toFixed(2);

            return remaining === '-0.00' ? '0.00' : remaining;
        }
    }
};
</script>
<style>
    .line-through {
        position: relative;
    }

    .line-through::before {
        content: '';
        position: absolute;
        top: 50%;
        left: 0;
        right: 0;
        border-top: 1px solid rgba(75, 68, 69, 0.99);
        transform: translateY(-50%);
    }

    .v-select .vs__dropdown-toggle {
        width: 140px;
    }
</style>